import { useMemo } from 'react';

// Discord benzeri renk paleti
const AVATAR_COLORS = [
  '#5865F2', // Discord Mavi
  '#57F287', // Discord Yeşil
  '#FEE75C', // Discord Sarı
  '#EB459E', // Discord Pembe
  '#ED4245', // Discord Kırmızı
  '#9B59B6', // Mor
  '#3498DB', // Açık Mavi
  '#E67E22', // Turuncu
  '#1ABC9C', // Turkuaz
  '#71368A', // Koyu Mor
];

interface ColorAvatarProps {
  userId: string | number;
  username: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

const ColorAvatar: React.FC<ColorAvatarProps> = ({ 
  userId, 
  username, 
  size = 'md',
  className = ''
}) => {
  // Kullanıcı ID'sine göre sabit bir renk seç
  const backgroundColor = useMemo(() => {
    const colorIndex = parseInt(userId.toString()) % AVATAR_COLORS.length;
    return AVATAR_COLORS[colorIndex];
  }, [userId]);

  // Size değerlerine göre boyutlar
  const sizeClasses = {
    sm: 'w-8 h-8 text-sm',
    md: 'w-10 h-10 text-base',
    lg: 'w-20 h-20 text-3xl',
    xl: 'w-full h-full text-[2.5rem]'
  };

  return (
    <div
      className={`rounded-full flex items-center justify-center font-semibold text-white ${sizeClasses[size]} ${className}`}
      style={{ 
        backgroundColor,
        transform: 'translate(0, 0)' // Fix for rendering issues
      }}
    >
      <span className="transform-none">
        {username.charAt(0).toUpperCase()}
      </span>
    </div>
  );
};

// Renk paletini dışa aktar
export { AVATAR_COLORS };
export default ColorAvatar; 