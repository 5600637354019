import React from 'react';

const MemberSidebar: React.FC = () => {
  const members = [
    { id: '1', name: 'User 1', status: 'online', role: 'admin' },
    { id: '2', name: 'User 2', status: 'idle', role: 'mod' },
    { id: '3', name: 'User 3', status: 'dnd', role: 'member' },
    { id: '4', name: 'User 4', status: 'offline', role: 'member' },
  ];

  const renderMemberSection = (title: string, filteredMembers: typeof members) => (
    <div className="mb-6">
      <h3 className="px-3 text-discord-light uppercase text-xs font-semibold mb-2">
        {title} — {filteredMembers.length}
      </h3>
      {filteredMembers.map(member => (
        <div
          key={member.id}
          className="flex items-center px-3 py-1 mx-2 rounded hover:bg-discord-gray/10 cursor-pointer group"
        >
          <div className="w-8 h-8 rounded-full bg-discord-darker flex items-center justify-center relative mr-3">
            <span className="text-sm text-discord-lighter">{member.name.charAt(0)}</span>
            <span
              className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-discord-dark ${
                member.status === 'online' ? 'bg-discord-green' :
                member.status === 'idle' ? 'bg-discord-yellow' :
                member.status === 'dnd' ? 'bg-discord-red' :
                'bg-discord-gray'
              }`}
            />
          </div>
          <span className={`text-sm ${
            member.role === 'admin' ? 'text-discord-red' :
            member.role === 'mod' ? 'text-discord-blue' :
            'text-discord-lighter'
          } group-hover:text-white`}>
            {member.name}
          </span>
        </div>
      ))}
    </div>
  );

  const onlineMembers = members.filter(m => m.status !== 'offline');
  const offlineMembers = members.filter(m => m.status === 'offline');

  return (
    <div className="w-60 bg-discord-dark flex-shrink-0 flex flex-col border-l border-black/[0.24]">
      {/* Members List */}
      <div className="flex-1 overflow-y-auto pt-16">
        {renderMemberSection('ÇEVRİMİÇİ', onlineMembers)}
        {renderMemberSection('ÇEVRİMDIŞI', offlineMembers)}
      </div>
    </div>
  );
};

export default MemberSidebar; 