import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { API } from '../../services/api';
import WebSocketClient from '../../utils/WebSocketClient';
import ColorAvatar from '../../components/common/ColorAvatar';
import { FaCrown } from 'react-icons/fa';

interface Member {
  id: number;
  username: string;
  avatar_url: string | null;
  status: string;
  role: string;
}

const MemberSidebar: React.FC = () => {
  const { serverId } = useParams();
  const { currentUser } = useAuth();
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(true);
  const wsClient = WebSocketClient.getInstance();

  useEffect(() => {
    const loadMembers = async () => {
      try {
        if (!serverId || !currentUser?.token) return;

        const response = await API.get(`/api/servers/${serverId}/members`, {
          headers: { Authorization: `Bearer ${currentUser.token}` }
        });

        if (response.data) {
          setMembers(response.data);
          setLoading(false);

          // Üyelerin presence durumlarını al
          wsClient.send({
            type: 'GET_PRESENCE',
            data: {
              userIds: response.data.map((member: Member) => member.id)
            }
          });
        }
      } catch (error) {
        console.error('Üyeler yüklenirken hata:', error);
        setLoading(false);
      }
    };

    loadMembers();

    // WebSocket event listener'ları
    const handlePresenceUpdate = (data: { userId: number; status: string }) => {
      setMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === data.userId 
            ? { ...member, status: data.status }
            : member
        )
      );
    };

    const handlePresenceResponse = (data: { presences: Array<{ userId: number; status: string }> }) => {
      setMembers(prevMembers => 
        prevMembers.map(member => {
          const presence = data.presences.find(p => p.userId === member.id);
          return presence ? { ...member, status: presence.status } : member;
        })
      );
    };

    wsClient.on('PRESENCE_UPDATE', handlePresenceUpdate);
    wsClient.on('PRESENCE_RESPONSE', handlePresenceResponse);

    return () => {
      wsClient.off('PRESENCE_UPDATE', handlePresenceUpdate);
      wsClient.off('PRESENCE_RESPONSE', handlePresenceResponse);
    };
  }, [serverId, currentUser, wsClient]);

  // Üyeleri role ve duruma göre sırala
  const sortMembers = (members: Member[]) => {
    return [...members].sort((a, b) => {
      // Önce owner en üstte
      if (a.role === 'admin' && b.role !== 'admin') return -1;
      if (b.role === 'admin' && a.role !== 'admin') return 1;
      
      // Sonra online durumuna göre
      if (a.status !== 'offline' && b.status === 'offline') return -1;
      if (b.status !== 'offline' && a.status === 'offline') return 1;
      
      // Son olarak isme göre
      return a.username.localeCompare(b.username);
    });
  };

  if (loading) {
    return (
      <div className="w-60 bg-[#2f3136] flex-shrink-0 flex flex-col">
        <div className="px-3 py-4 text-gray-400">Üyeler yükleniyor...</div>
      </div>
    );
  }

  const onlineMembers = sortMembers(members.filter(member => member.status !== 'offline'));
  const offlineMembers = sortMembers(members.filter(member => member.status === 'offline'));

  const renderMember = (member: Member, isOffline: boolean = false) => (
    <div 
      key={member.id} 
      className={`px-2 mx-2 py-1 rounded hover:bg-[#36393f] group flex items-center cursor-pointer ${
        isOffline ? 'opacity-40 hover:opacity-100' : ''
      }`}
    >
      <div className="relative flex-shrink-0">
        {member.avatar_url ? (
          <img 
            src={`${member.avatar_url}?size=32`}
            alt={member.username}
            className={`w-8 h-8 rounded-full object-cover ${isOffline ? 'grayscale' : ''}`}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.style.display = 'none';
              const parent = target.parentElement;
              if (parent) {
                const colorAvatar = document.createElement('div');
                colorAvatar.className = `w-8 h-8 rounded-full flex items-center justify-center ${isOffline ? 'grayscale' : ''}`;
                colorAvatar.textContent = member.username[0].toUpperCase();
                parent.appendChild(colorAvatar);
              }
            }}
          />
        ) : (
          <ColorAvatar 
            userId={member.id}
            username={member.username}
            size="md"
            className={isOffline ? 'grayscale' : ''}
          />
        )}
        <span className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-[#2f3136] ${
          member.status === 'online' ? 'bg-[#3ba55d]' :
          member.status === 'idle' ? 'bg-[#faa81a]' :
          member.status === 'dnd' ? 'bg-[#ed4245]' :
          'bg-[#747f8d]'
        }`} />
      </div>
      <div className="ml-2 flex-1 min-w-0 flex items-center">
        <span className={`text-sm ${isOffline ? 'text-gray-400 group-hover:text-gray-300' : 'text-gray-300 group-hover:text-gray-100'} truncate`}>
          {member.username}
        </span>
        {member.role === 'admin' && (
          <FaCrown className="ml-1 text-yellow-500 text-sm" title="Sunucu Sahibi" />
        )}
      </div>
    </div>
  );

  return (
    <div className="w-60 bg-[#2f3136] flex-shrink-0 flex flex-col h-full overflow-hidden">
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-[#202225] scrollbar-track-transparent">
        {/* Çevrimiçi Üyeler */}
        <div className="mb-2">
          <h3 className="px-3 py-2 text-xs font-semibold uppercase text-gray-400">
            Çevrimiçi — {onlineMembers.length}
          </h3>
          {onlineMembers.map(member => renderMember(member))}
        </div>

        {/* Çevrimdışı Üyeler */}
        <div className="mb-2">
          <h3 className="px-3 py-2 text-xs font-semibold uppercase text-gray-400">
            Çevrimdışı — {offlineMembers.length}
          </h3>
          {offlineMembers.map(member => renderMember(member, true))}
        </div>
      </div>
    </div>
  );
};

export default MemberSidebar; 