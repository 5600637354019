import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFriendRequests } from './FriendRequestsContext';
import { useAuth } from './AuthContext';

interface NotificationContextType {
  dmNotifications: { [key: string]: number }; // channelId -> bildirim sayısı
  friendRequestCount: number;
  clearDMNotifications: (channelId: string) => void;
  getTotalNotifications: () => number;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const getUserIdFromToken = (token: string): number | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload).id;
  } catch (error) {
    return null;
  }
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dmNotifications, setDmNotifications] = useState<{ [key: string]: number }>({});
  const [friendRequestCount, setFriendRequestCount] = useState(0);
  const { wsClient } = useFriendRequests();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!wsClient || !currentUser?.token) return;

    const userId = getUserIdFromToken(currentUser.token);
    if (!userId) return;

    const handleNewMessage = (data: any) => {
      const messageData = data.data || data;
      if (messageData.sender_id === userId) return;

      // Kanal ID'sine göre bildirim ekle
      setDmNotifications(prev => ({
        ...prev,
        [messageData.channel_id]: (prev[messageData.channel_id] || 0) + 1
      }));
    };

    const handleFriendRequest = () => {
      setFriendRequestCount(prev => prev + 1);
    };

    wsClient.on('NEW_MESSAGE', handleNewMessage);
    wsClient.on('FRIEND_REQUEST_RECEIVED', handleFriendRequest);

    return () => {
      wsClient.off('NEW_MESSAGE', handleNewMessage);
      wsClient.off('FRIEND_REQUEST_RECEIVED', handleFriendRequest);
    };
  }, [wsClient, currentUser]);

  const clearDMNotifications = (channelId: string) => {
    setDmNotifications(prev => {
      const newNotifications = { ...prev };
      delete newNotifications[channelId];
      return newNotifications;
    });
  };

  const getTotalNotifications = () => {
    return Object.values(dmNotifications).reduce((sum, count) => sum + count, 0) + friendRequestCount;
  };

  return (
    <NotificationContext.Provider value={{
      dmNotifications,
      friendRequestCount,
      clearDMNotifications,
      getTotalNotifications
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
}; 