import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from './AuthContext';
import { useSound } from '../hooks/useSound';

interface WebSocketContextType {
  socket: WebSocket | null;
  wsClient: WebSocket | null;
  connected: boolean;
  reconnect: () => void;
}

const WebSocketContext = createContext<WebSocketContextType>({
  socket: null,
  wsClient: null,
  connected: false,
  reconnect: () => {}
});

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const { currentUser } = useAuth();
  const { playSound } = useSound();
  const maxReconnectAttempts = 5;
  const reconnectDelay = 3000;
  const wsRef = useRef<WebSocket | null>(null);
  const clientIdRef = useRef<string | null>(null);
  const isAuthPendingRef = useRef(false);
  const authRetryCountRef = useRef(0);
  const maxAuthRetries = 3;

  const sendAuthMessage = useCallback((ws: WebSocket) => {
    if (!currentUser?.token || !currentUser?.id || !ws || ws.readyState !== WebSocket.OPEN) {
      console.log('❌ AUTH gönderilemiyor:', {
        token: !!currentUser?.token,
        userId: !!currentUser?.id,
        wsOpen: ws?.readyState === WebSocket.OPEN,
        clientId: clientIdRef.current
      });
      return;
    }

    // Maksimum deneme sayısını kontrol et
    if (authRetryCountRef.current >= maxAuthRetries) {
      console.log('⚠️ Maksimum AUTH denemesi aşıldı, bağlantı yenileniyor...');
      ws.close();
      return;
    }
    
    try {
      const authMessage = {
        type: 'auth',
        token: currentUser.token,
        userId: currentUser.id
      };
      
      console.log('🔑 auth gönderiliyor:', { 
        userId: currentUser.id,
        tokenLength: currentUser.token.length,
        attempt: authRetryCountRef.current + 1,
        maxAttempts: maxAuthRetries
      });

      ws.send(JSON.stringify(authMessage));
      isAuthPendingRef.current = true;
      authRetryCountRef.current++;

    } catch (error) {
      console.error('❌ AUTH gönderme hatası:', error);
      isAuthPendingRef.current = false;
      clientIdRef.current = null;
    }
  }, [currentUser?.token, currentUser?.id]);

  const createWebSocket = useCallback(() => {
    if (!currentUser?.token || !currentUser?.id) {
      console.log('❌ WebSocket oluşturulamıyor - Kullanıcı bilgileri eksik');
      return null;
    }

    // Önceki bağlantıyı temizle
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    // State'leri sıfırla
    isAuthPendingRef.current = false;
    clientIdRef.current = null;
    authRetryCountRef.current = 0;

    const wsUrl = 'wss://api.lazcord.aztmedya.net/ws';
    console.log('🔌 WebSocket başlatılıyor:', { url: wsUrl, attempt: reconnectAttempts + 1 });
    
    const newSocket = new WebSocket(wsUrl);
    wsRef.current = newSocket;

    newSocket.onopen = () => {
      console.log('✅ WebSocket bağlandı:', { attempt: reconnectAttempts + 1 });
      setConnected(true);
      setReconnectAttempts(0);
      
      // Bağlantı kurulduğunda AUTH gönder
      sendAuthMessage(newSocket);
    };

    newSocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('📨 Mesaj alındı:', {
          type: data.type,
          clientId: clientIdRef.current,
          authPending: isAuthPendingRef.current,
          authRetries: authRetryCountRef.current
        });

        switch (data.type) {
          case 'SERVER_JOIN':
            // SERVER_JOIN mesajını sadece auth tamamlandıysa işle
            if (!isAuthPendingRef.current && clientIdRef.current) {
              console.log('🎮 SERVER_JOIN işleniyor:', data);
            } else {
              console.log('⏳ AUTH bekliyor, SERVER_JOIN ertelendi');
              // Auth tamamlanınca mesajı tekrar işle
              setTimeout(() => {
                if (clientIdRef.current) {
                  newSocket.dispatchEvent(new MessageEvent('message', { data: event.data }));
                }
              }, 1000);
            }
            break;

          case 'REFRESH_SERVERS':
            // REFRESH_SERVERS mesajını sadece auth tamamlandıysa işle
            if (!isAuthPendingRef.current && clientIdRef.current) {
              console.log('🔄 REFRESH_SERVERS işleniyor');
            } else {
              console.log('⏳ AUTH bekliyor, REFRESH_SERVERS ertelendi');
            }
            break;

          case 'AUTH_SUCCESS':
            console.log('✅ AUTH başarılı:', { 
              newClientId: data.clientId,
              oldClientId: clientIdRef.current,
              attempts: authRetryCountRef.current
            });
            clientIdRef.current = data.clientId;
            isAuthPendingRef.current = false;
            authRetryCountRef.current = 0;
            break;

          case 'AUTH_ERROR':
            console.log('❌ AUTH başarısız:', {
              error: data.error,
              attempts: authRetryCountRef.current,
              maxAttempts: maxAuthRetries
            });
            clientIdRef.current = null;
            isAuthPendingRef.current = false;
            
            // Maksimum deneme sayısını aşmadıysak tekrar dene
            if (authRetryCountRef.current < maxAuthRetries) {
              setTimeout(() => sendAuthMessage(newSocket), 1000);
            } else {
              console.log('⚠️ Maksimum AUTH denemesi aşıldı, bağlantı yenileniyor...');
              newSocket.close();
            }
            break;

          case 'NEW_MESSAGE':
            // Bildirim sesi useDMUsers'da kontrol ediliyor
            break;

          case 'FRIEND_REQUEST_RECEIVED':
            playSound('friendRequest');
            break;
        }
      } catch (error) {
        console.error('❌ Mesaj işleme hatası:', error);
      }
    };

    newSocket.onclose = (event) => {
      console.log('❌ WebSocket kapandı:', { 
        reason: event.reason,
        attempt: reconnectAttempts + 1,
        clientId: clientIdRef.current,
        authRetries: authRetryCountRef.current
      });
      
      setConnected(false);
      wsRef.current = null;
      clientIdRef.current = null;
      isAuthPendingRef.current = false;
      authRetryCountRef.current = 0;

      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          setReconnectAttempts(prev => prev + 1);
          reconnect();
        }, reconnectDelay);
      }
    };

    newSocket.onerror = (error) => {
      console.error('❌ WebSocket hatası:', { 
        error,
        attempt: reconnectAttempts + 1,
        clientId: clientIdRef.current,
        authRetries: authRetryCountRef.current
      });
      
      setConnected(false);
      wsRef.current = null;
      clientIdRef.current = null;
      isAuthPendingRef.current = false;
      authRetryCountRef.current = 0;
    };

    return newSocket;
  }, [currentUser?.token, currentUser?.id, reconnectAttempts, sendAuthMessage]);

  const reconnect = useCallback(() => {
    const newSocket = createWebSocket();
    if (newSocket) {
      setSocket(newSocket);
    }
  }, [createWebSocket]);

  useEffect(() => {
    const newSocket = createWebSocket();
    if (newSocket) {
      setSocket(newSocket);
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      clientIdRef.current = null;
      isAuthPendingRef.current = false;
      authRetryCountRef.current = 0;
    };
  }, [currentUser?.token, currentUser?.id]);

  return (
    <WebSocketContext.Provider value={{
      socket,
      wsClient: socket,
      connected,
      reconnect: createWebSocket
    }}>
      {children}
    </WebSocketContext.Provider>
  );
}; 