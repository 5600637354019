import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useWebSocket } from '../../hooks/useWebSocket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faHeadphones, faCog, faUser, faGear, faVolumeUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useFriendRequests } from '../../contexts/FriendRequestsContext';
import { useProfile } from '../../contexts/ProfileContext';
import ColorAvatar from '../common/ColorAvatar';
import SettingsModal from '../modals/SettingsModal';

type UserStatus = 'online' | 'idle' | 'dnd' | 'offline';

const UserPanel: React.FC = () => {
  const { currentUser } = useAuth();
  const { updatePresence } = useWebSocket();
  const { wsClient } = useFriendRequests();
  const { profileSettings } = useProfile();
  const [status, setStatus] = useState<UserStatus>(() => {
    return (localStorage.getItem('userStatus') as UserStatus) || 'online';
  });
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const statusMenuRef = useRef<HTMLDivElement>(null);
  const settingsMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentUser && wsClient) {
      const savedStatus = localStorage.getItem('userStatus') as UserStatus;
      if (savedStatus) {
        setStatus(savedStatus);
        wsClient.updateUserStatus(savedStatus);
      }
    }
  }, [currentUser, wsClient]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (statusMenuRef.current && !statusMenuRef.current.contains(event.target as Node)) &&
        (settingsMenuRef.current && !settingsMenuRef.current.contains(event.target as Node))
      ) {
        setShowStatusMenu(false);
        setShowSettings(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getStatusColor = () => {
    if (!currentUser) return 'bg-discord-gray';
    
    switch (status) {
      case 'online':
        return 'bg-discord-green';
      case 'idle':
        return 'bg-discord-yellow';
      case 'dnd':
        return 'bg-discord-red';
      default:
        return 'bg-discord-gray';
    }
  };

  const getStatusText = () => {
    switch (status) {
      case 'online':
        return 'Çevrimiçi';
      case 'dnd':
        return 'Rahatsız Etmeyin';
      default:
        return 'Çevrimiçi';
    }
  };

  const handleStatusChange = (newStatus: string) => {
    if (wsClient) {
      setStatus(newStatus as UserStatus);
      localStorage.setItem('userStatus', newStatus);
      wsClient.updateUserStatus(newStatus);

      // Rahatsız etmeyin durumunda ses bildirimlerini kapat
      if (newStatus === 'dnd') {
        localStorage.setItem('notificationSound', 'false');
      } else {
        localStorage.setItem('notificationSound', 'true');
      }

      setShowStatusMenu(false);
    }
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setShowStatusMenu(true);
  };

  const StatusMenu = () => (
    <div
      ref={statusMenuRef}
      className="fixed bg-discord-dark rounded-md shadow-lg z-50"
      style={{
        top: menuPosition.y,
        left: menuPosition.x,
        transform: 'translate(-50%, -100%)',
      }}
    >
      <div className="p-2 space-y-1 min-w-[200px]">
        <div 
          className={`flex items-center px-3 py-2 rounded cursor-pointer hover:bg-discord-gray ${status === 'online' ? 'bg-discord-gray' : ''}`}
          onClick={() => handleStatusChange('online')}
        >
          <span className="w-2 h-2 rounded-full bg-discord-green mr-2" />
          <span className="text-white text-sm">Çevrimiçi</span>
        </div>
        <div 
          className={`flex items-center px-3 py-2 rounded cursor-pointer hover:bg-discord-gray ${status === 'dnd' ? 'bg-discord-gray' : ''}`}
          onClick={() => handleStatusChange('dnd')}
        >
          <span className="w-2 h-2 rounded-full bg-discord-red mr-2" />
          <span className="text-white text-sm">Rahatsız Etmeyin</span>
        </div>
      </div>
    </div>
  );

  const SettingsMenu = () => (
    <div
      ref={settingsMenuRef}
      className="fixed bg-discord-dark rounded-md shadow-lg z-50"
      style={{
        bottom: '60px',
        right: '10px',
      }}
    >
      <div className="p-2 space-y-1 min-w-[200px]">
        <div className="flex items-center px-3 py-2 rounded cursor-pointer hover:bg-discord-gray">
          <FontAwesomeIcon icon={faUser} className="w-4 h-4 text-discord-light mr-2" />
          <span className="text-white text-sm">Hesabım</span>
        </div>
        <div className="flex items-center px-3 py-2 rounded cursor-pointer hover:bg-discord-gray">
          <FontAwesomeIcon icon={faGear} className="w-4 h-4 text-discord-light mr-2" />
          <span className="text-white text-sm">Profil Ayarları</span>
        </div>
        <div className="flex items-center px-3 py-2 rounded cursor-pointer hover:bg-discord-gray">
          <FontAwesomeIcon icon={faVolumeUp} className="w-4 h-4 text-discord-light mr-2" />
          <span className="text-white text-sm">Ses & Mikrofon Ayarları</span>
        </div>
        <div className="h-[1px] bg-discord-gray my-1"></div>
        <div className="flex items-center px-3 py-2 rounded cursor-pointer hover:bg-discord-gray text-discord-red">
          <FontAwesomeIcon icon={faSignOutAlt} className="w-4 h-4 mr-2" />
          <span className="text-sm">Çıkış Yap</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="h-[52px] bg-discord-darker flex items-center px-2">
        <div 
          className="flex-1 flex items-center min-w-0 p-1 rounded hover:bg-discord-dark cursor-pointer"
          onContextMenu={handleContextMenu}
        >
          <div 
            className="relative mr-2"
            onContextMenu={handleContextMenu}
          >
            {profileSettings?.avatar ? (
              <div className="w-8 h-8 rounded-full overflow-hidden">
                <img 
                  src={profileSettings.avatar} 
                  alt={currentUser?.username || 'Profil'} 
                  className="w-full h-full object-cover"
                />
              </div>
            ) : currentUser?.username && currentUser?.id ? (
              <ColorAvatar 
                userId={currentUser.id} 
                username={currentUser.username}
                size="md"
              />
            ) : (
              <div className="w-10 h-10 rounded-full bg-discord-dark flex items-center justify-center">
                <span className="text-white text-sm">?</span>
              </div>
            )}
            <span 
              className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-discord-darker ${getStatusColor()}`} 
              onClick={() => {
                const statusOrder: UserStatus[] = ['online', 'idle', 'dnd'];
                const currentIndex = statusOrder.indexOf(status);
                const nextIndex = (currentIndex + 1) % statusOrder.length;
                handleStatusChange(statusOrder[nextIndex]);
              }}
            />
          </div>
          <div className="flex-1 min-w-0">
            <div className="text-sm font-medium text-white truncate">
              {currentUser?.username || 'Misafir'}
            </div>
            <div className="text-xs text-discord-light truncate">
              {getStatusText()}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button className="w-8 h-8 rounded-md hover:bg-discord-dark flex items-center justify-center text-discord-light">
            <FontAwesomeIcon icon={faMicrophone} />
          </button>
          <button className="w-8 h-8 rounded-md hover:bg-discord-dark flex items-center justify-center text-discord-light">
            <FontAwesomeIcon icon={faHeadphones} />
          </button>
          <button 
            className="w-8 h-8 rounded-md hover:bg-discord-dark flex items-center justify-center text-discord-light"
            onClick={() => setShowSettings(true)}
          >
            <FontAwesomeIcon icon={faCog} />
          </button>
        </div>
        {showStatusMenu && <StatusMenu />}
      </div>
      {showSettings && <SettingsModal onClose={() => setShowSettings(false)} />}
    </>
  );
};

export default UserPanel; 