import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faLock, faSearch, faInbox, faCircleQuestion, faPlus, faFaceSmile, faGift, faImage, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';
import { API } from '../../services/api';

interface Message {
  id: number;
  channel_id: number;
  author_id: number;
  content: string;
  created_at: string;
  author_username: string;
  author_avatar: string | null;
  reactions: Array<{
    user_id: number;
    emoji: string;
  }>;
}

interface Channel {
  id: number;
  name: string;
  server_id: number;
  is_default: boolean;
}

interface ChatAreaProps {
  type: 'dm' | 'server';
  serverId?: string;
  channelId?: string;
}

const ChatArea: React.FC<ChatAreaProps> = ({ type, serverId, channelId }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<Channel | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { socket, connected } = useWebSocket();
  const navigate = useNavigate();
  const [isNearBottom, setIsNearBottom] = useState(true);

  // Varsayılan kanalı al ve yönlendir
  useEffect(() => {
    const fetchDefaultChannel = async () => {
      if (!serverId) return;
      
      try {
        // Eğer URL'de "general" varsa veya channelId yoksa varsayılan kanala yönlendir
        if (channelId === 'general' || !channelId) {
          const response = await API.get(`/api/servers/${serverId}/default-channel`);
          const defaultChannel = response.data;
          setCurrentChannel(defaultChannel);
          navigate(`/channels/${serverId}/${defaultChannel.id}`);
        } else {
          // Mevcut kanalın bilgilerini al
          const response = await API.get(`/api/channels/${channelId}`);
          setCurrentChannel(response.data);
        }
      } catch (error) {
        console.error('Kanal bilgileri alınırken hata:', error);
      }
    };

    fetchDefaultChannel();
  }, [serverId, channelId, navigate]);

  // Scroll pozisyonunu kontrol et
  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const scrollPosition = Math.abs(scrollTop);
    const isBottom = scrollHeight - scrollPosition - clientHeight < 100;
    setIsNearBottom(isBottom);
  };

  // Scroll to bottom sadece gerektiğinde çalışsın
  const scrollToBottom = () => {
    if (isNearBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Mesajları yükle
  const loadMessages = async () => {
    if (!channelId || channelId === 'general') return;
    
    try {
      setLoading(true);
      const messagesResponse = await API.get(`/api/channels/${channelId}/messages`);
      setMessages(messagesResponse.data.reverse());
      // Mesajlar yüklendiğinde en alta scroll yap
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView();
      }, 100);
    } catch (error) {
      console.error('Mesajlar yüklenirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  // WebSocket event'lerini dinle
  useEffect(() => {
    if (!socket || !connected || !channelId || channelId === 'general') return;

    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'CHANNEL_MESSAGE_CREATE':
          if (data.channelId === parseInt(channelId)) {
            setMessages(prev => [data.message, ...prev]);
            // Sadece en alttayken scroll yap
            if (isNearBottom) {
              setTimeout(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
              }, 100);
            }
          }
          break;

        case 'CHANNEL_MESSAGE_UPDATE':
          if (data.channelId === parseInt(channelId)) {
            console.log('Mesaj güncellendi:', data);
            setMessages(prev => prev.map(msg => 
              msg.id === data.messageId 
                ? { ...msg, content: data.content }
                : msg
            ));
          }
          break;

        case 'CHANNEL_MESSAGE_DELETE':
          if (data.channelId === parseInt(channelId)) {
            console.log('Mesaj silindi:', data);
            setMessages(prev => prev.filter(msg => msg.id !== data.messageId));
          }
          break;
      }
    };

    socket.addEventListener('message', handleMessage);

    return () => {
      socket.removeEventListener('message', handleMessage);
    };
  }, [socket, connected, channelId, isNearBottom]);

  // Kanal değiştiğinde mesajları yükle
  useEffect(() => {
    if (channelId && channelId !== 'general') {
      loadMessages();
    }
  }, [channelId]);

  // Yeni mesaj gönder
  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !channelId || channelId === 'general') return;

    try {
      console.log('Mesaj gönderiliyor:', {
        channelId,
        content: newMessage
      });

      if (socket && connected) {
        socket.send(JSON.stringify({
          type: 'CHANNEL_MESSAGE_CREATE',
          channelId: parseInt(channelId),
          content: newMessage
        }));
      }

      const response = await API.post(`/api/channels/${channelId}/messages`, {
        content: newMessage
      });

      console.log('Mesaj gönderildi:', response.data);
      setNewMessage('');
    } catch (error) {
      console.error('Mesaj gönderilirken hata:', error);
    }
  };

  return (
    <main className="flex-1 flex flex-col min-w-0 bg-[#313338]">
      {/* Header */}
      <header className="h-12 px-4 border-b border-l border-black/[0.24] flex items-center justify-between bg-[#313338] shadow-sm fixed right-0 left-[312px] z-50">
        <div className="flex items-center text-white">
          <span className="text-2xl mr-2 text-discord-light">#</span>
          <span className="font-medium">{currentChannel?.name || channelId}</span>
        </div>
        {/* Header Actions */}
        <div className="flex items-center space-x-2">
          <button className="w-8 h-8 rounded-[4px] hover:bg-[#404249] text-discord-light flex items-center justify-center group relative">
            <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
              <FontAwesomeIcon icon={faBell} className="w-5 h-5" />
            </div>
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
              Bildirimler
            </span>
          </button>
          <button className="w-8 h-8 rounded-[4px] hover:bg-[#404249] text-discord-light flex items-center justify-center group relative">
            <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
              <FontAwesomeIcon icon={faLock} className="w-5 h-5" />
            </div>
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
              Gizlilik
            </span>
          </button>
          <button className="w-8 h-8 rounded-[4px] hover:bg-[#404249] text-discord-light flex items-center justify-center group relative">
            <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
              <FontAwesomeIcon icon={faSearch} className="w-5 h-5" />
            </div>
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
              Ara
            </span>
          </button>
          <button className="w-8 h-8 rounded-[4px] hover:bg-[#404249] text-discord-light flex items-center justify-center group relative">
            <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
              <FontAwesomeIcon icon={faInbox} className="w-5 h-5" />
            </div>
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
              Gelen Kutusu
            </span>
          </button>
          <button className="w-8 h-8 rounded-[4px] hover:bg-[#404249] text-discord-light flex items-center justify-center group relative">
            <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
              <FontAwesomeIcon icon={faCircleQuestion} className="w-5 h-5" />
            </div>
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
              Yardım
            </span>
          </button>
        </div>
      </header>

      {/* Messages Container with Border */}
      <div className="flex-1 flex flex-col border-l border-black/[0.24] h-[calc(100vh-48px)]">
        {/* Messages */}
        <div 
          ref={messagesContainerRef}
          onScroll={handleScroll}
          className="flex-1 overflow-y-scroll p-4 space-y-4 mt-12 flex flex-col-reverse h-[calc(100vh-144px)]
            [&::-webkit-scrollbar]:w-3
            [&::-webkit-scrollbar-thumb]:bg-[#1a1b1e] 
            [&::-webkit-scrollbar-thumb]:rounded-full
            [&::-webkit-scrollbar-thumb]:border-[3px]
            [&::-webkit-scrollbar-thumb]:border-solid
            [&::-webkit-scrollbar-thumb]:border-[#313338]
            hover:[&::-webkit-scrollbar-thumb]:bg-[#232428]
            [&::-webkit-scrollbar-track]:bg-transparent"
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <span className="text-discord-light">Mesajlar yükleniyor...</span>
            </div>
          ) : messages.length === 0 ? (
            <div className="flex items-center justify-center">
              <span className="text-discord-light">Henüz mesaj yok</span>
            </div>
          ) : (
            <>
              <div ref={messagesEndRef} />
          {messages.map(message => (
                <div key={message.id} className="flex items-start group min-h-[40px] hover:bg-[#2e3035] rounded px-2 -mx-2">
              {/* Avatar */}
              <div className="w-10 h-10 rounded-full bg-[#1E1F22] flex items-center justify-center mr-4">
                      {message.author_avatar ? (
                        <img 
                          src={message.author_avatar} 
                          alt={message.author_username} 
                          className="w-full h-full rounded-full object-cover"
                        />
                      ) : (
                        <span className="text-white">{message.author_username.charAt(0)}</span>
                      )}
              </div>

              {/* Message Content */}
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                        <span className="font-medium text-white">{message.author_username}</span>
                        <span className="ml-2 text-xs text-discord-light">
                          {new Date(message.created_at).toLocaleTimeString('tr-TR', {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </span>
                </div>
                <p className="text-discord-lighter break-words">{message.content}</p>
                      
                      {/* Reactions */}
                      {message.reactions && message.reactions.length > 0 && (
                        <div className="flex flex-wrap gap-1 mt-1">
                          {message.reactions.map((reaction, index) => (
                            <div 
                              key={`${reaction.user_id}-${index}`}
                              className="bg-[#2b2d31] rounded-md px-2 py-0.5 text-sm flex items-center gap-1"
                            >
                              <span>{reaction.emoji}</span>
                              <span className="text-xs text-discord-light">1</span>
                            </div>
                          ))}
                        </div>
                      )}
              </div>

              {/* Message Actions */}
              <div className="opacity-0 group-hover:opacity-100 flex space-x-2 flex-shrink-0">
                <button className="p-2 hover:bg-[#404249] rounded-md text-discord-light">
                  <FontAwesomeIcon icon={faPlus} className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
            </>
          )}
        </div>

        {/* Message Input */}
        <form onSubmit={handleSendMessage} className="px-4 py-4 bg-[#313338] border-t border-black/[0.24]">
          <div className="relative">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={`#${currentChannel?.name || channelId} kanalına mesaj gönder`}
              className="w-full bg-[#383A40] text-discord-lighter px-4 py-2.5 rounded-lg focus:outline-none"
            />
            <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center space-x-1">
              <button 
                type="button"
                className="p-2 hover:bg-[#404249] rounded-lg text-discord-light group relative"
              >
                <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
                  <FontAwesomeIcon icon={faSquarePlus} className="w-5 h-5" />
                </div>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Dosya Ekle
                </span>
              </button>
              <button 
                type="button"
                className="p-2 hover:bg-[#404249] rounded-lg text-discord-light group relative"
              >
                <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
                  <FontAwesomeIcon icon={faGift} className="w-5 h-5" />
                </div>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Hediye
                </span>
              </button>
              <button 
                type="button"
                className="p-2 hover:bg-[#404249] rounded-lg text-discord-light group relative"
              >
                <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
                  <FontAwesomeIcon icon={faImage} className="w-5 h-5" />
                </div>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Görsel Ekle
                </span>
              </button>
              <button 
                type="button"
                className="p-2 hover:bg-[#404249] rounded-lg text-discord-light group relative"
              >
                <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
                  <FontAwesomeIcon icon={faFaceSmile} className="w-5 h-5" />
                </div>
                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                  Emoji
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default ChatArea; 