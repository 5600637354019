import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type EventCallback = (data: any) => void;

export default class WebSocketClient {
  private ws: WebSocket | null = null;
  private readonly url: string = window.location.origin.includes('lazcord.aztmedya.net')
    ? 'wss://api.lazcord.aztmedya.net/ws'
    : 'ws://localhost:3001/ws';
  private reconnectAttempts: number = 0;
  private readonly maxReconnectAttempts: number = 5;
  private pingInterval: NodeJS.Timeout | null = null;
  private offlineTimeout: NodeJS.Timeout | null = null;
  private isIntentionalClose: boolean = false;
  private eventListeners: Map<string, EventCallback[]> = new Map();
  private messageQueue: any[] = [];
  private isAuthenticated: boolean = false;
  private lastStatus: string = 'online';
  private readonly OFFLINE_DELAY = 60000; // 1 dakika
  private visibilityChangeTimeout: NodeJS.Timeout | null = null;
  private static instance: WebSocketClient | null = null;
  private connectionPromise: Promise<void> | null = null;
  private resolveConnection: (() => void) | null = null;

  private constructor() {
    // Singleton pattern
  }

  public static getInstance(): WebSocketClient {
    if (!WebSocketClient.instance) {
      WebSocketClient.instance = new WebSocketClient();
    }
    return WebSocketClient.instance;
  }

  // Yeni metod: Login sonrası bağlantıyı başlat
  public initializeConnection() {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    
    if (token && username) {
      this.connect();
    }
  }

  // Event handling methods
  on(event: string, callback: EventCallback) {
    if (!this.eventListeners.has(event)) {
      this.eventListeners.set(event, []);
    }
    this.eventListeners.get(event)?.push(callback);
  }

  off(event: string, callback?: EventCallback) {
    if (!callback) {
      this.eventListeners.delete(event);
    } else {
      const callbacks = this.eventListeners.get(event);
      if (callbacks) {
        const index = callbacks.indexOf(callback);
        if (index !== -1) {
          callbacks.splice(index, 1);
        }
        if (callbacks.length === 0) {
          this.eventListeners.delete(event);
        }
      }
    }
  }

  private emit(event: string, data: any) {
    const callbacks = this.eventListeners.get(event);
    if (callbacks) {
      callbacks.forEach(callback => callback(data));
    }
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      if (!this.isConnected() && !this.isIntentionalClose) {
        console.log('Sekme görünür oldu, bağlantı kontrolü yapılıyor...');
        this.connect();
      } else if (this.isConnected() && this.isAuthenticated) {
        console.log('Sekme görünür oldu, presence yenileniyor...');
        this.refreshPresenceStates();
      }
    }
  };

  private handleBeforeUnload = () => {
    console.log('Tarayıcı/sekme kapatılıyor, offline durumuna geçiliyor...');
    this.setOfflineStatus();
    this.disconnect();
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userStatus');
  };

  private setOfflineStatus = () => {
    if (this.ws?.readyState === WebSocket.OPEN && this.isAuthenticated) {
      try {
        console.log('Offline durumu ayarlanıyor...');
        // Offline durumunu WebSocket üzerinden gönder
        this.send({
          type: 'SET_PRESENCE',
          data: {
            status: 'offline',
            timestamp: new Date().toISOString()
          }
        });
        
        // Bağlantıyı hemen kapat
        this.isIntentionalClose = true;
        this.ws.close(1000, 'User disconnecting');
      } catch (error) {
        console.error('Offline durumu ayarlanırken hata:', error);
      }
    }
  };

  private createConnectionPromise() {
    if (!this.connectionPromise) {
      this.connectionPromise = new Promise((resolve) => {
        this.resolveConnection = resolve;
      });
    }
    return this.connectionPromise;
  }

  public async waitForConnection(): Promise<void> {
    if (this.isConnected() && this.isAuthenticated) {
      return;
    }
    return this.createConnectionPromise();
  }

  connect() {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      return;
    }

    this.createConnectionPromise();

    this.ws = new WebSocket(this.url);

    this.ws.onopen = () => {
      console.log('WebSocket bağlantısı açıldı');
      
      // Auth token'ı gönder
      const token = localStorage.getItem('token');
      if (token) {
        this.send({
          type: 'auth',
          token
        });
      }
    };

    this.ws.onmessage = (event) => {
      this.handleWebSocketMessage(event);
    };

    this.ws.onclose = (event) => {
      this.isAuthenticated = false;
      this.cleanup();
      
      const closeInfo = {
        code: event.code,
        reason: event.reason || 'Sebep belirtilmedi',
        wasClean: event.wasClean,
        timestamp: new Date().toISOString()
      };
      
      console.log('WebSocket bağlantısı kapandı:', closeInfo);
      
      if (!this.isIntentionalClose && this.reconnectAttempts < this.maxReconnectAttempts) {
        const timeout = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 10000);
        setTimeout(() => {
          this.reconnectAttempts++;
          this.connect();
        }, timeout);
      }
    };

    this.ws.onerror = (error) => {
      const errorInfo = {
        type: error.type,
        timestamp: new Date().toISOString(),
        readyState: this.ws?.readyState,
        url: this.url,
        reconnectAttempts: this.reconnectAttempts
      };
      
      console.error('WebSocket bağlantı hatası:', errorInfo);
      
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.close(1006, 'Bağlantı hatası nedeniyle kapatıldı');
      }
    };
  }

  private startPingInterval() {
    this.clearPingInterval();
    this.pingInterval = setInterval(() => {
      if (this.isConnected()) {
        this.send({ type: 'ping' });
      }
    }, 30000);
  }

  private clearPingInterval() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  disconnect() {
    this.isIntentionalClose = true;
    this.setOfflineStatus();
    this.cleanup();
    
    if (this.ws) {
      try {
        // Bağlantıyı zorla kapat
        this.ws.close(1000, 'Client disconnecting');
        this.ws = null;
        this.reconnectAttempts = 0;
        this.isAuthenticated = false;
        
        // Tüm state'leri temizle
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userStatus');
        this.lastStatus = 'offline';
        
        // Event listener'ları temizle
        this.cleanup();
      } catch (error) {
        console.error('Bağlantı kapatılırken hata:', error);
      }
    }
  }

  async send(data: any) {
    try {
      if (!this.isConnected()) {
        console.warn('WebSocket bağlantısı kapalı veya hazır değil! Bağlantı bekleniyor...');
        await this.waitForConnection();
      }

      if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
        throw new Error('WebSocket bağlantısı hala hazır değil!');
      }

      this.ws.send(JSON.stringify(data));
    } catch (error) {
      console.error('Mesaj gönderme hatası:', error);
      throw error;
    }
  }

  isConnected(): boolean {
    return this.ws !== null && this.ws.readyState === WebSocket.OPEN;
  }

  private handleWebSocketMessage(event: MessageEvent) {
    try {
      const data = JSON.parse(event.data);
      console.log('WebSocket mesajı alındı:', data);

      // DM kullanıcıları yüklendi
      if (data.type === 'DM_USERS_LOADED') {
        console.log('DM kullanıcıları yüklendi:', data);
        this.emit('DM_USERS_LOADED', data.data);
        return;
      }

      // Mesaj yükleme yanıtı
      if (data.type === 'MESSAGES_LOADED') {
        console.log('Mesajlar yüklendi:', data);
        this.emit('MESSAGES_LOADED', data.data);
        return;
      }

      // Yeni mesaj
      if (data.type === 'NEW_MESSAGE') {
        console.log('Yeni mesaj:', data);
        this.emit('NEW_MESSAGE', data.data);
        return;
      }

      // Mesaj gönderme onayı
      if (data.type === 'MESSAGE_SENT') {
        console.log('Mesaj gönderildi:', data);
        this.emit('MESSAGE_SENT', data.data);
        return;
      }

      if (data.type === 'error') {
        console.error('WebSocket hatası:', data.message);
        this.emit('error', data);
        return;
      }

      if (data.type === 'ping') {
        this.send({ type: 'pong' });
        return;
      }

      if (data.type === 'auth_success') {
        console.log('Kimlik doğrulama başarılı:', data.userId);
        this.isAuthenticated = true;
        if (this.resolveConnection) {
          this.resolveConnection();
          this.resolveConnection = null;
          this.connectionPromise = null;
        }
        return;
      }

      if (data.type === 'auth_error') {
        console.error('Kimlik doğrulama hatası:', data.message);
        this.isAuthenticated = false;
        localStorage.removeItem('isLoggedIn');
        return;
      }

      // Diğer mesajları sadece auth başarılıysa işle
      if (!this.isAuthenticated) {
        console.log('Kimlik doğrulanmamış, mesaj işlenmedi:', data);
        return;
      }

      // Presence update event'i - real-time güncelleme
      if (data.type === 'PRESENCE_UPDATE') {
        this.emit('PRESENCE_UPDATE', data.data);
      }

      // Presence yanıtı
      if (data.type === 'PRESENCE_RESPONSE' || data.type === 'INITIAL_PRESENCE') {
        this.emit(data.type, data.data);
      }

      // Arkadaşlık sistemi event'leri
      if (data.type === 'friend_request') {
        console.log('Arkadaşlık isteği alındı:', data);
        const username = localStorage.getItem('username');
        
        if (username === data.request.receiver_username) {
          // Toast bildirimi göster
          toast.info(`${data.request.sender_username} size arkadaşlık isteği gönderdi!`, {
            position: "top-right",
            autoClose: 5000
          });
        }

        // Event'i emit et
        this.emit('friend_request', data.request);
      }

      if (data.type === 'friend_request_response') {
        const username = localStorage.getItem('username');
        
        if (username === data.senderId) {
          toast.info(data.status === 'accepted' 
            ? 'Arkadaşlık isteğiniz kabul edildi!'
            : 'Arkadaşlık isteğiniz reddedildi.', {
            position: "top-right",
            autoClose: 5000
          });

          // Arkadaşlık kabul edildiyse, presence durumunu güncelle
          if (data.status === 'accepted') {
            this.send({
              type: 'GET_PRESENCE',
              data: {
                userIds: [data.receiverId]
              }
            });
          }
        } else if (username === data.receiverId) {
          toast.info(data.status === 'accepted'
            ? 'Arkadaşlık isteğini kabul ettiniz.'
            : 'Arkadaşlık isteğini reddettiniz.', {
            position: "top-right",
            autoClose: 5000
          });

          // Arkadaşlık kabul edildiyse, presence durumunu güncelle
          if (data.status === 'accepted') {
            this.send({
              type: 'GET_PRESENCE',
              data: {
                userIds: [data.senderId]
              }
            });
          }
        }
        
        // Event'i emit et
        console.log('Arkadaşlık isteği yanıtı gönderiliyor:', data);
        this.emit('friend_request_response', {
          requestId: data.requestId,
          status: data.status,
          senderId: data.senderId,
          receiverId: data.receiverId,
          request: data.request
        });
      }
      
    } catch (error) {
      console.error('Mesaj işleme hatası:', error);
    }
  }

  private cleanup() {
    // Event listener'ları temizle
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    
    // Interval'ları temizle
    this.clearPingInterval();
    if (this.visibilityChangeTimeout) {
      clearTimeout(this.visibilityChangeTimeout);
      this.visibilityChangeTimeout = null;
    }
    
    // Mesaj kuyruğunu temizle
    this.messageQueue = [];
    
    // Event listener'ları temizle
    this.eventListeners.clear();
  }

  // Yeni metod: Kullanıcı durumunu güncelle
  updateUserStatus(status: string) {
    if (this.isAuthenticated && this.ws?.readyState === WebSocket.OPEN) {
      console.log('Kullanıcı durumu güncelleniyor:', status);
      this.send({
        type: 'SET_PRESENCE',
        data: {
          status: status,
          timestamp: new Date().toISOString()
        }
      });
    } else {
      console.log('Durum güncellenemedi: Bağlantı kapalı veya auth yapılmamış');
    }
  }

  // Yeni metod: Presence durumlarını yenile
  refreshPresenceStates() {
    if (this.isAuthenticated && this.ws?.readyState === WebSocket.OPEN) {
      this.send({
        type: 'GET_ALL_PRESENCE',
        data: {
          priority: 'high',
          immediate: true,
          batch_size: 100
        }
      });
    }
  }

  // Yeni metod: Belirli kullanıcıların presence durumlarını yenile
  refreshUserPresence(userIds: string[]) {
    if (this.isAuthenticated && this.ws?.readyState === WebSocket.OPEN) {
      console.log('Belirli kullanıcıların presence durumları yenileniyor:', userIds);
      this.send({
        type: 'GET_PRESENCE',
        data: {
          userIds: userIds
        }
      });
    }
  }
} 