import { API } from './api';
import { Friend } from '../types/friend';

export interface FriendRequest {
  id: number;
  sender_id: number;
  receiver_id: number;
  sender_username: string;
  receiver_username: string;
  status: 'pending' | 'accepted' | 'rejected';
  created_at: string;
  avatar?: string;
}

interface FriendshipResponse {
  message: string;
  request?: FriendRequest;
}

interface RequestsResponse {
  success: boolean;
  data?: {
    incoming: FriendRequest[];
    outgoing: FriendRequest[];
  };
  error?: string;
}

export const sendFriendRequest = async (senderUsername: string, receiverUsername: string) => {
  try {
    const response = await API.post<FriendshipResponse>('/api/friendship/send-request', {
      senderUsername,
      receiverUsername
    });

    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    if (error.response?.status === 400) {
      return {
        success: false,
        error: error.response.data.error,
        request: error.response.data.request
      };
    }

    if (error.response?.status === 404) {
      return {
        success: false,
        error: 'Kullanıcı bulunamadı'
      };
    }

    return {
      success: false,
      error: 'Arkadaşlık isteği gönderilirken bir hata oluştu'
    };
  }
};

export const getPendingRequests = async (): Promise<RequestsResponse> => {
  try {
    const response = await API.get<{ incoming: FriendRequest[]; outgoing: FriendRequest[] }>('/api/friendship/pending-requests');
    
    // Avatar URL'lerini işle
    const processRequests = async (requests: FriendRequest[], isIncoming: boolean) => {
      const API_URL = window.location.hostname === 'localhost'
        ? 'http://localhost:3001'
        : 'https://api.lazcord.aztmedya.net';

      return Promise.all(requests.map(async (request) => {
        try {
          // Gelen isteklerde gönderenin, giden isteklerde alıcının avatar bilgisini al
          const userId = isIncoming ? request.sender_id : request.receiver_id;
          const userResponse = await API.get(`/api/user/settings/${userId}`);
          const avatarUrl = userResponse.data.avatar;
          
          if (avatarUrl) {
            request.avatar = avatarUrl.startsWith('data:') || avatarUrl.startsWith('http')
              ? avatarUrl
              : `${API_URL}${avatarUrl.startsWith('/') ? avatarUrl : `/${avatarUrl}`}`;
          }
        } catch (error) {
          console.error(`Avatar yüklenirken hata: ${error}`);
        }
        return request;
      }));
    };

    const [processedIncoming, processedOutgoing] = await Promise.all([
      processRequests(response.data.incoming || [], true),
      processRequests(response.data.outgoing || [], false)
    ]);

    return {
      success: true,
      data: {
        incoming: processedIncoming,
        outgoing: processedOutgoing
      }
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.error || 'Bekleyen istekler alınamadı'
    };
  }
};

export const acceptFriendRequest = async (requestId: number) => {
  try {
    const response = await API.post<FriendshipResponse>(`/api/friendship/accept/${requestId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    return {
      success: false,
      error: 'İstek kabul edilirken bir hata oluştu'
    };
  }
};

export const rejectFriendRequest = async (requestId: number) => {
  try {
    const response = await API.post<FriendshipResponse>(`/api/friendship/reject/${requestId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    return {
      success: false,
      error: 'İstek reddedilirken bir hata oluştu'
    };
  }
};

export const cancelFriendRequest = async (requestId: number) => {
  try {
    const response = await API.delete<FriendshipResponse>(`/api/friendship/cancel/${requestId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    return {
      success: false,
      error: 'İstek iptal edilirken bir hata oluştu'
    };
  }
};

export const getAcceptedFriends = async () => {
  try {
    const response = await API.get<Friend[]>('/api/friendship/accepted-friends');
    
    const API_URL = window.location.hostname === 'localhost'
      ? 'http://localhost:3001'
      : 'https://api.lazcord.aztmedya.net';

    // Her arkadaş için avatar bilgisini al
    const friendsWithAvatars = await Promise.all(response.data.map(async (friend) => {
      try {
        const userResponse = await API.get(`/api/user/settings/${friend.id}`);
        const avatarUrl = userResponse.data.avatar;
        
        if (avatarUrl) {
          friend.avatar = avatarUrl.startsWith('data:') || avatarUrl.startsWith('http')
            ? avatarUrl
            : `${API_URL}${avatarUrl.startsWith('/') ? avatarUrl : `/${avatarUrl}`}`;
        }
      } catch (error) {
        console.error(`Avatar yüklenirken hata: ${error}`);
      }
      return friend;
    }));

    return friendsWithAvatars;
  } catch (error) {
    console.error('Kabul edilen arkadaşlar alınırken hata:', error);
    throw error;
  }
};

export const removeFriend = async (friendId: number) => {
  try {
    const response = await API.delete(`/api/friendship/remove/${friendId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.error || 'Arkadaşlıktan çıkarma işlemi başarısız oldu'
    };
  }
};

export const blockUser = async (userId: number) => {
  try {
    const response = await API.post(`/api/friendship/block/${userId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.error || 'Kullanıcı engelleme işlemi başarısız oldu'
    };
  }
};

export const unblockUser = async (userId: number) => {
  try {
    const response = await API.delete(`/api/friendship/unblock/${userId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.error || 'Engel kaldırma işlemi başarısız oldu'
    };
  }
};

export const getBlockedUsers = async () => {
  try {
    const response = await API.get<Friend[]>('/api/friendship/blocked-users');
    
    const API_URL = window.location.hostname === 'localhost'
      ? 'http://localhost:3001'
      : 'https://api.lazcord.aztmedya.net';

    // Her engellenen kullanıcı için avatar bilgisini al
    const usersWithAvatars = await Promise.all(response.data.map(async (user) => {
      try {
        const userResponse = await API.get(`/api/user/settings/${user.id}`);
        const avatarUrl = userResponse.data.avatar;
        
        if (avatarUrl) {
          user.avatar = avatarUrl.startsWith('data:') || avatarUrl.startsWith('http')
            ? avatarUrl
            : `${API_URL}${avatarUrl.startsWith('/') ? avatarUrl : `/${avatarUrl}`}`;
        }
      } catch (error) {
        console.error(`${user.username} için avatar yüklenirken hata:`, error);
      }
      return user;
    }));

    return usersWithAvatars;
  } catch (error) {
    console.error('Engellenen kullanıcılar alınırken hata:', error);
    throw error;
  }
};

export const isUserBlocked = async (userId: number): Promise<boolean> => {
  try {
    const response = await API.get(`/api/friendship/check-block/${userId}`);
    return response.data.isBlocked;
  } catch (error) {
    console.error('Engelleme durumu kontrol edilirken hata:', error);
    return false;
  }
}; 