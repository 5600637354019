import { useState, useEffect, useCallback, useRef } from 'react';
import { useFriendRequests } from '../contexts/FriendRequestsContext';
import { useAuth } from '../contexts/AuthContext';
import { useActiveChat } from '../contexts/ActiveChatContext';
import { useSound } from './useSound';
import axios from 'axios';

export interface DMUser {
  id: number;
  username: string;
  created_at: string;
  lastMessage?: string;
  unreadCount?: number;
  avatar?: string;
}

const getUserIdFromToken = (token: string): number | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload).id;
  } catch (error) {
    return null;
  }
};

export const useDMUsers = () => {
  const [dmUsers, setDmUsers] = useState<DMUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { wsClient } = useFriendRequests();
  const { currentUser } = useAuth();
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);
  const { activeChannel, isChannelVisible } = useActiveChat();
  const { playSound } = useSound();
  const lastLoadTimeRef = useRef<number>(0);
  const hasInitiallyLoaded = useRef(false);

  useEffect(() => {
    if (currentUser?.token) {
      const userId = getUserIdFromToken(currentUser.token);
      setCurrentUserId(userId);
    }
  }, [currentUser]);

  // Sohbet açıldığında mesajları okundu olarak işaretle
  useEffect(() => {
    if (activeChannel && currentUserId && wsClient?.isConnected() && isChannelVisible) {
      let markMessageTimeout: ReturnType<typeof setTimeout> | null = null;
      let hasMarkedMessages = false;

      // Mesajları okundu olarak işaretle
      const markMessagesRead = () => {
        if (hasMarkedMessages) return;
        
        hasMarkedMessages = true;
        wsClient.send({
          type: 'MARK_MESSAGES_READ',
          data: {
            channelId: activeChannel,
            userId: currentUserId
          }
        });

        // DM kullanıcılarının bildirimlerini sıfırla
        setDmUsers(prev => {
          return prev.map(user => {
            const userChannelId1 = `${user.id}-${currentUserId}`;
            const userChannelId2 = `${currentUserId}-${user.id}`;
            
            if (activeChannel === userChannelId1 || activeChannel === userChannelId2) {
              return { ...user, unreadCount: 0 };
            }
            return user;
          });
        });
      };

      // Sadece bir kere gönder ve önceki timeout'u temizle
      if (markMessageTimeout) {
        clearTimeout(markMessageTimeout);
      }
      markMessageTimeout = setTimeout(markMessagesRead, 500);

      return () => {
        if (markMessageTimeout) {
          clearTimeout(markMessageTimeout);
        }
      };
    }
  }, [activeChannel, currentUserId, wsClient, isChannelVisible]);

  const handleNewMessage = useCallback((message: any) => {
    // Mesajın kanal ID'sini kontrol et
    const channelParts = message.channel_id.split('-');
    const [userId1, userId2] = channelParts.map(Number);
    
    // Geçersiz kanal ID'si kontrolü
    if (!userId1 || !userId2) return;

    // Bu mesaj mevcut kullanıcıya ait bir kanalda mı?
    const isUserInvolved = userId1 === currentUserId || userId2 === currentUserId;
    if (!isUserInvolved) return;

    // Diğer kullanıcının ID'sini bul
    const otherUserId = userId1 === currentUserId ? userId2 : userId1;
    if (!otherUserId) return;

    // Mesajı gönderen kişi kontrolü
    const isCurrentUser = message.sender_id === currentUserId;

    // Eğer bu mesaj aktif kanaldan farklı bir kanala aitse
    if (message.channel_id !== activeChannel) {
      // Bildirim sesi kontrolü - sadece mesaj başka kullanıcıdan geliyorsa ve kanal görünür değilse çal
      if (!isCurrentUser && !isChannelVisible) {
        playSound('message');
      }

      // DM listesini güncelle
      setDmUsers(prev => {
        const targetUser = prev.find(u => u.id === otherUserId);
        if (!targetUser) return prev;

        const otherUsers = prev.filter(u => u.id !== otherUserId);
        
        const updatedUser = {
          ...targetUser,
          lastMessage: message.content,
          created_at: message.created_at,
          unreadCount: !isCurrentUser ? (targetUser.unreadCount || 0) + 1 : targetUser.unreadCount || 0
        };

        return [updatedUser, ...otherUsers];
      });

      // Kullanıcı bilgilerini API'den güncelle
      axios.get(`/api/user/settings/${otherUserId}`, {
        headers: {
          'Authorization': `Bearer ${currentUser?.token}`
        }
      }).then(response => {
        const userInfo = response.data;
        
        setDmUsers(prev => {
          const targetUser = prev.find(u => u.id === otherUserId);
          const otherUsers = prev.filter(u => u.id !== otherUserId);
          
          const updatedUser = {
            id: otherUserId,
            username: userInfo.username,
            avatar: userInfo.avatar,
            lastMessage: message.content,
            created_at: message.created_at,
            unreadCount: !isCurrentUser ? ((targetUser?.unreadCount || 0) + 1) : targetUser?.unreadCount || 0
          };

          return [updatedUser, ...otherUsers];
        });
      }).catch(error => {
        console.error('Kullanıcı bilgileri alınamadı:', error);
      });
      return; // Farklı kanala ait mesaj için burada işlemi sonlandır
    }

    // Eğer mesaj aktif kanala aitse ve görünürse
    if (message.channel_id === activeChannel && isChannelVisible) {
      // Mesajı okundu olarak işaretle
      wsClient?.send({
        type: 'MARK_MESSAGES_READ',
        data: {
          channelId: message.channel_id,
          userId: currentUserId
        }
      });

      // DM listesini güncelle (okundu durumu için)
      setDmUsers(prev => {
        const targetUser = prev.find(u => u.id === otherUserId);
        if (!targetUser) return prev;

        const otherUsers = prev.filter(u => u.id !== otherUserId);
        
        const updatedUser = {
          ...targetUser,
          lastMessage: message.content,
          created_at: message.created_at,
          unreadCount: 0
        };

        return [updatedUser, ...otherUsers];
      });
    }
  }, [currentUserId, activeChannel, isChannelVisible, wsClient, currentUser?.token, playSound]);

  useEffect(() => {
    if (!wsClient || !currentUser?.token || !currentUserId) {
      setIsLoading(false);
      return;
    }

    let isSubscribed = true;
    const REFRESH_INTERVAL = 30000; // 30 saniye

    const loadDMUsers = async () => {
      const now = Date.now();
      
      // Eğer ilk yükleme yapıldıysa ve DM listesi doluysa yükleme gösterme
      if (hasInitiallyLoaded.current && dmUsers.length > 0) {
        return;
      }

      try {
        if (!wsClient.isConnected()) {
          await wsClient.waitForConnection();
        }
        
        if (isSubscribed && wsClient.isConnected()) {
          // Sadece ilk yüklemede ve DM listesi boşsa loading göster
          if (!hasInitiallyLoaded.current && dmUsers.length === 0) {
            setIsLoading(true);
          }

          lastLoadTimeRef.current = now;
          await wsClient.send({
            type: 'GET_DM_USERS',
            data: { userId: currentUserId }
          });
          
          // İlk yüklemeyi tamamlandı olarak işaretle
          hasInitiallyLoaded.current = true;
        }
      } catch (error) {
        console.error('DM kullanıcıları yüklenirken hata:', error);
        setIsLoading(false);
      }
    };

    const handleDMUsers = async (data: any) => {
      if (!Array.isArray(data) || !isSubscribed) return;

      try {
        const API_URL = window.location.hostname === 'localhost'
          ? 'http://localhost:3001'
          : 'https://api.lazcord.aztmedya.net';

        // Mevcut kullanıcıları tut
        const currentUsers = [...dmUsers];
        const now = Date.now();

        // Her kullanıcı için avatar bilgisini al
        const userPromises = data.map(async (user: any) => {
          try {
            // Önce mevcut kullanıcı bilgilerini kullan
            const existingUser = currentUsers.find(u => u.id === user.id);
            
            // Eğer mevcut kullanıcı varsa ve son 30 saniye içinde güncellendiyse, mevcut bilgileri kullan
            if (existingUser && now - lastLoadTimeRef.current < REFRESH_INTERVAL) {
              return existingUser;
            }

            const response = await axios.get(`${API_URL}/api/user/settings/${user.id}`, {
              headers: {
                'Authorization': `Bearer ${currentUser?.token}`
              }
            });

            // Avatar URL'sini işle
            let avatarUrl = response.data.avatar;
            if (avatarUrl && !avatarUrl.startsWith('data:') && !avatarUrl.startsWith('http')) {
              avatarUrl = `${API_URL}${avatarUrl.startsWith('/') ? avatarUrl : `/${avatarUrl}`}`;
            }

            const unreadCount = parseInt(user.unread_count) || 0;
            const channelId = `${Math.min(currentUserId, user.id)}-${Math.max(currentUserId, user.id)}`;
            const isActiveChat = activeChannel === channelId && isChannelVisible;

            return {
              id: user.id,
              username: user.username,
              lastMessage: user.last_message,
              created_at: user.user_created_at,
              unreadCount: isActiveChat ? 0 : (existingUser?.unreadCount || unreadCount),
              avatar: avatarUrl || existingUser?.avatar
            };
          } catch (err) {
            // Hata durumunda mevcut kullanıcı bilgilerini koru
            const existingUser = currentUsers.find(u => u.id === user.id);
            return existingUser || {
              id: user.id,
              username: user.username,
              lastMessage: user.last_message,
              created_at: user.user_created_at,
              unreadCount: parseInt(user.unread_count) || 0,
              avatar: undefined
            };
          }
        });

        const uniqueUsers = await Promise.all(userPromises);
        if (isSubscribed) {
          setDmUsers(uniqueUsers);
          setIsLoading(false);
        }
      } catch (err) {
        console.error('Kullanıcı avatarları yüklenirken hata:', err);
        setIsLoading(false);
      }
    };

    const handleMessagesMarkedRead = (data: any) => {
      if (data.userId === currentUserId) {
        setDmUsers(prev => {
          return prev.map(user => {
            const channelParts = data.channelId.split('-');
            const otherUserId = channelParts.find((id: string) => parseInt(id) !== currentUserId);
            
            if (otherUserId && parseInt(otherUserId) === user.id) {
              return { ...user, unreadCount: 0 };
            }
            return user;
          });
        });
      }
    };

    // Event listener'ları ekle
    wsClient.on('DM_USERS_LOADED', handleDMUsers);
    wsClient.on('NEW_MESSAGE', handleNewMessage);
    wsClient.on('MESSAGES_MARKED_READ', handleMessagesMarkedRead);

    // İlk yükleme
    loadDMUsers();

    // Periyodik yenileme - sadece arka planda güncelleme yap
    const interval = setInterval(() => {
      if (hasInitiallyLoaded.current) {
        wsClient.send({
          type: 'GET_DM_USERS',
          data: { userId: currentUserId }
        }).catch(error => {
          console.error('Periyodik DM güncellemesi başarısız:', error);
        });
      }
    }, REFRESH_INTERVAL);

    return () => {
      isSubscribed = false;
      clearInterval(interval);
      wsClient.off('DM_USERS_LOADED', handleDMUsers);
      wsClient.off('NEW_MESSAGE', handleNewMessage);
      wsClient.off('MESSAGES_MARKED_READ', handleMessagesMarkedRead);
    };
  }, [wsClient, currentUser, currentUserId, activeChannel, isChannelVisible, handleNewMessage, dmUsers]);

  // DM kullanıcısını kaldır
  const removeDMUser = useCallback((userId: string) => {
    setDmUsers(prev => prev.filter(user => user.id.toString() !== userId));
  }, []);

  return {
    dmUsers,
    isLoading,
    removeDMUser
  };
}; 