import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ChannelSidebar from '../components/layout/ChannelSidebar';
import UserProfile from '../components/layout/UserProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../contexts/AuthContext';
import { useFriendRequests } from '../contexts/FriendRequestsContext';
import ColorAvatar from '../components/common/ColorAvatar';
import axios from 'axios';
import { useNotifications } from '../contexts/NotificationContext';
import { useActiveChat } from '../contexts/ActiveChatContext';
import ChatView from '../components/ChatView';
import ReactDOM from 'react-dom';
import { useSound } from '../hooks/useSound';
import { API } from '../services/api';
import { toast } from 'react-toastify';

interface LocationState {
  username?: string;
  created_at?: string | Date;
  avatar_url?: string;
}

interface Message {
  id: number | string;
  channel_id: string;
  sender_id: number;
  sender_username?: string;
  sender_avatar?: string | null;
  sender_avatar_url?: string | null;
  content: string;
  created_at: Date;
  isCurrentUser?: boolean;
}

interface ServerMessage {
  id: number;
  channel_id: string;
  sender_id: number;
  content: string;
  created_at: string | Date;
}

interface UserInfo {
  id: number;
  username: string;
  avatar?: string | null;
  created_at?: string | Date;
}

// JWT'den user ID'yi çıkaran yardımcı fonksiyon
const getUserIdFromToken = (token: string): number | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload).id;
  } catch (error) {
    console.error('Token çözme hatası:', error);
    return null;
  }
};

// Tarih formatlama fonksiyonu
function formatMessageDate(date: Date, isTooltip: boolean = false) {
  const now = new Date();
  const messageDate = new Date(date);
  
  // Tarihleri saat 00:00'a ayarla (sadece gün karşılaştırması için)
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const messageDay = new Date(messageDate.getFullYear(), messageDate.getMonth(), messageDate.getDate());

  // Tooltip için tam tarih formatı
  if (isTooltip) {
    return messageDate.toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  // Ayraç için tarih formatı
  if (messageDay.getTime() === today.getTime()) {
    return 'Bugün';
  } else {
    return messageDate.toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }
}

// Kısa tarih formatlama fonksiyonu
const formatShortDate = (date: Date) => {
  const now = new Date();
  const messageDate = new Date(date);
  
  // Tarihleri saat 00:00'a ayarla
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const messageDay = new Date(messageDate.getFullYear(), messageDate.getMonth(), messageDate.getDate());

  const time = messageDate.toLocaleTimeString('tr-TR', { 
    hour: '2-digit', 
    minute: '2-digit' 
  });

  if (messageDay.getTime() === today.getTime()) {
    return time;
  } else {
    return `${messageDate.toLocaleDateString('tr-TR', { 
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })} ${time}`;
  }
};

// Davet linki kontrolü için yardımcı fonksiyon
const isInviteLink = (content: string): boolean => {
  const inviteRegex = /(?:https?:\/\/)?(?:www\.)?lazcord\.aztmedya\.net\/invite\/[\w-]+/i;
  return inviteRegex.test(content);
};

// Davet kartı bileşeni
const InviteCard: React.FC<{ inviteLink: string }> = ({ inviteLink }) => {
  const { wsClient } = useFriendRequests();

  const handleJoin = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      // URL'den davet kodunu çıkar
      const inviteCode = inviteLink.split('/invite/')[1];
      console.log('Davet kodu:', inviteCode);
      
      if (!inviteCode) {
        toast.error('Geçersiz davet linki');
        return;
      }

      const response = await API.post(`/api/invites/join`, {
        code: inviteCode
      });

      if (response.data.success) {
        toast.success('Sunucuya başarıyla katıldınız!');
        if (wsClient) {
          wsClient.send({ type: 'REFRESH_SERVERS' });
        }
      }
    } catch (error: any) {
      console.error('Sunucuya katılma hatası:', error);
      toast.error(error.response?.data?.message || 'Sunucuya katılırken bir hata oluştu');
    }
  };

  return (
    <div className="bg-[#2f3136] border border-[#202225] rounded-md p-4 mt-2 max-w-sm hover:bg-[#36393f] transition-colors">
      <div className="flex items-center space-x-4">
        <div className="w-12 h-12 bg-[#36393f] rounded-full flex items-center justify-center">
          <svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/>
          </svg>
        </div>
        <div className="flex-1">
          <h3 className="text-white font-semibold">Discord Sunucusu Daveti</h3>
          <p className="text-[#b9bbbe] text-sm">Sunucuya katılmak için tıkla</p>
          <button 
            onClick={handleJoin}
            className="mt-2 bg-[#5865f2] text-white px-4 py-1 rounded hover:bg-[#4752c4] transition-colors"
          >
            Katıl
          </button>
          <p className="text-[#b9bbbe]/50 text-xs mt-2">Sınırsız Davet</p>
        </div>
      </div>
    </div>
  );
};

// Mesaj içeriği render fonksiyonu
const renderMessageContent = (content: string, messageId: number | string, senderId: number): React.ReactNode => {
  // URL'leri bul
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = content.split(urlRegex);
  const urls = content.match(urlRegex) || [];
  
  // Davet linki var mı kontrol et
  const inviteUrl = urls.find(url => isInviteLink(url));
  
  if (inviteUrl) {
    // Mesajın geri kalanını davet linkini çıkararak hazırla
    const messageWithoutInvite = parts.reduce((acc, part, index) => {
      if (urls[index] === inviteUrl) {
        return acc + part;
      }
      return acc + part + (urls[index] || '');
    }, '');

    return (
      <div className="space-y-2">
        {messageWithoutInvite && (
          <div className="text-discord-light break-words whitespace-pre-wrap">
            {messageWithoutInvite}
          </div>
        )}
        <InviteCard inviteLink={inviteUrl} />
      </div>
    );
  }

  // Normal mesaj için gelişmiş URL işleme
  return (
    <div className="text-discord-light break-words whitespace-pre-wrap">
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {urls[index] && (
            <a 
              href={urls[index]} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              {urls[index]}
            </a>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

// Mesaj bileşeni
const Message: React.FC<{
  message: Message;
  isFirstInGroup: boolean;
  showDate: boolean;
}> = React.memo(({ message, isFirstInGroup, showDate }) => {
  const { currentUser } = useAuth();
  const isOwnMessage = message.sender_id === (currentUser?.id ? Number(currentUser.id) : 0);

  return (
    <div className="px-4">
      {showDate && (
        <div className="flex items-center justify-center my-4">
          <div className="bg-[#383A40] text-xs text-discord-light px-2 py-1 rounded" 
               title={formatMessageDate(new Date(message.created_at), true)}>
            {formatMessageDate(new Date(message.created_at))}
          </div>
        </div>
      )}
      <div className={`flex ${isFirstInGroup ? 'mt-4' : 'mt-0.5'}`}>
        {isFirstInGroup && (
          <div className="w-10 h-10 rounded-full overflow-hidden flex-shrink-0 mr-4">
            {message.sender_avatar ? (
              <img 
                src={message.sender_avatar}
                alt={message.sender_username || ''}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.currentTarget.style.display = 'none';
                  const parent = e.currentTarget.parentElement;
                  if (parent) {
                    const colorAvatar = document.createElement('div');
                    colorAvatar.className = 'w-full h-full';
                    parent.appendChild(colorAvatar);
                    ReactDOM.render(
                      <ColorAvatar 
                        userId={message.sender_id.toString()}
                        username={message.sender_username || ''}
                        size="md"
                      />,
                      colorAvatar
                    );
                  }
                }}
              />
            ) : (
              <ColorAvatar 
                userId={message.sender_id.toString()}
                username={message.sender_username || ''}
                size="md"
              />
            )}
          </div>
        )}
        {!isFirstInGroup && <div className="w-10 mr-4" />}
        <div className="flex-1">
          {isFirstInGroup && (
            <div className="flex items-baseline">
              <span className="font-medium text-white mr-2 cursor-pointer hover:underline">
                {message.sender_username}
              </span>
              <span className="text-xs text-discord-light" 
                    title={formatMessageDate(new Date(message.created_at), true)}>
                {formatShortDate(new Date(message.created_at))}
              </span>
            </div>
          )}
          <div className="hover:bg-discord-light/5 rounded px-1 py-[1px] -ml-1">
            {renderMessageContent(message.content, message.id, message.sender_id)}
          </div>
        </div>
      </div>
    </div>
  );
});

// MessageInput bileşenini ayrı bir component olarak çıkaralım
const MessageInput: React.FC<{
  onSendMessage: (content: string) => void;
  friendUsername?: string;
}> = React.memo(({ onSendMessage, friendUsername }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim()) return;
    
    onSendMessage(inputValue.trim());
    setInputValue('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="bg-discord-dark rounded-lg flex items-center p-2">
        <button 
          type="button"
          className="text-discord-light hover:text-white p-2 rounded-full hover:bg-discord-light/10 transition-colors"
          onClick={() => console.log('Dosya gönderme tıklandı')}
        >
          <FontAwesomeIcon icon={faPaperclip} className="w-5 h-5" />
        </button>

        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder={`@${friendUsername || 'Kullanıcı'} kullanıcısına mesaj gönder`}
          className="flex-1 bg-transparent text-discord-light outline-none mx-2 placeholder:text-discord-light/50"
        />

        <button 
          type="submit"
          disabled={!inputValue.trim()}
          className={`p-2 rounded-full transition-colors ${
            inputValue.trim() 
              ? 'text-white hover:bg-discord-light/10' 
              : 'text-discord-light/50 cursor-not-allowed'
          }`}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="w-5 h-5" />
        </button>
      </div>
    </form>
  );
});

const API_URL = window.location.hostname === 'localhost'
  ? 'http://localhost:3001'
  : 'https://api.lazcord.aztmedya.net';

const DMChannel: React.FC = () => {
  const { friendId } = useParams<{ friendId?: string }>();
  const location = useLocation();
  const state = location.state as LocationState;
  const { currentUser } = useAuth();
  const { wsClient } = useFriendRequests();
  const { clearDMNotifications } = useNotifications();
  const [messages, setMessages] = useState<Message[]>([]);
  const [userAvatars, setUserAvatars] = useState<{[key: string]: string | null}>({});
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [isNearBottom, setIsNearBottom] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { setActiveChat } = useActiveChat();

  // Mesajları yüklendiğinde
  const handleMessagesLoaded = async (data: any) => {
    if (!Array.isArray(data) || !currentUser) return;

    try {
      // Mesajları sırala
      const sortedMessages = data.sort((a, b) => 
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

      // Önce karşı kullanıcının bilgilerini al
      let otherUserInfo: UserInfo | null = null;
      if (friendId) {
        try {
          const response = await API.get(`/api/user/settings/${friendId}`);
          otherUserInfo = response.data;
        } catch (err) {
          console.error('Karşı kullanıcı bilgileri alınamadı:', err);
        }
      }

      // Kendi bilgilerimizi al
      let currentUserInfo: UserInfo | null = null;
      try {
        const response = await API.get(`/api/user/settings/${currentUser.id}`);
        currentUserInfo = response.data;
      } catch (err) {
        console.error('Kendi bilgilerimiz alınamadı:', err);
      }

      // Mesajları hazırla
      const processedMessages = sortedMessages.map(message => {
        const isCurrentUser = message.sender_id === currentUser.id;
        
        if (isCurrentUser) {
          return {
            ...message,
            created_at: new Date(message.created_at),
            sender_username: currentUser.username,
            sender_avatar: currentUserInfo?.avatar || currentUser.avatar,
            sender_avatar_url: currentUserInfo?.avatar || currentUser.avatar,
            isCurrentUser: true
          } as Message;
        } else {
          return {
            ...message,
            created_at: new Date(message.created_at),
            sender_username: otherUserInfo?.username || state?.username || 'Kullanıcı',
            sender_avatar: otherUserInfo?.avatar || state?.avatar_url,
            sender_avatar_url: otherUserInfo?.avatar || state?.avatar_url,
            isCurrentUser: false
          } as Message;
        }
      });

      setMessages(processedMessages);
    } catch (err) {
      console.error('Mesajlar işlenirken hata:', err);
    }
  };

  // Yeni mesaj geldiğinde çalışacak fonksiyon
  const handleNewMessage = useCallback(async (message: any) => {
    console.log('handleNewMessage çağrıldı:', message);
    
    if (!currentUser || !friendId) return;

    // Mesajın ait olduğu kanalı kontrol et
    const userId = getUserIdFromToken(currentUser.token);
    if (!userId) return;

    // Kanal ID'sini oluştur
    const channelId = [userId, parseInt(friendId)].sort((a, b) => a - b).join('-');

    // Mesaj bu kanala ait değilse işleme
    if (message.channel_id !== channelId) return;

    try {
      const isCurrentUser = message.sender_id === currentUser.id;
      
      // Kullanıcı bilgilerini al
      let userInfo = null;
      try {
        const response = await API.get(`/api/user/settings/${message.sender_id}`);
        userInfo = response.data;
        console.log('Kullanıcı bilgileri alındı:', userInfo);
      } catch (err) {
        console.error('Kullanıcı bilgileri alınamadı:', err);
      }

      // Mesajı hazırla
      const newMessage: Message = {
        ...message,
        created_at: new Date(message.created_at),
        sender_username: userInfo?.username || (isCurrentUser ? currentUser.username : state?.username || 'Kullanıcı'),
        sender_avatar: userInfo?.avatar || (isCurrentUser ? currentUser.avatar : state?.avatar_url),
        sender_avatar_url: userInfo?.avatar || (isCurrentUser ? currentUser.avatar : state?.avatar_url),
        isCurrentUser
      };

      console.log('Yeni mesaj hazırlandı:', newMessage);

      setMessages(prev => [...prev, newMessage]);

      // Mesaj alındığında ve görünürse scroll
      if (isNearBottom) {
        requestAnimationFrame(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        });
      } else {
        setShowScrollButton(true);
      }

    } catch (error) {
      console.error('Mesaj işlenirken hata:', error);
      toast.error('Mesaj alınırken bir hata oluştu');
    }
  }, [currentUser, friendId, state, isNearBottom]);

  // Scroll kontrolü
  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const bottom = scrollHeight - scrollTop - clientHeight;
      const isNear = bottom < 100;
      setIsNearBottom(isNear);
      setShowScrollButton(!isNear);
    }
  };

  // En alta kaydır butonu
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    if (!wsClient || !friendId || !currentUser?.token) {
      return;
    }

    const token = currentUser.token;
    const userId = getUserIdFromToken(token);
    if (!userId) {
      return;
    }

    const channelId = [userId, parseInt(friendId)].sort((a, b) => a - b).join('-');
    
    // Bildirimleri temizle
    clearDMNotifications(channelId);

    let isSubscribed = true;

    // Event listener'ları ekle
    wsClient.on('NEW_MESSAGE', handleNewMessage);
    wsClient.on('MESSAGES_LOADED', handleMessagesLoaded);
    wsClient.on('error', (data: any) => {
      // Hata mesajını toast ile göster
      toast.error(data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    });

    // İlk mesajları yükle
    wsClient.send({
      type: 'GET_MESSAGES',
      data: {
        channel_id: channelId,
        limit: 50,
        offset: 0
      }
    });

    return () => {
      isSubscribed = false;
      wsClient.off('NEW_MESSAGE', handleNewMessage);
      wsClient.off('MESSAGES_LOADED', handleMessagesLoaded);
      wsClient.off('error');
    };
  }, [wsClient, friendId, currentUser?.token]);

  // Mesaj gönderme işleyicisi - memoize
  const handleSendMessage = React.useCallback(async (content: string) => {
    if (!wsClient || !currentUser?.token || !friendId) {
      return;
    }

    const userId = getUserIdFromToken(currentUser.token);
    if (!userId) {
      console.error('User ID alınamadı!');
      return;
    }

    try {
      const channelId = [userId, parseInt(friendId)].sort((a, b) => a - b).join('-');
      
      // Güncel kullanıcı bilgilerini al
      let userInfo: UserInfo | null = null;
      try {
        const response = await API.get(`/api/user/settings/${userId}`);
        userInfo = response.data;
      } catch (err) {
        console.error('Kullanıcı bilgileri alınamadı:', err);
      }
      
      const tempMessage: Message = {
        id: `temp-${Date.now()}`,
        channel_id: channelId,
        sender_id: userId,
        sender_username: currentUser.username,
        sender_avatar: userInfo?.avatar || currentUser.avatar,
        sender_avatar_url: userInfo?.avatar || currentUser.avatar,
        content: content,
        created_at: new Date(),
        isCurrentUser: true
      };
      
      setMessages(prev => [...prev, tempMessage]);
      
      wsClient.send({
        type: 'SEND_MESSAGE',
        data: {
          channel_id: channelId,
          content: content
        }
      });

      requestAnimationFrame(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
    } catch (error) {
      console.error('Mesaj gönderme hatası:', error);
    }
  }, [wsClient, currentUser, friendId]);

  // Mesajları render et
  const renderedMessages = React.useMemo(() => {
    if (!currentUser) return null;

    let lastDate = '';
    let lastSenderId: number | null = null;
    let lastTimestamp: number | null = null;

    return messages.map((message, index) => {
      const currentDate = formatMessageDate(new Date(message.created_at));
      const showDateDivider = lastDate !== currentDate;
      lastDate = currentDate;

      const messageTimestamp = new Date(message.created_at).getTime();
      const timeDiff = lastTimestamp ? messageTimestamp - lastTimestamp : 0;
      const isNewGroup = message.sender_id !== lastSenderId || timeDiff > 5 * 60 * 1000;
      
      lastSenderId = message.sender_id;
      lastTimestamp = messageTimestamp;

      const isCurrentUser = message.sender_id === currentUser.id;
      const shouldShowAvatar = isNewGroup;

      return (
        <React.Fragment key={message.id}>
          {showDateDivider && (
            <div className="flex items-center mb-2 mt-3">
              <div className="flex-1 border-t border-discord-light/10"></div>
              <div className="mx-4 text-xs text-discord-light/50">{currentDate}</div>
              <div className="flex-1 border-t border-discord-light/10"></div>
            </div>
          )}
          <div className={`group flex items-start px-4 ${isNewGroup ? 'mt-[15px]' : 'mt-[0.25px]'}`}>
            {shouldShowAvatar && (
              <div className="flex-shrink-0" style={{ width: '40px', height: '40px' }}>
                {message.sender_avatar_url ? (
                  <img 
                    src={message.sender_avatar_url}
                    alt={message.sender_username || 'Kullanıcı'}
                    className="w-full h-full rounded-full object-cover"
                  />
                ) : (
                  <ColorAvatar 
                    userId={message.sender_id.toString()}
                    username={message.sender_username || 'Kullanıcı'}
                    size="md"
                  />
                )}
              </div>
            )}
            {!shouldShowAvatar && <div style={{ width: '40px' }}></div>}
            <div className="flex-1 min-w-0 pl-4">
              {shouldShowAvatar && (
                <div className="flex items-baseline leading-[1.375rem] mb-[1px]">
                  <span className="font-medium text-white mr-2 cursor-pointer hover:underline">
                    {message.sender_username || 'Kullanıcı'}
                  </span>
                  <div className="relative group/tooltip">
                    <span className="text-xs text-discord-light/50">
                      {formatShortDate(new Date(message.created_at))}
                    </span>
                    <div className="absolute bottom-full left-0 mb-2 hidden group-hover/tooltip:block z-10">
                      <div className="bg-black text-white text-xs px-2 py-1 rounded shadow-lg whitespace-nowrap">
                        {formatMessageDate(new Date(message.created_at), true)}
                      </div>
                      <div className="w-2 h-2 bg-black transform rotate-45 absolute -bottom-1 left-4"></div>
                    </div>
                  </div>
                </div>
              )}
              <div className="hover:bg-discord-light/5 rounded px-1 py-[1px] -ml-1">
                {renderMessageContent(message.content, message.id, message.sender_id)}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  }, [messages, currentUser]);

  useEffect(() => {
    if (currentUser?.token && friendId) {
      const userId = getUserIdFromToken(currentUser.token);
      if (userId) {
        // DM kanal ID'sini oluştur ve sayısal değerleri kullan
        const channelId = [userId, parseInt(friendId)].sort((a, b) => a - b).join('-');
        console.log('DM kanalı aktif edildi:', channelId);
        setActiveChat(channelId, true);

        return () => {
          setActiveChat(null, false);
        };
      }
    }
  }, [friendId, currentUser, setActiveChat]);

  // Mesajlar değiştiğinde en alta scroll yap
  useEffect(() => {
    if (messages.length > 0) {
      requestAnimationFrame(() => {
        messagesEndRef.current?.scrollIntoView();
      });
    }
  }, [messages]);

  // WebSocket mesaj işleme
  useEffect(() => {
    if (!wsClient) return;

    console.log('WebSocket bağlantısı kuruldu');

    const handleWebSocketMessage = (data: any) => {
      console.log('WebSocket mesajı alındı:', {
        type: data.type,
        data: data.data,
        content: data.data?.content
      });

      if (data.type === 'MESSAGE_SENT' || data.type === 'NEW_MESSAGE') {
        handleNewMessage(data.data);
      }
    };

    wsClient.on('message', handleWebSocketMessage);

    return () => {
      console.log('WebSocket bağlantısı kapatıldı');
      wsClient.off('message', handleWebSocketMessage);
    };
  }, [wsClient, handleNewMessage]);

  if (!friendId || !currentUser?.token) {
    return null;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <ChannelSidebar />
      <div className="flex-1 flex min-w-0">
        <div className="flex-1 flex flex-col bg-discord-gray min-w-0">
          {/* Channel Header */}
          <header className="h-12 px-4 border-b border-black/[0.24] flex items-center shadow-sm">
            <div className="flex items-center text-discord-light">
              <FontAwesomeIcon icon={faAt} className="w-5 h-5 mr-2" />
              <span className="font-semibold text-white">{state?.username || 'Kullanıcı'}</span>
            </div>
          </header>

          {/* Messages Area */}
          <div 
            ref={messagesContainerRef}
            className="flex-1 overflow-y-auto overflow-x-hidden p-4 relative scroll-smooth"
            style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#202225 transparent'
            }}
          >
            {renderedMessages}
            <div ref={messagesEndRef} />

            {/* Scroll to Bottom Button */}
            {showScrollButton && (
              <button
                onClick={scrollToBottom}
                className="absolute bottom-4 right-4 bg-discord-dark text-white p-2 rounded-full shadow-lg hover:bg-discord-light/10 transition-colors"
                style={{ opacity: showScrollButton ? 1 : 0 }}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 16l-6-6h12l-6 6z" />
                </svg>
              </button>
            )}
          </div>

          <MessageInput 
            onSendMessage={handleSendMessage}
            friendUsername={state?.username}
          />
        </div>

        {/* User Profile Sidebar */}
        <div className="w-[300px] flex-shrink-0 bg-discord-dark border-l border-black/[0.24]">
          <UserProfile userId={friendId} />
        </div>
      </div>
    </div>
  );
};

export default DMChannel; 