import { createContext, useContext, useState, useEffect } from 'react';
import { API } from '../services/api';
import { toast } from 'react-toastify';

export interface User {
  id: number;
  username: string;
  email: string;
  token: string;
  avatar?: string | null;
  created_at?: string;
}

interface AuthContextType {
  currentUser: User | null;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  login: async () => false,
  logout: () => {},
  setCurrentUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  // Token kontrolü
  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');

      if (!token || !username) {
        setLoading(false);
        return;
      }

      try {
        const response = await API.get('/api/auth/validate', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.valid) {
          const { id, email } = response.data;
          setCurrentUser({ token, username, id, email });
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Token doğrulama hatası:', error);
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  // Storage event listener
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'token' || e.key === 'username') {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        
        if (token && username) {
          // Token'dan kullanıcı bilgilerini al
          try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
              '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            ).join(''));
            const { id, email } = JSON.parse(jsonPayload);
            setCurrentUser({ token, username, id, email });
          } catch (error) {
            console.error('Token çözümleme hatası:', error);
            handleLogout();
          }
        } else {
          handleLogout();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await API.post('/api/auth/login', {
        username,
        password
      });

      if (response.data && response.data.token) {
        const { token, username: serverUsername, id, email } = response.data;
        
        // LocalStorage'a kaydet
        localStorage.setItem('token', token);
        localStorage.setItem('username', serverUsername);

        // State'i güncelle
        setCurrentUser({ token, username: serverUsername, id, email });

        return true;
      }

      return false;
    } catch (error: any) {
      console.error('Giriş hatası:', error);
      
      // Sunucudan gelen hata mesajını göster
      if (error.response?.data?.error) {
        toast.error(error.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark"
        });
      } else {
        toast.error('Giriş yapılırken bir hata oluştu');
      }
      
      return false;
    }
  };

  const handleLogout = () => {
    // LocalStorage'dan temizle
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    
    // State'i temizle
    setCurrentUser(null);

    // Login sayfasına yönlendir
    window.location.href = '/login';
  };

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{
      currentUser,
      login: handleLogin,
      logout: handleLogout,
      setCurrentUser,
    }}>
      {children}
    </AuthContext.Provider>
  );
}; 