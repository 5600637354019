import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { useSound } from '../hooks/useSound';

interface WebSocketContextType {
  socket: WebSocket | null;
  wsClient: WebSocket | null;
  connected: boolean;
  reconnect: () => void;
}

const WebSocketContext = createContext<WebSocketContextType>({
  socket: null,
  wsClient: null,
  connected: false,
  reconnect: () => {}
});

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [connected, setConnected] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const { currentUser } = useAuth();
  const { playSound } = useSound();
  const maxReconnectAttempts = 5;
  const reconnectDelay = 3000;

  const createWebSocket = useCallback(() => {
    if (!currentUser?.token) return null;

    const wsUrl = 'wss://api.lazcord.aztmedya.net/ws';

    console.log('WebSocket bağlantısı kuruluyor:', wsUrl);
    const newSocket = new WebSocket(wsUrl);

    newSocket.onopen = () => {
      console.log('WebSocket bağlantısı kuruldu');
      setConnected(true);
      setReconnectAttempts(0);
      
      // Auth mesajını gönder
      newSocket.send(JSON.stringify({
        type: 'AUTH',
        token: currentUser.token
      }));
    };

    newSocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log('WebSocket mesajı alındı:', data);

        if (data.type === 'NEW_MESSAGE' && data.data.sender_id !== currentUser.id) {
          playSound('message');
        }

        if (data.type === 'FRIEND_REQUEST_RECEIVED') {
          playSound('friendRequest');
        }
      } catch (error) {
        console.error('WebSocket mesaj işleme hatası:', error);
      }
    };

    newSocket.onclose = (event) => {
      console.log('WebSocket bağlantısı kapandı:', event.reason);
      setConnected(false);

      // Otomatik yeniden bağlanma
      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          setReconnectAttempts(prev => prev + 1);
          reconnect();
        }, reconnectDelay);
      }
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket hatası:', error);
      setConnected(false);
    };

    return newSocket;
  }, [currentUser?.token, reconnectAttempts]);

  const reconnect = useCallback(() => {
    if (socket) {
      socket.close();
    }
    const newSocket = createWebSocket();
    if (newSocket) {
      setSocket(newSocket);
    }
  }, [socket, createWebSocket]);

  useEffect(() => {
    const newSocket = createWebSocket();
    if (newSocket) {
      setSocket(newSocket);
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [currentUser?.token]);

  return (
    <WebSocketContext.Provider value={{
      socket,
      wsClient: socket,
      connected,
      reconnect: createWebSocket
    }}>
      {children}
    </WebSocketContext.Provider>
  );
}; 