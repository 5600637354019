import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faUserPlus, faUserMinus, faBan } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import ColorAvatar from '../common/ColorAvatar';
import { useProfile } from '../../contexts/ProfileContext';
import { API } from '../../services/api';
import { useFriendRequests } from '../../contexts/FriendRequestsContext';
import ReactDOM from 'react-dom/client';
import { sendFriendRequest, removeFriend, blockUser, isUserBlocked, unblockUser } from '../../services/friendshipService';
import { toast } from 'react-toastify';

interface UserProfileProps {
  userId: string;
  mutualFriends?: number;
}

interface LocationState {
  username: string;
  created_at?: string;
}

interface UserProfileSettings {
  avatar?: string;
  banner?: string;
  bannerColor?: string;
  aboutMe?: string;
}

interface MutualFriend {
  id: number;
  username: string;
  avatar: string | null;
}

const UserProfile: React.FC<UserProfileProps> = ({ userId, mutualFriends = 0 }) => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [isServersOpen, setIsServersOpen] = useState(false);
  const [isFriendsOpen, setIsFriendsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfileSettings | null>(null);
  const { currentUser } = useAuth();
  const { profileSettings } = useProfile();
  const { acceptedFriends, loadAcceptedFriends } = useFriendRequests();
  const [mutualFriendsList, setMutualFriendsList] = useState<MutualFriend[]>([]);
  const [isBlocked, setIsBlocked] = useState(false);

  // Engelleme durumunu kontrol et
  useEffect(() => {
    const checkBlockStatus = async () => {
      try {
        const response = await API.get(`/api/friendship/check-block/${userId}`);
        setIsBlocked(response.data.isBlocked);
      } catch (error) {
        console.error('Engelleme durumu kontrol edilirken hata:', error);
      }
    };

    if (userId) {
      checkBlockStatus();
    }
  }, [userId]);

  // Arkadaşlık durumunu kontrol et
  const isFriend = acceptedFriends.some(friend => friend.id === Number(userId));

  // Arkadaşlık isteği gönderme
  const handleSendFriendRequest = async () => {
    if (!currentUser || !state?.username) return;
    
    try {
      // Engelleme durumunu kontrol et
      const isBlocked = await isUserBlocked(Number(userId));
      if (isBlocked) {
        toast.error('Bu kullanıcıya arkadaşlık isteği gönderemezsiniz çünkü engellendiniz');
        return;
      }

      const result = await sendFriendRequest(currentUser.username, state.username);
      if (result.success) {
        toast.success('Arkadaşlık isteği gönderildi');
      } else {
        toast.error(result.error || 'Bir hata oluştu');
      }
    } catch (error) {
      toast.error('Arkadaşlık isteği gönderilirken bir hata oluştu');
    }
  };

  // Arkadaşlıktan çıkarma
  const handleRemoveFriend = async () => {
    try {
      const result = await removeFriend(Number(userId));
      if (result.success) {
        toast.success('Arkadaşlıktan çıkarıldı');
      } else {
        toast.error(result.error || 'Bir hata oluştu');
      }
    } catch (error) {
      toast.error('Arkadaşlıktan çıkarma işlemi başarısız oldu');
    }
  };

  // Engelleme/engel kaldırma
  const handleBlockUser = async () => {
    try {
      if (isBlocked) {
        // Engeli kaldır
        await unblockUser(Number(userId));
        setIsBlocked(false);
        toast.success('Kullanıcının engeli kaldırıldı');
      } else {
        // Kullanıcıyı engelle
        await blockUser(Number(userId));
        setIsBlocked(true);
        toast.success('Kullanıcı engellendi');
      }
      // Arkadaş listesini güncelle
      await loadAcceptedFriends();
    } catch (error) {
      toast.error('İşlem başarısız oldu');
    }
  };

  // Online durumunu kontrol et
  const userOnlineStatus = acceptedFriends.find(friend => friend.id === Number(userId))?.online_status || 'offline';
  
  // Online durumuna göre renk belirle
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'online':
        return '#23a559';
      case 'idle':
        return '#f0b232';
      case 'dnd':
        return '#f23f43';
      default:
        return '#80848e';
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (currentUser && Number(userId) === Number(currentUser.id)) {
          console.log('Kendi profilim:', profileSettings);
          setUserProfile(profileSettings);
        } else {
          const response = await API.get(`/api/user/settings/${userId}`);
          const settings = response.data;
          
          // API_URL'yi tanımla
          const API_URL = window.location.hostname === 'localhost' 
            ? 'http://localhost:3001'
            : 'https://api.lazcord.aztmedya.net';
          
          // URL'leri düzelt
          if (settings.avatar) {
            settings.avatar = settings.avatar.startsWith('data:') 
              ? settings.avatar 
              : `${API_URL}${settings.avatar.startsWith('/') ? settings.avatar : `/${settings.avatar}`}`;
            console.log('Avatar URL:', settings.avatar);
          }
          
          if (settings.banner) {
            settings.banner = settings.banner.startsWith('data:') 
              ? settings.banner 
              : `${API_URL}${settings.banner.startsWith('/') ? settings.banner : `/${settings.banner}`}`;
            console.log('Banner URL:', settings.banner);
          }
          
          console.log('Başka kullanıcının profili:', settings);
          setUserProfile(settings);
        }
      } catch (error) {
        console.error('Profil bilgileri alınamadı:', error);
        // Hata durumunda varsayılan profil ayarlarını kullan
        setUserProfile({
          bannerColor: '#4752c4',
          aboutMe: ''
        });
      }
    };

    if (userId) {
      fetchUserProfile();
    }
  }, [userId, currentUser, profileSettings]);

  useEffect(() => {
    const fetchMutualFriends = async () => {
      try {
        const response = await API.get(`/api/user/settings/${userId}/mutual-friends`);
        // API_URL'yi tanımla
        const API_URL = window.location.hostname === 'localhost' 
          ? 'http://localhost:3001'
          : 'https://api.lazcord.aztmedya.net';

        // Avatar URL'lerini düzelt
        const friendsWithFixedAvatars = response.data.map((friend: any) => ({
          id: friend.id,
          username: friend.username,
          avatar: friend.avatar_url 
            ? friend.avatar_url.startsWith('data:')
              ? friend.avatar_url
              : `${API_URL}${friend.avatar_url.startsWith('/') ? friend.avatar_url : `/${friend.avatar_url}`}`
            : null
        }));
        
        setMutualFriendsList(friendsWithFixedAvatars);
      } catch (error) {
        console.error('Ortak arkadaşlar alınamadı:', error);
      }
    };

    if (userId && isFriendsOpen) {
      fetchMutualFriends();
    }
  }, [userId, isFriendsOpen]);

  const formatJoinDate = (date?: string) => {
    if (!date) return '';
    return format(new Date(date), "d MMMM yyyy", { locale: tr });
  };

  return (
    <div className="h-full bg-[#232428]">
      {/* Banner Alanı */}
      <div 
        className="h-[140px] bg-cover bg-center relative"
        style={{ 
          backgroundColor: userProfile?.bannerColor || '#4752c4',
          backgroundImage: userProfile?.banner ? `url('${userProfile.banner}')` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {/* Arkadaşlık Butonları */}
        {currentUser && Number(currentUser.id) !== Number(userId) && (
          <div className="absolute top-4 right-4 flex gap-2">
            {!isFriend ? (
              <div className="relative group">
                <button
                  onClick={handleSendFriendRequest}
                  className="bg-discord-blue hover:bg-discord-blue-dark text-white w-9 h-9 rounded-full flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                </button>
                <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 whitespace-nowrap pointer-events-none transition-opacity duration-200">
                  Arkadaş Ekle
                </div>
              </div>
            ) : (
              <div className="relative group">
                <button
                  onClick={handleRemoveFriend}
                  className="bg-discord-red hover:bg-discord-red-dark text-white w-9 h-9 rounded-full flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faUserMinus} />
                </button>
                <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 whitespace-nowrap pointer-events-none transition-opacity duration-200">
                  Arkadaşlıktan Çıkar
                </div>
              </div>
            )}
            <div className="relative group">
              <button
                onClick={handleBlockUser}
                className={`${isBlocked ? 'bg-discord-red' : 'bg-discord-gray'} hover:bg-discord-gray-dark text-white w-9 h-9 rounded-full flex items-center justify-center`}
              >
                <FontAwesomeIcon icon={faBan} />
              </button>
              <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 whitespace-nowrap pointer-events-none transition-opacity duration-200">
                {isBlocked ? 'Engeli Kaldır' : 'Engelle'}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Profil Bilgileri */}
      <div className="px-4">
        {/* Avatar */}
        <div className="relative -mt-[40px]">
          <div className="w-[92px] h-[92px] rounded-full bg-[#232428] p-[7px] relative">
            {userProfile?.avatar ? (
              <div className="w-full h-full rounded-full overflow-hidden">
                <img 
                  src={userProfile.avatar}
                  alt={state?.username || ''}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.error('Avatar yükleme hatası:', e);
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = '';
                  }}
                />
              </div>
            ) : (
              <div className="w-full h-full rounded-full overflow-hidden">
                <ColorAvatar 
                  userId={userId}
                  username={state?.username || ''}
                  size="xl"
                  className="w-full h-full"
                />
              </div>
            )}
            {/* Online Simgesi */}
            <div className="absolute bottom-1 right-1 w-[22px] h-[22px] rounded-full bg-[#232428] flex items-center justify-center">
              <div 
                className="w-[16px] h-[16px] rounded-full transition-colors duration-200"
                style={{ backgroundColor: getStatusColor(userOnlineStatus) }}
              />
            </div>
          </div>
        </div>

        {/* Kullanıcı Bilgileri */}
        <div className="bg-[#111214] mt-4 rounded-lg">
          <div className="p-3">
            <div className="bg-[#2b2d31] rounded-[4px] p-3">
              <h2 className="text-white font-semibold text-xl leading-6">
                {state?.username}
              </h2>
              <div className="text-[#b5bac1] text-base mt-1">
                {state?.username}#{userId.slice(0, 4)}
              </div>
            </div>
          </div>

          <div className="px-3 pb-3">
            <div className="h-[1px] bg-[#2b2d31] my-3"></div>

            <div className="text-[#b5bac1]">
              <h3 className="text-xs font-bold mb-1">ŞU TARİHTEN BERİ ÜYE</h3>
              <div className="text-sm">{formatJoinDate(state?.created_at)}</div>
            </div>

            <div className="h-[1px] bg-[#2b2d31] my-3"></div>

            {/* Ortak Sunucular */}
            <div>
              <button 
                onClick={() => setIsServersOpen(!isServersOpen)}
                className="w-full flex items-center justify-between text-[#b5bac1] hover:text-white cursor-pointer mb-2"
              >
                <h3 className="text-xs font-bold">ORTAK SUNUCULAR</h3>
                <FontAwesomeIcon 
                  icon={isServersOpen ? faChevronDown : faChevronRight} 
                  className="w-3 h-3"
                />
              </button>
              {isServersOpen && (
                <div className="text-[#b5bac1] text-sm pl-2">
                  Henüz ortak sunucu bulunmuyor.
                </div>
              )}
            </div>

            <div className="h-[1px] bg-[#2b2d31] my-3"></div>

            {/* Ortak Arkadaşlar */}
            <div>
              <button 
                onClick={() => setIsFriendsOpen(!isFriendsOpen)}
                className="w-full flex items-center justify-between text-[#b5bac1] hover:text-white cursor-pointer mb-2"
              >
                <h3 className="text-xs font-bold">
                  ORTAK ARKADAŞLAR{mutualFriends > 0 ? ` — ${mutualFriends}` : ''}
                </h3>
                <FontAwesomeIcon 
                  icon={isFriendsOpen ? faChevronDown : faChevronRight} 
                  className="w-3 h-3"
                />
              </button>
              {isFriendsOpen && (
                <div className="text-[#b5bac1] text-sm pl-2">
                  {mutualFriendsList.length > 0 ? (
                    <div className="space-y-2">
                      {mutualFriendsList.map((friend) => (
                        <div key={friend.id} className="flex items-center text-[#b5bac1] hover:text-white cursor-pointer">
                          {friend.avatar ? (
                            <img 
                              src={friend.avatar} 
                              alt={friend.username}
                              className="w-10 h-10 rounded-full mr-3"
                              onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.style.display = 'none';
                                const avatarContainer = e.currentTarget.parentElement;
                                if (avatarContainer) {
                                  const colorAvatar = document.createElement('div');
                                  colorAvatar.className = 'w-10 h-10 mr-3';
                                  avatarContainer.appendChild(colorAvatar);
                                  const root = ReactDOM.createRoot(colorAvatar);
                                  root.render(
                                    <ColorAvatar 
                                      userId={friend.id.toString()}
                                      username={friend.username}
                                      size="md"
                                    />
                                  );
                                }
                              }}
                            />
                          ) : (
                            <ColorAvatar 
                              userId={friend.id.toString()}
                              username={friend.username}
                              size="md"
                              className="mr-3"
                            />
                          )}
                          <div className="text-sm font-medium">
                            {friend.username}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-2">
                      Henüz ortak arkadaş bulunmuyor.
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="h-[1px] bg-[#2b2d31] my-3"></div>

            <div>
              <h3 className="text-[#b5bac1] text-xs font-bold mb-1">Hakkında</h3>
              <div className="text-[#949ba4] text-sm italic">
                {userProfile?.aboutMe || 'Henüz Hakkında Yazısı eklenmemiş.'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile; 