import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCamera } from '@fortawesome/free-solid-svg-icons';
import { createServer } from '../../services/serverService';
import { toast } from 'react-toastify';

interface CreateServerModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const CreateServerModal: React.FC<CreateServerModalProps> = ({ onClose, onSuccess }) => {
  const [serverName, setServerName] = useState('');
  const [serverIcon, setServerIcon] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setServerIcon(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!serverName.trim() || isLoading) return;

    try {
      setIsLoading(true);
      await createServer(serverName, serverIcon || undefined);
      toast.success('Sunucu başarıyla oluşturuldu!');
      onSuccess();
    } catch (error) {
      console.error('Error creating server:', error);
      toast.error('Sunucu oluşturulurken bir hata oluştu.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
      <div className="bg-discord-dark rounded-lg w-full max-w-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-white text-xl font-bold">Sunucunu Özelleştir</h2>
          <button 
            onClick={onClose}
            className="text-discord-light hover:text-white transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="w-5 h-5" />
          </button>
        </div>
        
        <p className="text-discord-light text-sm mb-6">
          Yeni sunucuna bir isim ve simge ekleyerek ona kişilik kat.
          Bunları istediğin zaman değiştirebilirsin.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="flex justify-center mb-6">
            <div className="relative">
              <div className="w-[100px] h-[100px] rounded-full bg-discord-dark-hover flex items-center justify-center overflow-hidden border-2 border-dashed border-discord-light cursor-pointer">
                {previewUrl ? (
                  <img 
                    src={previewUrl} 
                    alt="Server icon" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="text-discord-light">
                    <FontAwesomeIcon icon={faCamera} className="w-8 h-8" />
                  </div>
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleIconChange}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              />
              <div className="absolute bottom-0 right-0 w-8 h-8 bg-discord-blurple rounded-full flex items-center justify-center text-white cursor-pointer">
                <FontAwesomeIcon icon={faCamera} className="w-4 h-4" />
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-xs font-bold text-discord-light uppercase mb-2">
              SUNUCU ADI
            </label>
            <input
              type="text"
              value={serverName}
              onChange={(e) => setServerName(e.target.value)}
              className="w-full px-3 py-2 bg-[#1E1F22] text-[#DCDDDE] rounded focus:outline-none focus:ring-2 focus:ring-discord-blurple"
              placeholder="Sunucu Adı"
              disabled={isLoading}
            />
          </div>

          <div className="flex justify-between">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-white hover:underline"
              disabled={isLoading}
            >
              Geri
            </button>
            <button
              type="submit"
              disabled={!serverName.trim() || isLoading}
              className={`px-4 py-2 rounded ${
                serverName.trim() && !isLoading
                  ? 'bg-[#5865F2] hover:bg-[#4752C4] text-white'
                  : 'bg-[#5865F2]/50 cursor-not-allowed text-white/50'
              }`}
            >
              {isLoading ? 'Oluşturuluyor...' : 'Oluştur'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateServerModal; 