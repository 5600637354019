import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { Friend } from '../types/friend';
import { FriendRequest, getPendingRequests, getAcceptedFriends } from '../services/friendshipService';
import WebSocketClient from '../utils/WebSocketClient';
import { useAuth } from './AuthContext';
import { useWebSocket } from '../hooks/useWebSocket';
import { useSound } from '../hooks/useSound';

interface FriendRequestsContextType {
  incomingRequests: FriendRequest[];
  outgoingRequests: FriendRequest[];
  acceptedFriends: Friend[];
  loadPendingRequests: () => Promise<void>;
  loadAcceptedFriends: () => Promise<void>;
  updateFriendStatus: (userId: number, status: string, activity?: string) => void;
  wsClient: WebSocketClient;
}

const FriendRequestsContext = createContext<FriendRequestsContextType | undefined>(undefined);

// Presence Update tipi tanımı
interface PresenceUpdate {
  userId: number;
  status: string;
  activity?: string;
}

export const FriendRequestsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser } = useAuth();
  const [incomingRequests, setIncomingRequests] = useState<FriendRequest[]>([]);
  const [outgoingRequests, setOutgoingRequests] = useState<FriendRequest[]>([]);
  const [acceptedFriends, setAcceptedFriends] = useState<Friend[]>([]);
  const [wsClient] = useState(() => WebSocketClient.getInstance());
  const [isConnected, setIsConnected] = useState(false);
  const [presenceInitialized, setPresenceInitialized] = useState(false);
  const { playSound } = useSound();
  const [presences, setPresences] = useState<Record<number, boolean>>({});

  // Presence durumlarını global olarak tut
  const presenceCache = useRef<Map<number, { status: string; activity?: string }>>(new Map());

  const updateFriendStatus = useCallback((userId: number, status: string, activity?: string) => {
    console.log('Arkadaş durumu güncelleniyor:', { userId, status, activity });
    
    // Önce cache'i güncelle
    presenceCache.current.set(userId, { status, activity });
    
    // Sonra state'i güncelle
    setAcceptedFriends(prevFriends => 
      prevFriends.map(friend => {
        if (friend.id === userId) {
          // Status kontrolü
          const validStatus = ['online', 'offline', 'idle', 'dnd'].includes(status) 
            ? status as Friend['online_status']
            : 'offline';

          return { 
            ...friend, 
            online_status: validStatus,
            activity
          };
        }
        return friend;
      })
    );
  }, []);

  const loadPendingRequests = useCallback(async () => {
    try {
      const result = await getPendingRequests();
      if (result.success && result.data) {
        setIncomingRequests(result.data.incoming);
        setOutgoingRequests(result.data.outgoing);
      }
    } catch (error) {
      console.error('Bekleyen istekler yüklenirken hata:', error);
    }
  }, []);

  const loadAcceptedFriends = useCallback(async () => {
    try {
      console.log('Arkadaşlar yükleniyor...');
      const friends = await getAcceptedFriends();
      
      // Arkadaşları cache'deki presence durumlarıyla birleştir
      const friendsWithStatus = friends.map(friend => {
        const cachedPresence = presenceCache.current.get(friend.id);
        return {
          ...friend,
          online_status: (cachedPresence?.status || 'offline') as Friend['online_status'],
          activity: cachedPresence?.activity
        };
      });
      
      setAcceptedFriends(friendsWithStatus);
      
      // Eğer presence durumları henüz başlatılmamışsa, başlat
      if (!presenceInitialized && wsClient.isConnected()) {
        wsClient.send({
          type: 'GET_PRESENCE',
          data: {
            userIds: friends.map(friend => friend.id),
            priority: 'high',
            immediate: true
          }
        });
        setPresenceInitialized(true);
      }
    } catch (error) {
      console.error('Kabul edilen arkadaşlar yüklenirken hata:', error);
    }
  }, [wsClient, presenceInitialized]);

  // WebSocket bağlantısını yönet
  useEffect(() => {
    if (currentUser) {
      console.log('WebSocket bağlantısı başlatılıyor (currentUser var)');
      wsClient.initializeConnection();

      // friend_request event'ini dinle
      wsClient.on('friend_request', (request: any) => {
        console.log('Yeni arkadaşlık isteği alındı:', request);
        if (request && request.receiver_username === currentUser.username) {
          // State'i hemen güncelle
          setIncomingRequests(prev => [...prev, request]);
          // Arkadaşlık isteği sesi çal
          playSound('friendRequest');
        } else if (request && request.sender_username === currentUser.username) {
          // Gönderen için de state'i güncelle
          setOutgoingRequests(prev => [...prev, request]);
        }
      });

      // friend_request_cancelled event'ini dinle
      wsClient.on('friend_request_cancelled', (data: any) => {
        console.log('Arkadaşlık isteği iptal edildi:', data);
        const username = localStorage.getItem('username');
        
        // İsteği listeden kaldır
        if (username === data.senderId || username === data.receiverId) {
          setIncomingRequests(prev => prev.filter(req => req.id !== data.requestId));
          setOutgoingRequests(prev => prev.filter(req => req.id !== data.requestId));
          
          // Arkadaş listesini ve bekleyen istekleri yenile
          loadAcceptedFriends();
          loadPendingRequests();
        }
      });

      // friend_request_response event'ini dinle
      wsClient.on('friend_request_response', (data: any) => {
        console.log('Arkadaşlık isteği yanıtı alındı:', data);
        const username = localStorage.getItem('username');

        // İsteği listeden kaldır
        if (data.status === 'accepted' || data.status === 'rejected') {
          if (username === data.senderId || username === data.receiverId) {
            setIncomingRequests(prev => prev.filter(req => req.id !== data.requestId));
            setOutgoingRequests(prev => prev.filter(req => req.id !== data.requestId));
            
            // Eğer istek kabul edildiyse ve ben gönderen veya alıcıysam arkadaş listesini hemen yenile
            if (data.status === 'accepted') {
              console.log('Arkadaşlık isteği kabul edildi, arkadaş listesi yenileniyor...');
              // Arkadaş listesini yenile
              loadAcceptedFriends();
              // Presence durumlarını da yenile
              setPresenceInitialized(false);
              wsClient.send({
                type: 'GET_PRESENCE',
                data: {
                  userIds: acceptedFriends.map(friend => friend.id),
                  priority: 'high',
                  immediate: true
                }
              });
            }
          }
        }
      });

      // Engelleme event'lerini dinle
      wsClient.on('user_blocked', (data: any) => {
        console.log('Kullanıcı engellendi:', data);
        const currentUserId = currentUser?.id;
        
        // Engellenen veya engelleyen kişi bensem
        if (currentUserId === data.data.blockedId || currentUserId === data.data.blockerId) {
          // Arkadaş listesinden gizle
          setAcceptedFriends(prev => 
            prev.map(friend => {
              if (friend.id === data.data.blockedId || friend.id === data.data.blockerId) {
                return { ...friend, isHidden: true };
              }
              return friend;
            })
          );
          
          // Presence listesinden de gizle
          setPresences((prev: Record<number, boolean>) => {
            const newPresences = { ...prev };
            if (data.data.blockedId) delete newPresences[data.data.blockedId];
            if (data.data.blockerId) delete newPresences[data.data.blockerId];
            return newPresences;
          });
        }
      });

      wsClient.on('user_unblocked', (data: any) => {
        console.log('Kullanıcının engeli kaldırıldı:', data);
        // Engeli kaldırılan kullanıcıyı arkadaş listesinde göster
        setAcceptedFriends(prev => 
          prev.map(friend => {
            if (friend.id === data.data.blockedId || friend.id === data.data.blockerId) {
              return { ...friend, isHidden: false };
            }
            return friend;
          })
        );
      });

      // Bağlantı durumunu kontrol et
      const checkConnection = setInterval(() => {
        const connected = wsClient.isConnected();
        if (connected !== isConnected) {
          setIsConnected(connected);
          
          // Bağlantı yeniden kurulduğunda presence durumlarını güncelle
          if (connected) {
            console.log('Bağlantı kuruldu, arkadaşlar yükleniyor...');
            loadAcceptedFriends();
            loadPendingRequests();
            setPresenceInitialized(false);
          }
        }
      }, 3000);

      return () => {
        wsClient.off('friend_request');
        wsClient.off('friend_request_cancelled');
        wsClient.off('friend_request_response');
        wsClient.off('user_blocked');
        wsClient.off('user_unblocked');
        clearInterval(checkConnection);
      };
    }
  }, [currentUser, wsClient, isConnected, loadAcceptedFriends, loadPendingRequests, playSound]);

  // Presence yanıtlarını dinle
  useEffect(() => {
    if (wsClient) {
      const handlePresenceResponse = (data: { presences: { userId: number; status: string; activity?: string }[] }) => {
        console.log('Presence yanıtı alındı:', data);
        if (data.presences) {
          // Tüm presence güncellemelerini tek seferde yap
          const updates = new Map();
          data.presences.forEach(presence => {
            if (presence.userId && presence.status) {
              // Status kontrolü
              const validStatus = ['online', 'offline', 'idle', 'dnd'].includes(presence.status) 
                ? presence.status 
                : 'offline';

              updates.set(presence.userId, {
                status: validStatus,
                activity: presence.activity
              });
            }
          });

          // Cache'i güncelle
          updates.forEach((value, key) => {
            presenceCache.current.set(key, value);
          });

          // State'i hemen güncelle
          setAcceptedFriends(prevFriends => {
            const updatedFriends = [...prevFriends];
            let hasChanges = false;
            
            updates.forEach((value, userId) => {
              const friendIndex = updatedFriends.findIndex(f => f.id === userId);
              if (friendIndex !== -1) {
                hasChanges = true;
                updatedFriends[friendIndex] = {
                  ...updatedFriends[friendIndex],
                  online_status: value.status as Friend['online_status'],
                  activity: value.activity
                };
              }
            });
            
            return hasChanges ? updatedFriends : prevFriends;
          });
        }
      };

      wsClient.on('PRESENCE_RESPONSE', handlePresenceResponse);
      wsClient.on('PRESENCE_UPDATE', (data: PresenceUpdate) => {
        console.log('Presence güncelleme alındı:', data);
        if (data.userId && data.status) {
          const userId = typeof data.userId === 'string' ? parseInt(data.userId, 10) : data.userId;
          // Hemen güncelle
          updateFriendStatus(userId, data.status, data.activity);
        }
      });

      return () => {
        wsClient.off('PRESENCE_RESPONSE');
        wsClient.off('PRESENCE_UPDATE');
      };
    }
  }, [wsClient, updateFriendStatus]);

  // Arkadaş listesi değiştiğinde presence durumlarını güncelle
  useEffect(() => {
    if (wsClient.isConnected() && acceptedFriends.length > 0 && !presenceInitialized) {
      wsClient.send({
        type: 'GET_PRESENCE',
        data: {
          userIds: acceptedFriends.map(friend => friend.id),
          priority: 'high',
          immediate: true
        }
      });
      setPresenceInitialized(true);
    }
  }, [acceptedFriends, wsClient, presenceInitialized]);

  useEffect(() => {
    if (wsClient && currentUser) {
      wsClient.on('friend_request_cancelled', (data: { requestId: number }) => {
        // İsteği listeden kaldır
        setIncomingRequests(prev => prev.filter(req => req.id !== data.requestId));
        setOutgoingRequests(prev => prev.filter(req => req.id !== data.requestId));
      });

      wsClient.on('friend_request_accepted', (data: { requestId: number }) => {
        // İsteği listeden kaldır
        setIncomingRequests(prev => prev.filter(req => req.id !== data.requestId));
        setOutgoingRequests(prev => prev.filter(req => req.id !== data.requestId));
        // Arkadaş listesini güncelle
        loadAcceptedFriends();
      });

      wsClient.on('friend_request_rejected', (data: { requestId: number }) => {
        // İsteği listeden kaldır
        setIncomingRequests(prev => prev.filter(req => req.id !== data.requestId));
        setOutgoingRequests(prev => prev.filter(req => req.id !== data.requestId));
      });

      return () => {
        wsClient.off('friend_request_cancelled');
        wsClient.off('friend_request_accepted');
        wsClient.off('friend_request_rejected');
      };
    }
  }, [wsClient, currentUser, loadAcceptedFriends]);

  return (
    <FriendRequestsContext.Provider
      value={{
        incomingRequests,
        outgoingRequests,
        acceptedFriends,
        loadPendingRequests,
        loadAcceptedFriends,
        updateFriendStatus,
        wsClient
      }}
    >
      {children}
    </FriendRequestsContext.Provider>
  );
};

export const useFriendRequests = () => {
  const context = useContext(FriendRequestsContext);
  if (context === undefined) {
    throw new Error('useFriendRequests must be used within a FriendRequestsProvider');
  }
  return context;
}; 