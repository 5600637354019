import { API } from './api';

export interface Server {
    id: number;
    name: string;
    icon_url: string | null;
    owner_id: number;
    channels: Channel[];
    members: number[];
    created_at: string;
    updated_at: string;
}

export interface Channel {
    id: number;
    server_id: number;
    name: string;
    type: 'text' | 'voice';
}

export const createServer = async (name: string, icon?: File): Promise<Server> => {
    const formData = new FormData();
    formData.append('name', name);
    
    if (icon) {
        formData.append('icon', icon);
    }

    const response = await API.post('/api/servers', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
};

export const getServer = async (id: number): Promise<Server> => {
    const response = await API.get(`/api/servers/${id}`);
    return response.data;
};

export const getServers = async (): Promise<Server[]> => {
    const response = await API.get('/api/servers');
    return response.data;
};

export const leaveServer = async (serverId: number): Promise<void> => {
    await API.delete(`/api/servers/${serverId}/members`);
}; 