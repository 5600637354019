import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import FriendsPage from './pages/FriendsPage';
import DMChannel from './pages/DMChannel';
import ServerChatPage from './pages/ServerChatPage';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      
      {/* App Routes */}
      <Route path="/channels" element={<AppLayout />}>
        {/* Friends and DMs */}
        <Route path="@me" element={<FriendsPage />} />
        <Route path="@me/:friendId" element={<DMChannel />} />
        
        {/* Server Channels */}
        <Route path=":serverId/:channelId" element={<ServerChatPage />} />
      </Route>

      {/* Fallback */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes; 