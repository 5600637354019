import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { API } from '../services/api';
import { toast } from 'react-toastify';

const API_URL = window.location.hostname === 'localhost' 
  ? 'http://localhost:3001'
  : 'https://api.lazcord.aztmedya.net';

interface ProfileSettings {
  avatar?: string;
  banner?: string;
  bannerColor?: string;
  aboutMe?: string;
}

interface ProfileContextType {
  profileSettings: ProfileSettings | null;
  updateAvatar: (file: File) => Promise<void>;
  updateBanner: (file: File) => Promise<void>;
  updateBannerColor: (color: string) => Promise<void>;
  updateAboutMe: (text: string) => Promise<void>;
  loadProfileSettings: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profileSettings, setProfileSettings] = useState<ProfileSettings | null>(null);
  const { currentUser } = useAuth();

  const loadProfileSettings = async () => {
    if (!currentUser) return;

    try {
      const response = await API.get('/api/user/settings');
      const settings = response.data;
      
      // URL'leri kontrol et ve düzelt
      if (settings.avatar) {
        settings.avatar = settings.avatar.startsWith('data:') 
          ? settings.avatar 
          : `${API_URL}${settings.avatar.startsWith('/') ? settings.avatar : `/${settings.avatar}`}`;
        console.log('Avatar URL:', settings.avatar); // Debug için
      }
      
      if (settings.banner) {
        settings.banner = settings.banner.startsWith('data:') 
          ? settings.banner 
          : `${API_URL}${settings.banner.startsWith('/') ? settings.banner : `/${settings.banner}`}`;
        console.log('Banner URL:', settings.banner); // Debug için
      }
      
      console.log('Yüklenen profil ayarları:', settings); // Debug için
      setProfileSettings(settings);
    } catch (error) {
      console.error('Profil ayarları yüklenirken hata:', error);
      toast.error('Profil ayarları yüklenemedi');
    }
  };

  useEffect(() => {
    if (currentUser) {
      loadProfileSettings();
    } else {
      setProfileSettings(null);
    }
  }, [currentUser]);

  const updateAvatar = async (file: File) => {
    if (!currentUser) return;

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const response = await API.post('/api/user/settings/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      const avatar = response.data.avatar;
      const fullAvatarUrl = avatar.startsWith('data:') 
        ? avatar 
        : `${API_URL}${avatar.startsWith('/') ? avatar : `/${avatar}`}`;
      
      console.log('Yeni avatar URL:', fullAvatarUrl); // Debug için
      setProfileSettings(prev => ({ ...prev, avatar: fullAvatarUrl }));
      toast.success('Profil resmi güncellendi');
    } catch (error) {
      console.error('Profil resmi güncellenirken hata:', error);
      toast.error('Profil resmi güncellenemedi');
    }
  };

  const updateBanner = async (file: File) => {
    if (!currentUser) return;

    const formData = new FormData();
    formData.append('banner', file);

    try {
      const response = await API.post('/api/user/settings/banner', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      const banner = response.data.banner;
      const fullBannerUrl = banner.startsWith('data:') 
        ? banner 
        : `${API_URL}${banner.startsWith('/') ? banner : `/${banner}`}`;
      
      console.log('Yeni banner URL:', fullBannerUrl); // Debug için
      setProfileSettings(prev => ({ ...prev, banner: fullBannerUrl }));
      toast.success('Banner resmi güncellendi');
    } catch (error) {
      console.error('Banner resmi güncellenirken hata:', error);
      toast.error('Banner resmi güncellenemedi');
    }
  };

  const updateBannerColor = async (color: string) => {
    if (!currentUser) return;

    try {
      const response = await API.post('/api/user/settings/banner-color', { bannerColor: color });
      setProfileSettings(prev => ({ ...prev, bannerColor: response.data.bannerColor }));
      toast.success('Banner rengi güncellendi');
    } catch (error) {
      console.error('Banner rengi güncellenirken hata:', error);
      toast.error('Banner rengi güncellenemedi');
    }
  };

  const updateAboutMe = async (text: string) => {
    if (!currentUser) return;

    try {
      const response = await API.post('/api/user/settings/about-me', { aboutMe: text });
      setProfileSettings(prev => ({ ...prev, aboutMe: response.data.aboutMe }));
      toast.success('Hakkımda metni güncellendi');
    } catch (error) {
      console.error('Hakkımda metni güncellenirken hata:', error);
      toast.error('Hakkımda metni güncellenemedi');
    }
  };

  return (
    <ProfileContext.Provider value={{
      profileSettings,
      updateAvatar,
      updateBanner,
      updateBannerColor,
      updateAboutMe,
      loadProfileSettings
    }}>
      {children}
    </ProfileContext.Provider>
  );
}; 