import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import UserPanel from './UserPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faPlus, faHashtag, faVolumeHigh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { useFriendRequests } from '../../contexts/FriendRequestsContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { useDMUsers } from '../../hooks/useDMUsers';
import ColorAvatar from '../common/ColorAvatar';
import type { DMUser } from '../../hooks/useDMUsers';
import { API } from '../../services/api';
import { toast } from 'react-toastify';
import { getServer } from '../../services/serverService';
import type { Channel } from '../../services/serverService';

const getUserIdFromToken = (token: string): number | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload).id;
  } catch (error) {
    return null;
  }
};

const ChannelSidebar: React.FC = () => {
  const location = useLocation();
  const { serverId } = useParams();
  const { currentUser } = useAuth();
  const { incomingRequests } = useFriendRequests();
  const { dmUsers, isLoading, removeDMUser } = useDMUsers();
  const { dmNotifications } = useNotifications();
  const isDMPage = location.pathname.startsWith('/channels/@me');
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [serverChannels, setServerChannels] = useState<Channel[]>([]);
  const [serverName, setServerName] = useState<string>('');

  useEffect(() => {
    const loadServerData = async () => {
      if (serverId && !isDMPage) {
        try {
          console.log('Loading server data for ID:', serverId);
          const serverData = await getServer(parseInt(serverId));
          console.log('Server data loaded:', serverData);
          setServerChannels(serverData.channels || []);
          setServerName(serverData.name);
        } catch (error) {
          console.error('Sunucu bilgileri yüklenirken hata:', error);
          setServerChannels([]);
          setServerName('');
        }
      } else {
        setServerChannels([]);
        setServerName('');
      }
    };

    loadServerData();
  }, [serverId, isDMPage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Modal açıkken ve modal içine tıklandığında işlemi durdur
      if (isDeleteModalOpen) {
        const modalContent = document.querySelector('.modal-content');
        if (modalContent?.contains(event.target as Node)) {
          return;
        }
      }
      
      // Context menu dışına tıklandığında sadece context menu'yü kapat
      setContextMenuPosition(null);
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isDeleteModalOpen]);

  if (!currentUser?.token) return null;

  const userId = getUserIdFromToken(currentUser.token);
  if (!userId) return null;

  const handleContextMenu = (e: React.MouseEvent, userId: string) => {
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setSelectedUserId(userId);
  };

  const handleDeleteDM = async () => {
    if (!selectedUserId || !currentUser) return;
    
    // Context menu'yü kapat ama selectedUserId'yi tut
    setContextMenuPosition(null);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      if (!selectedUserId) {
        console.error('selectedUserId bulunamadı');
        return;
      }
      
      console.log('Silme işlemi başlatılıyor...', selectedUserId);
      await API.delete(`/api/messages/dm/${selectedUserId}`);
      console.log('API isteği başarılı');
      
      removeDMUser(selectedUserId as string);
      console.log('DM kullanıcısı listeden kaldırıldı');
      
      toast.success('Sohbet başarıyla silindi', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    } catch (error) {
      console.error('Sohbet silme hatası:', error);
      toast.error('Sohbet silinirken bir hata oluştu', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }

    setIsDeleteModalOpen(false);
    setContextMenuPosition(null);
    setSelectedUserId(null);
  };

  return (
    <div className="w-60 h-full bg-[#2B2D31] flex-shrink-0 flex flex-col">
      {!isDMPage ? (
        <>
          {/* Server Header */}
          <div className="h-12 px-4 border-b border-black/[0.24] flex items-center shadow-sm bg-[#2B2D31]">
            <h2 className="text-white font-medium truncate">{serverName}</h2>
          </div>

          {/* Server Channels */}
          <div className="flex-1 overflow-y-auto bg-[#2B2D31]">
            {/* Text Channels */}
            <div className="px-2">
              <div className="flex items-center justify-between px-2 py-1">
                <span className="text-discord-light uppercase text-xs font-semibold tracking-wide">METİN KANALLARI</span>
              </div>
              {serverChannels
                .filter(channel => channel.type === 'text')
                .map(channel => (
                  <Link
                    key={channel.id}
                    to={`/channels/${serverId}/${channel.id}`}
                    className={`flex items-center px-2 py-1.5 rounded-[4px] ${
                      location.pathname.includes(`/channels/${serverId}/${channel.id}`) ? 'bg-[#404249] text-white' : 'text-discord-light hover:bg-[#35363C] hover:text-white'
                    } cursor-pointer group transition-colors duration-200`}
                  >
                    <FontAwesomeIcon icon={faHashtag} className="w-4 h-4 mr-2 text-discord-light group-hover:text-white" />
                    <span className="font-medium">{channel.name}</span>
                  </Link>
                ))}
            </div>

            {/* Voice Channels */}
            <div className="px-2 mt-4">
              <div className="flex items-center justify-between px-2 py-1">
                <span className="text-discord-light uppercase text-xs font-semibold tracking-wide">SES KANALLARI</span>
              </div>
              {serverChannels
                .filter(channel => channel.type === 'voice')
                .map(channel => (
                  <Link
                    key={channel.id}
                    to={`/channels/${serverId}/${channel.id}`}
                    className={`flex items-center px-2 py-1.5 rounded-[4px] ${
                      location.pathname.includes(`/channels/${serverId}/${channel.id}`) ? 'bg-[#404249] text-white' : 'text-discord-light hover:bg-[#35363C] hover:text-white'
                    } cursor-pointer group transition-colors duration-200`}
                  >
                    <FontAwesomeIcon icon={faVolumeHigh} className="w-4 h-4 mr-2 text-discord-light group-hover:text-white" />
                    <span className="font-medium">{channel.name}</span>
                  </Link>
                ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-12 px-3 border-b border-black/[0.24] flex items-center shadow-sm bg-[#2B2D31]">
            <input
              type="text"
              placeholder="Arkadaş veya DM ara"
              className="w-full bg-[#1E1F22] text-sm text-discord-light px-2 py-1.5 rounded-[4px] focus:outline-none"
            />
          </div>

          <div className="flex-1 overflow-y-auto bg-[#2B2D31]">
            {/* Friends Section */}
            <div className="p-2">
              <Link
                to="/channels/@me"
                className={`flex items-center px-2 py-1.5 rounded-[4px] ${
                  location.pathname === '/channels/@me' ? 'bg-[#404249] text-white' : 'text-discord-light hover:bg-[#35363C] hover:text-white'
                } cursor-pointer mb-1 transition-colors duration-200`}
              >
                <FontAwesomeIcon icon={faUserFriends} className="w-5 h-5 mr-3" />
                <span className="font-medium">Arkadaşlar</span>
                {incomingRequests.length > 0 && (
                  <span className="ml-auto px-[6px] py-[2px] text-xs bg-discord-red text-white rounded-full">
                    {incomingRequests.length}
                  </span>
                )}
              </Link>
            </div>

            {/* Direct Messages */}
            <div className="px-2">
              <div className="flex items-center justify-between px-2 py-1">
                <span className="text-discord-light uppercase text-xs font-semibold tracking-wide">Özel Mesajlar</span>
                <button className="w-4 h-4 rounded-[4px] hover:bg-[#35363C] text-discord-light flex items-center justify-center group">
                  <FontAwesomeIcon icon={faPlus} className="w-4 h-4 transform transition-transform duration-200 group-hover:-translate-y-0.5" />
                </button>
              </div>
              {isLoading ? (
                <div className="flex items-center justify-center py-4">
                  <div className="w-5 h-5 border-2 border-discord-light/20 border-t-discord-light rounded-full animate-spin"></div>
                </div>
              ) : dmUsers.length === 0 ? (
                <div className="text-discord-light/50 text-sm text-center py-4">
                  Henüz mesajlaşma yok
                </div>
              ) : (
                dmUsers.map((user: DMUser) => {
                  const channelId = [userId, user.id].sort((a, b) => a - b).join('-');
                  const notificationCount = user.unreadCount || 0;

                  return (
                    <div key={user.id} onContextMenu={(e) => handleContextMenu(e, user.id.toString())}>
                      <Link
                        to={`/channels/@me/${user.id}`}
                        state={{ 
                          username: user.username,
                          created_at: user.created_at,
                          avatar_url: user.avatar
                        }}
                        className={`flex items-center px-2 py-1.5 rounded-[4px] ${
                          location.pathname === `/channels/@me/${user.id}` ? 'bg-[#404249] text-white' : 'text-discord-light hover:bg-[#35363C] hover:text-white'
                        } cursor-pointer group transition-colors duration-200`}
                      >
                        {user.avatar ? (
                          <div className="w-8 h-8 rounded-full overflow-hidden mr-3">
                            <img 
                              src={user.avatar}
                              alt={user.username}
                              className="w-full h-full object-cover"
                            />
                          </div>
                        ) : (
                          <ColorAvatar 
                            userId={user.id}
                            username={user.username}
                            size="sm"
                            className="mr-3"
                          />
                        )}
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center justify-between">
                            <div className="font-medium truncate">{user.username}</div>
                            {notificationCount > 0 && (
                              <div className="bg-red-500 text-white text-xs px-1.5 py-0.5 rounded-full ml-2 min-w-[18px] h-[18px] flex items-center justify-center font-medium">
                                {notificationCount}
                              </div>
                            )}
                          </div>
                          {user.lastMessage && (
                            <div className="text-xs text-discord-light truncate">{user.lastMessage}</div>
                          )}
                        </div>
                      </Link>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </>
      )}

      <UserPanel />

      {/* Context Menu */}
      {contextMenuPosition && (
        <div 
          className="fixed bg-[#18191c] border border-[#2f3136] rounded-md shadow-lg py-2 z-50"
          style={{ 
            top: contextMenuPosition.y, 
            left: contextMenuPosition.x,
            minWidth: '150px'
          }}
        >
          <button
            onClick={handleDeleteDM}
            className="w-full px-3 py-2 text-left text-[#dcddde] hover:bg-[#4752c4] hover:text-white flex items-center gap-2"
          >
            <FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
            <span>Sohbeti Sil</span>
          </button>
        </div>
      )}

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div 
            className="fixed inset-0 bg-black/50" 
            onClick={() => setIsDeleteModalOpen(false)}
          />
          <div className="bg-[#36393f] p-6 rounded-lg shadow-xl max-w-md w-full relative z-10">
            <h2 className="text-xl font-semibold text-white mb-4">DM Sohbetini Sil</h2>
            <p className="text-discord-light mb-6">Bu sohbeti silmek istediğinize emin misiniz?</p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="px-4 py-2 rounded text-white hover:underline"
              >
                Vazgeç
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-discord-red hover:bg-discord-red-dark rounded text-white"
              >
                Sil
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelSidebar; 