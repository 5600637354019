import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCompass, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useFriendRequests } from '../../contexts/FriendRequestsContext';
import { useDMUsers } from '../../hooks/useDMUsers';
import CreateServerModal from '../modals/CreateServerModal';
import { Server, getServers, leaveServer } from '../../services/serverService';
import { toast } from 'react-toastify';
import { useWebSocket } from '../../contexts/WebSocketContext';

const ServerList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dmUsers } = useDMUsers();
  const { incomingRequests } = useFriendRequests();
  const { serverId } = useParams();
  const { socket } = useWebSocket();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [servers, setServers] = useState<Server[]>([]);
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    serverId: number;
  } | null>(null);
  
  // Toplam DM bildirim sayısını hesapla
  const totalDMNotifications = dmUsers.reduce((total, user) => total + (user.unreadCount || 0), 0);
  
  // Toplam bildirim sayısı (DM + arkadaş istekleri)
  const totalNotifications = totalDMNotifications + incomingRequests.length;

  const loadServers = useCallback(async () => {
    try {
      console.log('🔄 loadServers fonksiyonu çağrıldı');
      console.log('📡 Sunucu listesi API isteği yapılıyor...');
      const serverList = await getServers();
      console.log('📥 API yanıtı alındı:', serverList);
      
      setServers(prevServers => {
        console.log('🔄 Önceki sunucu listesi:', prevServers);
        console.log('🔄 Yeni sunucu listesi:', serverList);
        return serverList;
      });
    } catch (error) {
      console.error('❌ Sunucular yüklenirken hata:', error);
      toast.error('Sunucu listesi yüklenirken bir hata oluştu');
    }
  }, []);

  const handleMessage = useCallback((event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      console.log('📨 WebSocket mesajı alındı:', data);

      if (data.type === 'SERVER_JOIN') {
        console.log('🎮 SERVER_JOIN mesajı işleniyor:', data);
        
        // Sadece sunucu listesini güncelle
        loadServers()
          .then(() => {
            toast.success(data.data?.message || 'Sunucuya başarıyla katıldınız!');
          })
          .catch((error) => {
            console.error('❌ Sunucu listesi güncellenirken hata:', error);
            toast.error('Sunucu listesi güncellenirken bir hata oluştu');
          });
      }

      if (data.type === 'REFRESH_SERVERS') {
        console.log('🔄 REFRESH_SERVERS mesajı alındı');
        loadServers();
      }
    } catch (error) {
      console.error('❌ WebSocket mesajı işlenirken hata:', error);
    }
  }, [loadServers]);

  // WebSocket bağlantısını izle
  useEffect(() => {
    if (!socket) {
      console.log('❌ WebSocket bağlantısı yok, bekleniyor...');
      return;
    }

    // Bağlantı durumunu kontrol et
    const checkConnection = () => {
      if (socket.readyState !== WebSocket.OPEN) {
        console.log('⚠️ WebSocket bağlantısı koptu, yeniden bağlanılıyor...');
        loadServers(); // Bağlantı koptuğunda listeyi güncelle
      }
    };

    // Her 5 saniyede bir bağlantıyı kontrol et
    const intervalId = setInterval(checkConnection, 5000);

    socket.addEventListener('message', handleMessage);

    return () => {
      clearInterval(intervalId);
      socket.removeEventListener('message', handleMessage);
    };
  }, [socket, handleMessage]);

  // İlk yükleme ve route değişikliklerinde sunucu listesini güncelle
  useEffect(() => {
    console.log('🚀 ServerList bileşeni yüklendi veya route değişti');
    loadServers();
  }, [loadServers]);

  const handleServerCreated = () => {
    setShowCreateModal(false);
    loadServers();
  };

  const handleContextMenu = (e: React.MouseEvent, serverId: number) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      serverId
    });
  };

  const handleLeaveServer = async () => {
    if (!contextMenu) return;

    try {
      await leaveServer(contextMenu.serverId);
      toast.success('Sunucudan ayrıldınız');
      loadServers();
      navigate('/channels/@me');
    } catch (error) {
      toast.error('Sunucudan ayrılırken bir hata oluştu');
    }
    setContextMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = () => setContextMenu(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <>
      <div className="w-[72px] bg-[#1E1F22] flex-shrink-0 flex flex-col items-center py-3 space-y-2 relative z-50">
        {/* Home Button */}
        <Link
          to="/channels/@me"
          className={`group relative w-12 h-12 ${
            location.pathname.includes('@me') ? 'rounded-2xl bg-discord-blue' : 'rounded-3xl hover:rounded-2xl bg-[#313338]'
          } flex items-center justify-center transition-all duration-200`}
        >
          {/* Indicator */}
          <div className={`absolute -left-3 w-1 h-10 bg-white rounded-r transition-all transform origin-left ${
            location.pathname.includes('@me') ? 'scale-100' : 'scale-0 group-hover:scale-100'
          }`} />
          
          {/* Logo */}
          <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5 w-full h-full p-2">
            <img src="/lazcord.png" alt="LazCord" className="w-full h-full object-contain" />
          </div>

          {/* Bildirim Sayısı */}
          {totalNotifications > 0 && (
            <div className="absolute -bottom-1 -right-1 min-w-[20px] h-5 bg-discord-red text-white text-[13px] font-medium rounded-full flex items-center justify-center px-1.5 border-[2.5px] border-[#1E1F22]">
              {totalNotifications}
            </div>
          )}

          {/* Tooltip */}
          <div className="absolute left-full ml-2 px-2 py-1 bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
            <span className="text-sm font-medium text-white whitespace-nowrap">Ana Sayfa</span>
          </div>
        </Link>

        {/* Separator */}
        <div className="w-8 h-0.5 bg-[#35363C] rounded-full mx-auto" />

        {/* Server List */}
        {servers.map(server => (
          <div key={server.id} onContextMenu={(e) => handleContextMenu(e, server.id)}>
            <Link
              to={`/channels/${server.id}/general`}
              className={`group relative w-12 h-12 ${
                location.pathname.includes(`/channels/${server.id}`) ? 'rounded-2xl bg-discord-blue' : 'rounded-3xl hover:rounded-2xl bg-[#313338]'
              } flex items-center justify-center transition-all duration-200`}
            >
              {/* Indicator */}
              <div className={`absolute -left-3 w-1 h-10 bg-white rounded-r transition-all transform origin-left ${
                location.pathname.includes(`/channels/${server.id}`) ? 'scale-100' : 'scale-0 group-hover:scale-100'
              }`} />
              
              {/* Server Icon */}
              <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
                {server.icon_url ? (
                  <img 
                    src={server.icon_url} 
                    alt={server.name} 
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <span className="text-2xl text-discord-light group-hover:text-white">
                    {server.name.charAt(0)}
                  </span>
                )}
              </div>

              {/* Tooltip */}
              <div className="absolute left-full ml-2 px-2 py-1 bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                <span className="text-sm font-medium text-white whitespace-nowrap">{server.name}</span>
              </div>
            </Link>
          </div>
        ))}

        {/* Add Server Button */}
        <button
          onClick={() => setShowCreateModal(true)}
          className="w-12 h-12 rounded-full bg-[#313338] hover:bg-discord-green hover:rounded-2xl flex items-center justify-center transition-all duration-200 group relative"
        >
          <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
            <FontAwesomeIcon icon={faPlus} className="w-6 h-6 text-discord-green group-hover:text-white transition-colors" />
          </div>
          {/* Tooltip */}
          <div className="absolute left-full ml-2 px-2 py-1 bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
            <span className="text-sm font-medium text-white whitespace-nowrap">Sunucu Ekle</span>
          </div>
        </button>

        {/* Explore Servers Button */}
        <button className="w-12 h-12 rounded-full bg-[#313338] hover:bg-discord-green hover:rounded-2xl flex items-center justify-center transition-all duration-200 group relative">
          <div className="transform transition-all duration-200 ease-out group-hover:-translate-y-0.5">
            <FontAwesomeIcon icon={faCompass} className="w-6 h-6 text-discord-green group-hover:text-white transition-colors" />
          </div>
          {/* Tooltip */}
          <div className="absolute left-full ml-2 px-2 py-1 bg-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
            <span className="text-sm font-medium text-white whitespace-nowrap">Sunucu Keşfet</span>
          </div>
        </button>
      </div>

      {/* Context Menu */}
      {contextMenu && (
        <div
          className="fixed bg-[#18191c] border border-[#2f3136] rounded-md shadow-lg py-2 z-50"
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          <button
            onClick={handleLeaveServer}
            className="w-full px-3 py-2 text-left text-[#dcddde] hover:bg-[#4752c4] hover:text-white flex items-center gap-2"
          >
            <FontAwesomeIcon icon={faRightFromBracket} className="w-4 h-4" />
            <span>Sunucudan Ayrıl</span>
          </button>
        </div>
      )}

      {showCreateModal && (
        <CreateServerModal
          onClose={() => setShowCreateModal(false)}
          onSuccess={handleServerCreated}
        />
      )}
    </>
  );
};

export default ServerList; 