import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faGear, faVolumeUp, faXmark, faSignOutAlt, faPen } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { updateUsername, updatePassword } from '../../services/api';
import { toast } from 'react-toastify';
import { useProfile } from '../../contexts/ProfileContext';
import ColorAvatar from '../common/ColorAvatar';

interface SettingsModalProps {
  onClose: () => void;
}

type TabId = 'account' | 'profile' | 'audio';

interface Tab {
  id: TabId;
  label: string;
  icon: any; // FontAwesome icon tipini daha spesifik yapabiliriz ama şimdilik any kullanıyoruz
}

const SettingsModal = ({ onClose }: SettingsModalProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabId>('account');
  const { logout, currentUser, setCurrentUser } = useAuth();
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [newUsername, setNewUsername] = useState(currentUser?.username || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [focusedInput, setFocusedInput] = useState<string>('');
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const { profileSettings, updateBanner, updateBannerColor, updateAvatar, updateAboutMe } = useProfile();
  const [aboutMeText, setAboutMeText] = useState(profileSettings?.aboutMe || '');

  console.log('Profile Settings:', profileSettings); // Debug için eklendi

  const tabs: Tab[] = [
    { id: 'account', label: 'Hesabım', icon: faUser },
    { id: 'profile', label: 'Profil', icon: faGear },
    { id: 'audio', label: 'Ses & Görüntü', icon: faVolumeUp },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      onClose();
    } catch (error) {
      console.error('Çıkış yapılırken hata oluştu:', error);
    }
  };

  const handleUsernameSubmit = async () => {
    if (!newUsername.trim()) {
      toast.error('Kullanıcı adı boş olamaz');
      return;
    }

    if (newUsername === currentUser?.username) {
      setIsEditingUsername(false);
      return;
    }

    try {
      const response = await updateUsername(newUsername);
      toast.success('Kullanıcı adı başarıyla güncellendi');
      setIsEditingUsername(false);
      
      // AuthContext'i güncelle
      if (currentUser) {
        const updatedUser = {
          ...currentUser,
          username: response.user.username
        };
        localStorage.setItem('username', response.user.username);
        setCurrentUser(updatedUser);
      }
    } catch (error: any) {
      console.error('Kullanıcı adı güncelleme hatası:', error);
      toast.error(error.response?.data?.error || 'Kullanıcı adı güncellenirken bir hata oluştu');
    }
  };

  const handlePasswordSubmit = async () => {
    // Şifre kontrolü
    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error('Tüm alanları doldurunuz');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('Yeni şifreler eşleşmiyor');
      return;
    }

    if (newPassword.length < 6) {
      toast.error('Yeni şifre en az 6 karakter olmalıdır');
      return;
    }

    try {
      await updatePassword(currentPassword, newPassword);
      toast.success('Şifre başarıyla güncellendi');
      
      // Formu temizle
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setIsEditingPassword(false);
    } catch (error: any) {
      console.error('Şifre güncelleme hatası:', error);
      toast.error(error.response?.data?.error || 'Şifre güncellenirken bir hata oluştu');
    }
  };

  const handlePasswordEditClick = () => {
    setIsEditingPassword(true);
    setFocusedInput('current-password');
  };

  const AccountContent = () => (
    <div className="text-[#B5BAC1] max-w-[660px]" onClick={(e) => e.stopPropagation()}>
      <h3 className="text-white text-xl font-semibold mb-4">Kullanıcı Bilgileri</h3>
      
      {/* Profil Kartı */}
      <div className="bg-[#1E1F22] rounded-lg p-4 mb-6">
        <div className="flex items-start">
          {profileSettings?.avatar ? (
            <div className="w-20 h-20 rounded-full overflow-hidden mr-4">
              <img 
                src={profileSettings.avatar}
                alt={currentUser?.username || 'Profil'}
                className="w-full h-full object-cover"
              />
            </div>
          ) : currentUser?.username && currentUser?.id ? (
            <ColorAvatar 
              userId={currentUser.id}
              username={currentUser.username}
              size="lg"
              className="mr-4"
            />
          ) : (
          <div className="w-20 h-20 bg-discord-dark rounded-full flex items-center justify-center mr-4">
              <span className="text-white text-3xl">?</span>
          </div>
          )}
          <div className="flex-1">
            <h4 className="text-white text-lg font-medium">{currentUser?.username}</h4>
            <p className="text-sm"># {currentUser?.id}</p>
          </div>
        </div>
      </div>

      {/* Kullanıcı Adı Değiştirme */}
      <div className="bg-[#1E1F22] rounded-lg p-4 mb-4">
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-xs font-semibold uppercase text-[#B5BAC1]">Kullanıcı Adı</h4>
          <button 
            onClick={(e) => {
              e.preventDefault();
              setIsEditingUsername(!isEditingUsername);
            }}
            className="text-[#B5BAC1] hover:text-white"
          >
            <FontAwesomeIcon icon={faPen} className="w-3 h-3" />
          </button>
        </div>
        {isEditingUsername ? (
          <form 
            onSubmit={(e) => {
            e.preventDefault();
            handleUsernameSubmit();
            }}
            onClick={(e) => e.stopPropagation()}
          >
          <div className="space-y-2">
            <div>
                <label htmlFor="username-input" className="block text-xs font-semibold uppercase text-[#B5BAC1] mb-2">
                  Yeni Kullanıcı Adı
                </label>
              <input
                id="username-input"
                name="username"
                type="text"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                className="w-full bg-[#1E1F22] border-2 border-[#3F4147] rounded px-3 py-2 text-white focus:border-[#5865F2] focus:outline-none"
                placeholder="Yeni kullanıcı adı"
                autoComplete="username"
				  autoFocus
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditingUsername(false);
                  }}
                className="px-4 py-0.5 text-sm text-white hover:underline"
              >
                İptal
              </button>
              <button
                type="submit"
                className="px-4 py-0.5 text-sm bg-[#5865F2] text-white rounded hover:bg-[#4752C4]"
              >
                Kaydet
              </button>
            </div>
          </div>
          </form>
        ) : (
          <p className="text-white">{currentUser?.username}</p>
        )}
      </div>

      {/* Email */}
      <div className="bg-[#1E1F22] rounded-lg p-4 mb-4">
        <h4 className="text-xs font-semibold uppercase text-[#B5BAC1] block mb-2">Email</h4>
        <p className="text-white">{currentUser?.email}</p>
      </div>

      {/* Şifre Değiştirme */}
      <div className="bg-[#1E1F22] rounded-lg p-4">
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-xs font-semibold uppercase text-[#B5BAC1]">ŞİFRE VE KİMLİK DOĞRULAMA</h4>
          <button 
            onClick={(e) => {
              e.preventDefault();
              handlePasswordEditClick();
            }}
            className="text-[#B5BAC1] hover:text-white"
          >
            <FontAwesomeIcon icon={faPen} className="w-3 h-3" />
          </button>
        </div>
        {isEditingPassword ? (
          <form 
            onSubmit={(e) => {
            e.preventDefault();
            handlePasswordSubmit();
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="space-y-2">
              <div>
                <label htmlFor="current-password-input" className="block text-xs font-semibold uppercase text-[#B5BAC1] mb-2">
                  Mevcut Şifre
                </label>
                <input
                  id="current-password-input"
                  name="current-password"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => {
                    e.stopPropagation();
                    setCurrentPassword(e.target.value);
                  }}
                  onFocus={() => setFocusedInput('current-password')}
                  onClick={(e) => e.stopPropagation()}
                  className="w-full bg-[#1E1F22] border-2 border-[#3F4147] rounded px-3 py-2 text-white focus:border-[#5865F2] focus:outline-none"
                  placeholder="Mevcut şifre"
                  autoComplete="current-password"
                  autoFocus={focusedInput === 'current-password'}
                />
              </div>
              <div>
                <label htmlFor="new-password-input" className="block text-xs font-semibold uppercase text-[#B5BAC1] mb-2">
                  Yeni Şifre
                </label>
                <input
                  id="new-password-input"
                  name="new-password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => {
                    e.stopPropagation();
                    setNewPassword(e.target.value);
                  }}
                  onFocus={() => setFocusedInput('new-password')}
                  onClick={(e) => e.stopPropagation()}
                  className="w-full bg-[#1E1F22] border-2 border-[#3F4147] rounded px-3 py-2 text-white focus:border-[#5865F2] focus:outline-none"
                  placeholder="Yeni şifre"
                  autoComplete="new-password"
                  autoFocus={focusedInput === 'new-password'}
                />
              </div>
              <div>
                <label htmlFor="confirm-password-input" className="block text-xs font-semibold uppercase text-[#B5BAC1] mb-2">
                  Yeni Şifreyi Onayla
                </label>
                <input
                  id="confirm-password-input"
                  name="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    e.stopPropagation();
                    setConfirmPassword(e.target.value);
                  }}
                  onFocus={() => setFocusedInput('confirm-password')}
                  onClick={(e) => e.stopPropagation()}
                  className="w-full bg-[#1E1F22] border-2 border-[#3F4147] rounded px-3 py-2 text-white focus:border-[#5865F2] focus:outline-none"
                  placeholder="Yeni şifreyi onayla"
                  autoComplete="new-password"
                  autoFocus={focusedInput === 'confirm-password'}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsEditingPassword(false);
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                  }}
                  className="px-4 py-0.5 text-sm text-white hover:underline"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  onClick={(e) => e.stopPropagation()}
                  className="px-4 py-0.5 text-sm bg-[#5865F2] text-white rounded hover:bg-[#4752C4]"
                  disabled={!currentPassword || !newPassword || !confirmPassword}
                >
                  Kaydet
                </button>
              </div>
            </div>
          </form>
        ) : (
          <p className="text-[#B5BAC1] text-sm">
            Şifrenizi değiştirmek için düzenleme simgesine tıklayın
          </p>
        )}
      </div>
    </div>
  );

  return (
    <div 
      className="fixed inset-0 bg-black/80 z-50 flex"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div 
        className="flex w-full h-full m-auto bg-[#313338] rounded-md overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Sol Sidebar */}
        <div className="w-[232px] bg-[#2B2D31] p-4">
          <div className="space-y-[2px] flex flex-col h-full">
            <div className="flex-1">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id as TabId)}
                  className={`w-full text-left px-2.5 py-1.5 rounded flex items-center space-x-3 transition-colors ${
                    activeTab === tab.id
                      ? 'bg-[#404249] text-white'
                      : 'text-[#B5BAC1] hover:text-white hover:bg-[#35373C]'
                  }`}
                >
                  <FontAwesomeIcon icon={tab.icon} className="w-4 h-4" />
                  <span className="text-sm">{tab.label}</span>
                </button>
              ))}
            </div>
            <div className="pt-2 border-t border-[#1F2023]">
              <button
                onClick={handleLogout}
                className="w-full text-left px-2.5 py-1.5 rounded flex items-center space-x-3 text-[#B5BAC1] hover:text-[#ED4245] hover:bg-[#35373C] transition-colors"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="w-4 h-4" />
                <span className="text-sm">Çıkış Yap</span>
              </button>
            </div>
          </div>
        </div>

        {/* Sağ İçerik */}
        <div className="flex-1 bg-[#313338] relative">
          {/* Başlık */}
          <div className="h-[48px] border-b border-black/[0.3] px-4 flex items-center">
            <h2 className="text-white font-medium">
              {tabs.find(tab => tab.id === activeTab)?.label}
            </h2>
            <button
              onClick={onClose}
              className="w-[36px] h-[36px] rounded-full bg-transparent hover:bg-white/[0.1] absolute right-4 flex items-center justify-center text-[#B5BAC1] hover:text-white transition-colors"
            >
              <FontAwesomeIcon icon={faXmark} className="w-4 h-4" />
            </button>
          </div>

          {/* İçerik Alanı */}
          <div className="p-4">
            {activeTab === 'account' && (
              <div className="text-[#B5BAC1] max-w-[660px]">
                <AccountContent />
              </div>
            )}
            {activeTab === 'profile' && (
              <div className="text-[#B5BAC1] max-w-[660px]">
                <h3 className="text-white text-xl font-semibold mb-4">Profil Ayarları</h3>
                
                {/* Banner Bölümü */}
                <div className="bg-[#1E1F22] rounded-lg p-4 mb-4">
                  <h4 className="text-xs font-semibold uppercase text-[#B5BAC1] mb-2">Banner</h4>
                  <div className="relative">
                    <div 
                      className="w-full h-[180px] rounded-lg overflow-hidden cursor-pointer group relative"
                      style={{
                        background: profileSettings?.banner 
                          ? `url('${profileSettings.banner}') center/cover no-repeat`
                          : profileSettings?.bannerColor || '#2B2D31',
                        backgroundColor: profileSettings?.bannerColor || '#2B2D31'
                      }}
                      onClick={() => bannerInputRef.current?.click()}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            updateBanner(file);
                          }
                        }}
                        ref={bannerInputRef}
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black/30 group-hover:opacity-0 transition-opacity">
                        <span className="text-white">
                          {profileSettings?.banner ? 'Banner resmini değiştir' : 'Banner resmi yükle'}
                        </span>
                      </div>
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <span className="text-white">Resim yükle</span>
                      </div>
                    </div>
                  </div>

                  {/* Banner Renk Seçici */}
                  <div className="mt-4">
                    <h4 className="text-xs font-semibold uppercase text-[#B5BAC1] mb-2">Banner Rengi</h4>
                    <div className="flex flex-wrap gap-2">
                      {['#5865F2', '#57F287', '#FEE75C', '#EB459E', '#ED4245'].map((color) => (
                        <button
                          key={color}
                          className="w-8 h-8 rounded-full focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#313338]"
                          style={{ backgroundColor: color }}
                          onClick={() => updateBannerColor(color)}
                        />
                      ))}
                      <div className="relative">
                        <button className="w-8 h-8 rounded-full bg-[#2B2D31] border-2 border-[#B5BAC1] hover:border-white flex items-center justify-center group">
                          <div className="w-4 h-4 rounded-full overflow-hidden relative">
                            <div className="absolute inset-0 bg-gradient-to-br from-red-500 via-green-500 to-blue-500"></div>
                          </div>
                        </button>
                        <input
                          type="color"
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          aria-label="Özel renk seç"
                          onChange={(e) => updateBannerColor(e.target.value)}
                        />
                      </div>
                    </div>
                    <p className="mt-2 text-xs text-[#B5BAC1]">
                      Özel bir renk seçmek için son butonu kullanabilirsiniz
                    </p>
                  </div>
                </div>

                {/* Profil Resmi Bölümü */}
                <div className="bg-[#1E1F22] rounded-lg p-4 mb-4">
                  <h4 className="text-xs font-semibold uppercase text-[#B5BAC1] mb-2">Profil Resmi</h4>
                  <div className="flex items-center">
                    <div className="relative group">
                      <div 
                        className="w-[100px] h-[100px] rounded-full overflow-hidden cursor-pointer relative group"
                        onClick={() => avatarInputRef.current?.click()}
                      >
                        <div className="absolute inset-0">
                          {profileSettings?.avatar ? (
                            <img 
                              src={profileSettings.avatar}
                              alt="Profil resmi"
                              className="absolute inset-0 w-full h-full object-cover"
                            />
                          ) : currentUser?.username && currentUser?.id ? (
                            <ColorAvatar 
                              userId={currentUser.id}
                              username={currentUser.username}
                              size="xl"
                            />
                          ) : (
                            <div className="absolute inset-0 bg-[#2B2D31] flex items-center justify-center">
                              <span className="text-white text-4xl">?</span>
                            </div>
                          )}
                        </div>
                        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                          <span className="text-white text-sm">
                            {profileSettings?.avatar ? 'Değiştir' : 'Resim yükle'}
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            updateAvatar(file);
                          }
                        }}
                        ref={avatarInputRef}
                      />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm text-[#B5BAC1]">
                        En az 128x128 piksel boyutunda bir resim yükleyin.<br />
                        PNG veya JPG formatı desteklenir.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Hakkımda Bölümü */}
                <div className="bg-[#1E1F22] rounded-lg p-4">
                  <h4 className="text-xs font-semibold uppercase text-[#B5BAC1] mb-2">Hakkımda</h4>
                  <textarea
                    className="w-full h-24 bg-[#2B2D31] border-2 border-[#1E1F22] rounded px-3 py-2 text-white focus:border-[#5865F2] focus:outline-none resize-none"
                    placeholder="Kendinizden bahsedin..."
                    value={aboutMeText}
                    onChange={(e) => setAboutMeText(e.target.value)}
                  />
                  <div className="flex justify-end mt-3">
                    <button
                      onClick={() => {
                        updateAboutMe(aboutMeText);
                      }}
                      className="px-4 py-2 bg-[#5865F2] hover:bg-[#4752C4] text-white rounded-md text-sm font-medium transition-colors"
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'audio' && (
              <div className="text-white">
                Ses & Görüntü ayarları içeriği burada olacak
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal; 