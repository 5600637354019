import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const HomePage: React.FC = () => {
  const { currentUser } = useAuth();

  return (
    <div className="min-h-screen bg-discord-dark flex flex-col">
      {/* Header */}
      <header className="bg-discord-darker">
        <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="text-white font-bold text-xl">LazCord</div>
          <div className="space-x-4">
            <Link
              to="/login"
              className="text-white hover:text-discord-light transition-colors"
            >
              Giriş Yap
            </Link>
            <Link
              to="/register"
              className="bg-discord-blue text-white px-4 py-2 rounded hover:bg-opacity-80 transition-opacity"
            >
              Kayıt Ol
            </Link>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <main className="flex-1 container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-5xl font-bold text-white mb-6">
            Arkadaşlarınla iletişimde kal
          </h1>
          <p className="text-discord-light text-lg mb-8">
            LazCord ile arkadaşlarınla sohbet et, oyun oyna ve eğlenceli vakit geçir.
          </p>
          <div className="space-x-4">
            <Link
              to="/register"
              className="inline-block bg-discord-blue text-white px-8 py-4 rounded-full font-medium hover:bg-opacity-80 transition-opacity"
            >
              Hemen Başla
            </Link>
            <a 
              href="https://lazcord.aztmedya.net/login"
              className="inline-block bg-discord-dark text-white px-8 py-4 rounded-full font-medium hover:bg-opacity-80 transition-opacity border border-discord-light"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://lazcord.aztmedya.net/login';
              }}
            >
              Uygulamayı Aç
            </a>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-discord-darker py-8">
        <div className="container mx-auto px-4 text-center text-discord-light">
          <p>&copy; 2024 LazCord. Tüm hakları saklıdır.</p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage; 