import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ServerList from './ServerList';

const AppLayout: React.FC = () => {
  const location = useLocation();
  const isAtMe = location.pathname.includes('@me');

  return (
    <div className="flex h-screen bg-discord-dark">
      {/* Server List - Sabit sol kenar çubuğu */}
      <ServerList />

      {/* Ana içerik - Outlet ile route'a göre içerik değişecek */}
      <div className={isAtMe ? "flex-1 min-w-0" : "flex flex-1 min-w-0"}>
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout; 