import { useCallback } from 'react';

type SoundType = 'message' | 'friendRequest';

const soundFiles = {
  message: '/sounds/message.mp3',
  friendRequest: '/sounds/friend-request.mp3'
};

export const useSound = () => {
  const playSound = useCallback((type: SoundType) => {
    try {
      // DND durumunda ses çalma
      const userStatus = localStorage.getItem('userStatus');
      if (userStatus === 'dnd') {
        return;
      }

      // Ses bildirimleri kapalıysa çalma
      const notificationSound = localStorage.getItem('notificationSound');
      if (notificationSound === 'false') {
        return;
      }

      const audio = new Audio(soundFiles[type]);
      audio.volume = 0.5; // Ses seviyesi (0.0 - 1.0)
      audio.play().catch(error => {
        console.error('Ses çalma hatası:', error);
      });
    } catch (error) {
      console.error('Ses yükleme hatası:', error);
    }
  }, []);

  return { playSound };
}; 