import { useCallback, useEffect } from 'react';
import { useFriendRequests } from '../contexts/FriendRequestsContext';
import { useAuth } from '../contexts/AuthContext';

export const useWebSocket = () => {
  const { wsClient, updateFriendStatus } = useFriendRequests();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!wsClient || !currentUser) return;

    wsClient.on('PRESENCE_UPDATE', (data: { userId: number; status: string; activity?: string }) => {
      updateFriendStatus(data.userId, data.status, data.activity);
    });

    wsClient.on('PRESENCE_UPDATE_CONFIRMATION', (data: { status: string; activity?: string; timestamp: string }) => {
      console.log('Durum güncellendi:', data);
    });

    return () => {
      wsClient.off('PRESENCE_UPDATE');
      wsClient.off('PRESENCE_UPDATE_CONFIRMATION');
    };
  }, [wsClient, updateFriendStatus, currentUser]);

  const sendMessage = useCallback((message: any) => {
    if (wsClient && currentUser) {
      wsClient.send(message);
    }
  }, [wsClient, currentUser]);

  const updatePresence = useCallback((status: string, activity?: string) => {
    if (wsClient && currentUser) {
      wsClient.send({
        type: 'SET_PRESENCE',
        data: { status, activity }
      });
    }
  }, [wsClient, currentUser]);

  return {
    sendMessage,
    updatePresence,
    isConnected: (wsClient?.isConnected() && !!currentUser) || false
  };
};

export default useWebSocket; 