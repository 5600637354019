import React from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/layout/Sidebar';
import MemberSidebar from '../components/layout/MemberSidebar';
import ChatArea from '../components/chat/ChatArea';

const ServerChatPage: React.FC = () => {
  const { serverId, channelId } = useParams();

  return (
    <>
      {/* Server Channels Sidebar */}
      <Sidebar type="server" serverId={serverId} />

      {/* Chat Area */}
      <ChatArea type="server" serverId={serverId} channelId={channelId} />

      {/* Members Sidebar */}
      <MemberSidebar />
    </>
  );
};

export default ServerChatPage; 