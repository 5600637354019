import React from 'react';

const FriendsSidebar: React.FC = () => {
  const activities = [
    { id: '1', name: 'User 1', game: 'Minecraft', duration: '2 saat', status: 'online' },
    { id: '2', name: 'User 2', game: 'Visual Studio Code', duration: '3 saat', status: 'idle' },
    { id: '3', name: 'User 3', game: 'Spotify', duration: '1 saat', status: 'dnd' },
  ];

  return (
    <div className="h-full overflow-y-auto">
      <div className="p-4">
        <h3 className="text-discord-light uppercase text-xs font-semibold mb-4">Aktif Şimdi — {activities.length}</h3>
        <div className="space-y-2">
          {activities.map(activity => (
            <div key={activity.id} className="p-2 rounded hover:bg-discord-gray/10 cursor-pointer group">
              <div className="flex items-center mb-2">
                {/* Avatar ve Status */}
                <div className="w-8 h-8 rounded-full bg-discord-darker flex items-center justify-center relative mr-3">
                  <span className="text-sm text-discord-lighter">{activity.name.charAt(0)}</span>
                  <span className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-discord-dark ${
                    activity.status === 'online' ? 'bg-discord-green' :
                    activity.status === 'idle' ? 'bg-discord-yellow' :
                    'bg-discord-red'
                  }`} />
                </div>
                <div>
                  <div className="text-white text-sm font-medium">{activity.name}</div>
                  <div className="text-discord-light text-xs">{activity.game}</div>
                </div>
              </div>
              <div className="text-discord-light text-xs ml-11">
                {activity.duration} boyunca
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FriendsSidebar; 