import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserPanel from './UserPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faChevronDown,
  faUserPlus,
  faCog,
  faHashtag,
  faLayerGroup,
  faCopy,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { getServer } from '../../services/serverService';
import { toast } from 'react-toastify';
import { API } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { useFriendRequests } from '../../contexts/FriendRequestsContext';
import ColorAvatar from '../common/ColorAvatar';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { Friend } from '../../types/friend';

interface SidebarProps {
  type: 'friends' | 'server';
  serverId?: string;
}

interface Channel {
  id: string;
  name: string;
  type: 'text' | 'voice';
}

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  serverId: string;
}

const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose, serverId }) => {
  const { currentUser } = useAuth();
  const { wsClient, acceptedFriends } = useFriendRequests();
  const [inviteLink, setInviteLink] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const createInvite = async () => {
      if (isOpen && serverId) {
        try {
          setLoading(true);
          const response = await API.post('/api/invites/create', {
            serverId: parseInt(serverId)
          });

          if (response.data.success) {
            setInviteLink(response.data.invite.url);
          }
        } catch (error) {
          console.error('Davet kodu oluşturma hatası:', error);
          toast.error('Davet kodu oluşturulamadı');
        } finally {
          setLoading(false);
        }
      }
    };

    createInvite();
  }, [isOpen, serverId]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    toast.success('Davet linki kopyalandı!');
  };

  const handleSendInvite = async (friendId: string) => {
    try {
      if (!currentUser?.id || !currentUser?.username) {
        toast.error('Kullanıcı bilgisi alınamadı');
        return;
      }

      // Kanal ID'sini oluştur (küçük ID önce)
      const channelId = [parseInt(currentUser.id.toString()), parseInt(friendId)]
        .sort((a, b) => a - b)
        .join('-');

      // WebSocket ile mesaj gönder
      wsClient.send({
        type: 'SEND_MESSAGE',
        data: {
          channel_id: channelId,
          content: `Hey! Seni sunucuma davet ediyorum: ${inviteLink}`
        }
      });

      toast.success('Davet mesajı gönderildi!');
    } catch (error) {
      console.error('Davet gönderme hatası:', error);
      toast.error('Davet gönderilemedi');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black/70" onClick={onClose}></div>
      <div className="relative bg-[#313338] rounded-lg w-full max-w-md">
        {/* Header */}
        <div className="p-4 border-b border-[#3f4147]">
          <div className="flex items-center justify-between">
            <h2 className="text-white text-lg font-semibold">İnsanları Davet Et</h2>
            <button 
              onClick={onClose}
              className="text-discord-light hover:text-white"
            >
              <FontAwesomeIcon icon={faXmark} className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Davet Linki */}
        <div className="p-4 border-b border-[#3f4147]">
          <div className="flex items-center bg-[#1e1f22] rounded p-2">
            <input
              type="text"
              value={loading ? 'Davet kodu oluşturuluyor...' : inviteLink}
              readOnly
              className="flex-1 bg-transparent text-discord-light outline-none"
            />
            <button
              onClick={handleCopyLink}
              disabled={loading}
              className={`ml-2 px-3 py-1 bg-[#4752c4] text-white rounded hover:bg-[#4752c4]/90 flex items-center ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <FontAwesomeIcon icon={faCopy} className="w-4 h-4 mr-2" />
              <span>Kopyala</span>
            </button>
          </div>
        </div>

        {/* Arkadaş Listesi */}
        <div className="p-4">
          <h3 className="text-discord-light text-sm font-semibold mb-2">ARKADAŞLARINA GÖNDER</h3>
          <div className="max-h-[240px] overflow-y-auto custom-scrollbar">
            {acceptedFriends.length === 0 ? (
              <div className="text-discord-light text-center py-4">
                Henüz arkadaşın yok
              </div>
            ) : (
              <div className="space-y-2">
                {acceptedFriends.map(friend => (
                  <div key={friend.id} className="flex items-center justify-between p-2 hover:bg-[#2e3035] rounded">
                    <div className="flex items-center">
                      {friend.avatar ? (
                        <img 
                          src={friend.avatar} 
                          alt={friend.username}
                          className="w-8 h-8 rounded-full mr-3"
                        />
                      ) : (
                        <ColorAvatar 
                          userId={friend.id.toString()}
                          username={friend.username}
                          size="sm"
                          className="mr-3"
                        />
                      )}
                      <span className="text-white">{friend.username}</span>
                    </div>
                    <button
                      onClick={() => handleSendInvite(friend.id.toString())}
                      className="px-3 py-1 bg-[#4752c4] text-white rounded hover:bg-[#4752c4]/90"
                    >
                      Davet Et
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ type, serverId }) => {
  const [serverName, setServerName] = useState<string>('');
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isServerMenuOpen, setIsServerMenuOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  useEffect(() => {
    const loadServerData = async () => {
      if (type === 'server' && serverId) {
        try {
          const serverData = await getServer(parseInt(serverId));
          setServerName(serverData.name);
          // Burada serverData.channels'ı kullanacağız
          setChannels(serverData.channels.map(channel => ({
            id: channel.id.toString(),
            name: channel.name,
            type: channel.type
          })));
        } catch (error) {
          toast.error('Sunucu bilgileri yüklenirken bir hata oluştu');
        } finally {
          setLoading(false);
        }
      }
    };

    loadServerData();
  }, [type, serverId]);

  // Menüyü kapat (dışarı tıklandığında)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menu = document.getElementById('server-menu');
      const button = document.getElementById('server-menu-button');
      if (menu && button && !menu.contains(event.target as Node) && !button.contains(event.target as Node)) {
        setIsServerMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const friends = [
    { id: '1', name: 'User 1', status: 'online' },
    { id: '2', name: 'User 2', status: 'idle' },
    { id: '3', name: 'User 3', status: 'dnd' },
  ];

  return (
    <div className="w-60 h-full bg-discord-dark flex-shrink-0 flex flex-col">
      {/* Header */}
      {type === 'friends' ? (
        <div className="h-12 px-4 border-b border-black flex items-center shadow-sm">
          <input
            type="text"
            placeholder="Arkadaş veya DM ara"
            className="w-full bg-discord-darker text-sm text-discord-light px-2 py-1 rounded"
          />
        </div>
      ) : (
        <div className="h-12 px-4 border-b border-black flex items-center shadow-sm relative">
          <button
            id="server-menu-button"
            onClick={() => setIsServerMenuOpen(!isServerMenuOpen)}
            className="flex items-center w-full hover:bg-[#35363C] px-2 py-1 rounded cursor-pointer transition-colors duration-200"
          >
            <h2 className="text-white font-bold flex-1 text-left">
              {loading ? 'Yükleniyor...' : serverName}
            </h2>
            <FontAwesomeIcon 
              icon={faChevronDown} 
              className={`w-3 h-3 ml-2 text-discord-light transition-transform duration-200 ${isServerMenuOpen ? 'transform rotate-180' : ''}`}
            />
          </button>

          {/* Server Menu Dropdown */}
          {isServerMenuOpen && (
            <div 
              id="server-menu"
              className="absolute top-11 left-2 right-2 bg-[#1e1f22] rounded-md shadow-lg py-2 z-50"
            >
              <button 
                onClick={() => {
                  setIsInviteModalOpen(true);
                  setIsServerMenuOpen(false);
                }}
                className="w-full px-3 py-2 text-sm text-[#b5bac1] hover:bg-[#4752c4] hover:text-white flex items-center"
              >
                <FontAwesomeIcon icon={faUserPlus} className="w-4 h-4 mr-2.5" />
                <span>İnsanları Davet Et</span>
              </button>
              <button className="w-full px-3 py-2 text-sm text-[#b5bac1] hover:bg-[#4752c4] hover:text-white flex items-center">
                <FontAwesomeIcon icon={faCog} className="w-4 h-4 mr-2.5" />
                <span>Sunucu Ayarları</span>
              </button>
              <div className="my-1 border-t border-[#3f4147]"></div>
              <button className="w-full px-3 py-2 text-sm text-[#b5bac1] hover:bg-[#4752c4] hover:text-white flex items-center">
                <FontAwesomeIcon icon={faHashtag} className="w-4 h-4 mr-2.5" />
                <span>Kanal Oluştur</span>
              </button>
              <button className="w-full px-3 py-2 text-sm text-[#b5bac1] hover:bg-[#4752c4] hover:text-white flex items-center">
                <FontAwesomeIcon icon={faLayerGroup} className="w-4 h-4 mr-2.5" />
                <span>Kategori Oluştur</span>
              </button>
            </div>
          )}
        </div>
      )}

      {/* Content */}
      <div className="flex-1 overflow-y-auto">
        {type === 'friends' ? (
          <>
            {/* Friends Section */}
            <div className="p-4">
              <div className="flex items-center text-discord-light hover:text-white cursor-pointer mb-2">
                <span className="mr-2">👥</span>
                <span className="font-medium">Arkadaşlar</span>
              </div>
              <div className="flex items-center text-discord-light hover:text-white cursor-pointer mb-2">
                <span className="mr-2">📩</span>
                <span className="font-medium">DM'ler</span>
              </div>
            </div>

            {/* Direct Messages */}
            <div className="px-2">
              <h3 className="text-discord-light uppercase text-xs font-semibold px-2 mb-2">
                Özel Mesajlar
              </h3>
              {friends.map(friend => (
                <Link
                  key={friend.id}
                  to={`/channels/@me/${friend.id}`}
                  className="flex items-center px-2 py-1 rounded hover:bg-discord-gray/10 cursor-pointer group"
                >
                  {/* Status Indicator */}
                  <div className="w-8 h-8 rounded-full bg-discord-darker flex items-center justify-center relative mr-3">
                    <span className="text-sm">{friend.name.charAt(0)}</span>
                    <span className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-discord-dark ${
                      friend.status === 'online' ? 'bg-green-500' :
                      friend.status === 'idle' ? 'bg-yellow-500' :
                      'bg-red-500'
                    }`} />
                  </div>
                  <span className="text-discord-light group-hover:text-white text-sm">
                    {friend.name}
                  </span>
                </Link>
              ))}
            </div>
          </>
        ) : (
          <>
            {/* Server Channels */}
            <div className="mt-4 px-2">
              <h3 className="text-discord-light uppercase text-xs font-semibold px-2 mb-2 flex items-center justify-between">
                METİN KANALLARI
                <button className="text-discord-light hover:text-white">+</button>
              </h3>
              {channels
                .filter(channel => channel.type === 'text')
                .map(channel => (
                  <Link
                    key={channel.id}
                    to={`/channels/${serverId}/${channel.id}`}
                    className="flex items-center px-2 py-1 rounded hover:bg-[#36393f] cursor-pointer group"
                  >
                    <span className="text-xl text-discord-light mr-2">#</span>
                    <span className="text-discord-light group-hover:text-white text-sm">
                      {channel.name}
                    </span>
                  </Link>
                ))}

              <h3 className="text-discord-light uppercase text-xs font-semibold px-2 mb-2 mt-4 flex items-center justify-between">
                SES KANALLARI
                <button className="text-discord-light hover:text-white">+</button>
              </h3>
              {channels
                .filter(channel => channel.type === 'voice')
                .map(channel => (
                  <Link
                    key={channel.id}
                    to={`/channels/${serverId}/${channel.id}`}
                    className="flex items-center px-2 py-1 rounded hover:bg-[#36393f] cursor-pointer group"
                  >
                    <svg 
                      className="w-4 h-4 mr-2 text-discord-light" 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 24 24" 
                      fill="currentColor"
                    >
                      <path d="M3 10v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71V6.41c0-.89-1.08-1.34-1.71-.71L7 9H4c-.55 0-1 .45-1 1zm13.5 2A4.5 4.5 0 0014 8v8a4.5 4.5 0 001.5-4z"/>
                      <path d="M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z"/>
                    </svg>
                    <span className="text-discord-light group-hover:text-white text-sm">
                      {channel.name}
                    </span>
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>

      {/* User Panel */}
      <UserPanel />

      {/* Invite Modal */}
      {serverId && (
        <InviteModal 
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          serverId={serverId}
        />
      )}
    </div>
  );
};

export default Sidebar; 