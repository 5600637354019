import axios from 'axios';

// API URL'sini belirle
const API_URL = window.location.hostname === 'localhost' 
  ? 'http://localhost:3001'
  : 'https://api.lazcord.aztmedya.net';

// Axios instance oluştur
export const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Request interceptor
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('İstek hatası:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Sunucu hatası:', {
        url: error.config?.url,
        status: error.response.status,
        data: error.response.data
      });
    } else if (error.request) {
      console.error('Yanıt alınamadı:', {
        url: error.config?.url,
        error: error.message
      });
    } else {
      console.error('İstek hatası:', error.message);
    }
    return Promise.reject(error);
  }
);

// Kullanıcı adı güncelleme
export const updateUsername = async (newUsername: string) => {
  const response = await API.put('/api/auth/username', { newUsername });
  return response.data;
};

// Şifre güncelleme
export const updatePassword = async (currentPassword: string, newPassword: string) => {
  const response = await API.put('/api/auth/password', { currentPassword, newPassword });
  return response.data;
}; 