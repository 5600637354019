import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import lazcordLogo from '../assets/auth/lazcord.png';

const LoginPage: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const success = await login(username, password);
      
      if (success) {
        navigate('/channels/@me');
      }
      // Başarısız durumda navigate yapmıyoruz, toast mesajı AuthContext'ten gelecek
    } catch (error) {
      console.error('Login error:', error);
      toast.error('Giriş yapılırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-discord-dark flex items-center justify-center p-4">
      <div className="bg-discord-gray rounded-lg p-8 w-full max-w-md">
        <div className="text-center mb-8">
          <img src={lazcordLogo} alt="LazCord" className="w-24 h-24 mx-auto mb-4" />
          <h1 className="text-2xl font-bold text-white">Tekrar Hoşgeldin!</h1>
          <p className="text-discord-light">Seni tekrar görmek güzel</p>
        </div>

        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-discord-light text-sm font-medium mb-2">
              Kullanıcı Adı
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 bg-discord-dark text-white rounded focus:outline-none focus:ring-2 focus:ring-discord-blurple"
              required
              disabled={loading}
            />
          </div>

          <div>
            <label className="block text-discord-light text-sm font-medium mb-2">
              Şifre
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-discord-dark text-white rounded focus:outline-none focus:ring-2 focus:ring-discord-blurple"
              required
              disabled={loading}
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-discord-blurple text-white p-3 rounded font-medium ${
              loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-80'
            }`}
          >
            {loading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
          </button>

          <p className="text-discord-light text-sm">
            Hesabın yok mu?{' '}
            <Link to="/register" className="text-discord-blurple hover:underline">
              Kayıt Ol
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage; 