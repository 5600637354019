import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelSidebar from '../components/layout/ChannelSidebar';
import FriendsSidebar from '../components/layout/FriendsSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faUserPlus, faCommentDots, faEllipsisVertical, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../contexts/AuthContext';
import { useFriendRequests } from '../contexts/FriendRequestsContext';
import { toast } from 'react-toastify';
import { sendFriendRequest, acceptFriendRequest, rejectFriendRequest, cancelFriendRequest, getAcceptedFriends, removeFriend, blockUser, unblockUser, getBlockedUsers, isUserBlocked } from '../services/friendshipService';
import ColorAvatar from '../components/common/ColorAvatar';
import type { Friend } from '../types/friend';
import ReactDOM from 'react-dom';
import { API } from '../services/api';

const API_URL = window.location.hostname === 'localhost'
  ? 'http://localhost:3001'
  : 'https://api.lazcord.aztmedya.net';

const FriendsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { incomingRequests, outgoingRequests, loadPendingRequests, acceptedFriends, loadAcceptedFriends } = useFriendRequests();
  const { wsClient } = useFriendRequests();
  const [activeTab, setActiveTab] = useState<'online' | 'all' | 'pending' | 'blocked' | 'add'>('online');
  const [newFriendUsername, setNewFriendUsername] = useState('');
  const [contextMenu, setContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
    friendId: string;
  }>({
    visible: false,
    x: 0,
    y: 0,
    friendId: ''
  });
  const [blockedUsers, setBlockedUsers] = useState<Friend[]>([]);
  const navigate = useNavigate();

  // İlk yüklemede arkadaşları ve presence durumlarını al
  useEffect(() => {
    const loadInitialData = async () => {
      await loadAcceptedFriends();
      // Sadece ilk yüklemede presence durumlarını al
      if (wsClient) {
        wsClient.refreshPresenceStates();
      }
    };
    loadInitialData();
  }, [loadAcceptedFriends, wsClient]);

  // Engellenen kullanıcıları yükle
  useEffect(() => {
    if (activeTab === 'blocked') {
      loadBlockedUsers();
    }
  }, [activeTab]);

  // Engellenen kullanıcıları yükle
  const loadBlockedUsers = async () => {
    try {
      const users = await getBlockedUsers();
      setBlockedUsers(users);
    } catch (error) {
      console.error('Engellenen kullanıcılar yüklenirken hata:', error);
      toast.error('Engellenen kullanıcılar yüklenemedi');
    }
  };

  // Tab değiştiğinde gerekli verileri yükle
  const handleTabChange = async (tab: typeof activeTab) => {
    setActiveTab(tab);
    if (tab === 'blocked') {
      await loadBlockedUsers();
    } else if ((tab === 'online' || tab === 'all') && wsClient) {
      await loadAcceptedFriends();
      wsClient.send({
        type: 'GET_PRESENCE',
        data: {
          userIds: acceptedFriends.map(friend => friend.id),
          priority: 'high',
          immediate: true
        }
      });
    }
  };

  const onlineFriends = useMemo(() => {
    return acceptedFriends.filter(friend => 
      friend.online_status === 'online' || 
      friend.online_status === 'idle' || 
      friend.online_status === 'dnd'
    );
  }, [acceptedFriends]);

  const handleAddFriend = async () => {
    if (!currentUser) return;
    if (!newFriendUsername.trim()) {
      toast.warning('Lütfen bir kullanıcı adı girin');
      return;
    }

    if (newFriendUsername === currentUser.username) {
      toast.warning('Kendinize arkadaşlık isteği gönderemezsiniz');
      return;
    }

    try {
      const result = await sendFriendRequest(currentUser.username, newFriendUsername);
      if (result.success) {
        toast.success('Arkadaşlık isteği gönderildi');
        setNewFriendUsername('');
        loadPendingRequests();
      } else {
        toast.warning(result.error);
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        toast.error('Kullanıcı bulunamadı');
      } else {
        toast.error('Arkadaşlık isteği gönderilirken bir hata oluştu');
      }
    }
  };

  const handleAcceptRequest = async (requestId: number) => {
    const result = await acceptFriendRequest(requestId);
    if (result.success) {
      toast.success('Arkadaşlık isteği kabul edildi');
      loadPendingRequests();
    } else {
      toast.error(result.error);
    }
  };

  const handleRejectRequest = async (requestId: number) => {
    const result = await rejectFriendRequest(requestId);
    if (result.success) {
      toast.success('Arkadaşlık isteği reddedildi');
      loadPendingRequests();
    } else {
      toast.error(result.error);
    }
  };

  const handleCancelRequest = async (requestId: number) => {
    const result = await cancelFriendRequest(requestId);
    if (result.success) {
      toast.success('Arkadaşlık isteği iptal edildi');
      loadPendingRequests();
    } else {
      toast.error(result.error);
    }
  };

  const handleRemoveFriend = async (friendId: string) => {
    try {
      const result = await removeFriend(parseInt(friendId));
      if (result.success) {
        toast.success('Arkadaşlıktan çıkarıldı');
        loadAcceptedFriends();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error('Bir hata oluştu');
    }
    setContextMenu(prev => ({ ...prev, visible: false }));
  };

  const handleBlockUser = async (friendId: string) => {
    try {
      const result = await blockUser(parseInt(friendId));
      if (result.success) {
        toast.success('Kullanıcı engellendi');
        // Arkadaş listesini güncelle
        await loadAcceptedFriends();
        // Engellenen kullanıcılar listesini güncelle
        await loadBlockedUsers();
        // Arkadaşlık durumunu güncelle
        if (wsClient) {
          wsClient.send({
            type: 'GET_PRESENCE',
            data: {
              userIds: acceptedFriends.map(friend => friend.id),
              priority: 'high',
              immediate: true
            }
          });
        }
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error('Bir hata oluştu');
    }
    setContextMenu(prev => ({ ...prev, visible: false }));
  };

  const handleUnblockUser = async (userId: string) => {
    try {
      const result = await unblockUser(parseInt(userId));
      if (result.success) {
        toast.success('Engel kaldırıldı');
        loadBlockedUsers();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error('Bir hata oluştu');
    }
  };

  const friends = [
    { id: '1', name: 'User 1', tag: '7786', status: 'online', activity: 'Playing Minecraft' },
    { id: '2', name: 'User 2', tag: '8098', status: 'idle', activity: 'Spotify' },
    { id: '3', name: 'User 3', tag: '9099', status: 'dnd', activity: 'Visual Studio Code' },
  ];

  const renderTabButton = (tab: typeof activeTab, label: string) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`px-3 py-1.5 text-[15px] font-medium rounded-[4px] transition-colors duration-200 flex items-center ${
        activeTab === tab 
          ? 'bg-[#454950] text-white hover:bg-[#4E535C]' 
          : 'text-discord-light hover:text-white hover:bg-[#454950]'
      }`}
    >
      {label}
      {tab === 'pending' && incomingRequests.length > 0 && (
        <span className="ml-2 px-[6px] py-[2px] text-xs bg-discord-red text-white rounded-full">
          {incomingRequests.length}
        </span>
      )}
    </button>
  );

  const getStatusText = (status: Friend['online_status']) => {
    switch (status) {
      case 'online':
        return 'Çevrimiçi';
      case 'offline':
        return 'Çevrimdışı';
      case 'idle':
        return 'Boşta';
      case 'dnd':
        return 'Rahatsız Etmeyin';
      default:
        return 'Çevrimdışı';
    }
  };

  // Context menu'yü kapat
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenu.visible) {
        setContextMenu(prev => ({ ...prev, visible: false }));
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [contextMenu.visible]);

  const handleContextMenu = (e: React.MouseEvent, friendId: string) => {
    e.preventDefault();
    e.stopPropagation();
    
    const rect = e.currentTarget.getBoundingClientRect();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      friendId
    });
  };

  const handleMessageClick = (friendId: string, friendUsername: string, created_at: string) => {
    // DM kanalına yönlendir
    navigate(`/channels/@me/${friendId}`, { 
      state: { 
        username: friendUsername,
        created_at,
        fromFriendsPage: true 
      } 
    });
  };

  // Arkadaş kartı bileşeni
  const FriendCard: React.FC<{
    friend: Friend;
    onMessageClick: () => void;
    onContextMenu: (e: React.MouseEvent) => void;
  }> = ({ friend, onMessageClick, onContextMenu }) => (
    <div 
      className="flex items-center px-2 py-[7px] mx-2 hover:bg-[#393C43] rounded-[4px] cursor-pointer group relative"
      onContextMenu={onContextMenu}
    >
      <div className="flex items-center flex-1 min-w-0">
        <div className="relative">
          {friend.avatar ? (
            <img 
              src={friend.avatar.startsWith('data:') || friend.avatar.startsWith('http') 
                ? friend.avatar 
                : `${API_URL}${friend.avatar.startsWith('/') ? friend.avatar : `/${friend.avatar}`}`}
              alt={friend.username}
              className="w-10 h-10 rounded-full object-cover"
              onError={(e) => {
                e.currentTarget.style.display = 'none';
                const colorAvatar = document.createElement('div');
                colorAvatar.style.width = '40px';
                colorAvatar.style.height = '40px';
                e.currentTarget.parentNode?.appendChild(colorAvatar);
                ReactDOM.render(
                  <ColorAvatar 
                    userId={friend.id.toString()} 
                    username={friend.username} 
                    size="md"
                  />,
                  colorAvatar
                );
              }}
            />
          ) : (
            <ColorAvatar 
              userId={friend.id.toString()} 
              username={friend.username} 
              size="md"
            />
          )}
          <div className={`absolute bottom-0 right-0 w-[12px] h-[12px] rounded-full border-2 border-discord-dark ${
            friend.online_status === 'online' ? 'bg-discord-green' :
            friend.online_status === 'idle' ? 'bg-discord-yellow' :
            friend.online_status === 'dnd' ? 'bg-discord-red' :
            'bg-discord-gray'
          }`} />
        </div>
        <div className="ml-3 flex-1 min-w-0">
          <div className="text-white font-medium truncate">{friend.username}</div>
          <div className="text-discord-light text-sm truncate">
            {getStatusText(friend.online_status)}
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-3 mr-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onMessageClick();
          }}
          className="text-discord-light hover:text-white transition-colors"
        >
          <FontAwesomeIcon icon={faCommentDots} className="w-5 h-5" />
        </button>
        <button 
          onClick={(e) => {
            e.preventDefault();
            onContextMenu(e);
          }}
          className="text-discord-light hover:text-white transition-colors"
        >
          <FontAwesomeIcon icon={faEllipsisVertical} className="w-5 h-5" />
        </button>
      </div>
    </div>
  );

  // Bekleyen istek kartı bileşeni
  const PendingRequestCard: React.FC<{
    request: any;
    type: 'incoming' | 'outgoing';
    onAccept?: () => void;
    onReject?: () => void;
    onCancel?: () => void;
  }> = ({ request, type, onAccept, onReject, onCancel }) => {
    // Kullanıcı bilgilerini güvenli bir şekilde al
    const username = type === 'incoming' 
      ? request?.sender_username || 'Bilinmeyen Kullanıcı'
      : request?.receiver_username || 'Bilinmeyen Kullanıcı';

    const userId = type === 'incoming'
      ? request?.sender_id?.toString() || '0'
      : request?.receiver_id?.toString() || '0';

    if (!request) {
      return null;
    }

    return (
      <div className="flex items-center px-2 py-[7px] mx-2 hover:bg-[#393C43] rounded-[4px] cursor-pointer group">
        <div className="flex items-center flex-1 min-w-0">
          <div className="relative">
            {request.avatar ? (
              <img 
                src={request.avatar}
                alt={username}
                className="w-8 h-8 rounded-full object-cover"
                onError={(e) => {
                  e.currentTarget.style.display = 'none';
                  const colorAvatar = document.createElement('div');
                  colorAvatar.style.width = '32px';
                  colorAvatar.style.height = '32px';
                  e.currentTarget.parentNode?.appendChild(colorAvatar);
                  ReactDOM.render(
                    <ColorAvatar 
                      userId={userId} 
                      username={username} 
                      size="md"
                    />,
                    colorAvatar
                  );
                }}
              />
            ) : (
              <ColorAvatar 
                userId={userId} 
                username={username} 
                size="md"
              />
            )}
          </div>
          <div className="ml-3 flex-1 min-w-0">
            <div className="text-white font-medium truncate">{username}</div>
            <div className="text-discord-light text-sm truncate">
              {type === 'incoming' ? 'Gelen İstek' : 'Giden İstek'}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          {type === 'incoming' && (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onAccept?.();
                }}
                className="text-discord-green hover:text-green-400 transition-colors"
              >
                <FontAwesomeIcon icon={faCheck} className="w-5 h-5" />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onReject?.();
                }}
                className="text-discord-red hover:text-red-400 transition-colors"
              >
                <FontAwesomeIcon icon={faTimes} className="w-5 h-5" />
              </button>
            </>
          )}
          {type === 'outgoing' && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onCancel?.();
              }}
              className="text-discord-red hover:text-red-400 transition-colors"
            >
              <FontAwesomeIcon icon={faTimes} className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    );
  };

  // Engellenen kullanıcı kartı bileşeni
  const BlockedUserCard: React.FC<{
    user: Friend;
    onUnblock: () => void;
  }> = ({ user, onUnblock }) => (
    <div className="flex items-center px-2 py-[7px] mx-2 hover:bg-[#393C43] rounded-[4px] cursor-pointer group">
      <div className="flex items-center flex-1 min-w-0">
        <div className="relative">
          {user.avatar ? (
            <img 
              src={user.avatar.startsWith('data:') || user.avatar.startsWith('http') 
                ? user.avatar 
                : `${API_URL}${user.avatar.startsWith('/') ? user.avatar : `/${user.avatar}`}`}
              alt={user.username}
              className="w-10 h-10 rounded-full object-cover"
              onError={(e) => {
                e.currentTarget.style.display = 'none';
                const colorAvatar = document.createElement('div');
                colorAvatar.style.width = '40px';
                colorAvatar.style.height = '40px';
                e.currentTarget.parentNode?.appendChild(colorAvatar);
                ReactDOM.render(
                  <ColorAvatar 
                    userId={user.id.toString()} 
                    username={user.username} 
                    size="md"
                  />,
                  colorAvatar
                );
              }}
            />
          ) : (
            <ColorAvatar 
              userId={user.id.toString()} 
              username={user.username} 
              size="md"
            />
          )}
        </div>
        <div className="ml-3 flex-1 min-w-0">
          <div className="text-white font-medium truncate">{user.username}</div>
          <div className="text-discord-light text-sm truncate">Engellendi</div>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onUnblock();
          }}
          className="text-discord-light hover:text-white transition-colors px-3 py-1 rounded hover:bg-[#454950]"
        >
          Engeli Kaldır
        </button>
      </div>
    </div>
  );

  const ContextMenu = () => {
    if (!contextMenu.visible) return null;

    return (
      <div 
        className="fixed bg-discord-dark rounded-md shadow-lg z-50 min-w-[180px] py-2 text-sm"
        style={{
          top: contextMenu.y,
          left: contextMenu.x,
          transform: 'translate(-100%, -100%)'
        }}
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={() => handleRemoveFriend(contextMenu.friendId)}
          className="w-full px-3 py-2 text-left text-discord-red hover:bg-discord-red/10 transition-colors flex items-center"
        >
          Arkadaşlıktan Çıkar
        </button>
        <div className="mx-2 my-1 h-px bg-discord-light/10" />
        <button 
          onClick={() => handleBlockUser(contextMenu.friendId)}
          className="w-full px-3 py-2 text-left text-discord-red hover:bg-discord-red/10 transition-colors flex items-center"
        >
          Engelle
        </button>
      </div>
    );
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <ChannelSidebar />
      <div className="flex-1 flex min-w-0">
        <div className="flex-1 flex flex-col bg-discord-gray min-w-0">
          {/* Friends Header */}
          <header className="h-12 px-4 border-b border-black/[0.24] flex items-center shadow-sm">
            <div className="flex items-center">
              <div className="flex items-center text-discord-light">
                <FontAwesomeIcon icon={faUserFriends} className="w-6 h-6 mr-2" />
                <span className="font-semibold">Arkadaşlar</span>
              </div>
              <div className="h-6 w-px bg-discord-gray/50 mx-4" />
              <div className="flex items-center space-x-2">
                {renderTabButton('online', 'Çevrimiçi')}
                {renderTabButton('all', 'Tümü')}
                {renderTabButton('pending', 'Bekleyen')}
                {renderTabButton('blocked', 'Engellenenler')}
                <button
                  onClick={() => setActiveTab('add')}
                  className={`px-3 py-1.5 text-[15px] font-medium rounded-[4px] transition-colors duration-200 ${
                    activeTab === 'add'
                      ? 'bg-[#248046] text-white hover:bg-[#1a6334]'
                      : 'bg-[#248046] text-white hover:bg-[#1a6334]'
                  }`}
                >
                  Arkadaş Ekle
                </button>
              </div>
            </div>
          </header>

          {/* Friends List */}
          <div className="flex-1 overflow-y-auto overflow-x-hidden">
            {activeTab === 'online' && (
              <div className="p-4">
                <h2 className="text-discord-light font-semibold text-xs uppercase mb-4 px-2">
                  ÇEVRİMİÇİ — {onlineFriends.length}
                </h2>
                {onlineFriends.length === 0 ? (
                  <p className="text-discord-light text-center mt-4">Çevrimiçi arkadaşın bulunmuyor</p>
                ) : (
                  onlineFriends.map((friend, index, array) => (
                    <React.Fragment key={friend.id}>
                      <FriendCard
                        friend={friend}
                        onMessageClick={() => handleMessageClick(friend.id.toString(), friend.username, friend.created_at)}
                        onContextMenu={(e) => handleContextMenu(e, friend.id.toString())}
                      />
                      {index < array.length - 1 && (
                        <div className="mx-2 h-[1px] bg-[#4f545c99]" />
                      )}
                    </React.Fragment>
                  ))
                )}
              </div>
            )}

            {activeTab === 'all' && (
              <div className="p-4">
                <h2 className="text-discord-light font-semibold text-xs uppercase mb-4 px-2">
                  TÜM ARKADAŞLAR — {acceptedFriends.length}
                </h2>
                {acceptedFriends.length === 0 ? (
                  <p className="text-discord-light text-center mt-4">Henüz hiç arkadaşın yok</p>
                ) : (
                  acceptedFriends.map((friend, index, array) => (
                    <React.Fragment key={friend.id}>
                      <FriendCard
                        friend={friend}
                        onMessageClick={() => handleMessageClick(friend.id.toString(), friend.username, friend.created_at)}
                        onContextMenu={(e) => handleContextMenu(e, friend.id.toString())}
                      />
                      {index < array.length - 1 && (
                        <div className="mx-2 h-[1px] bg-[#4f545c99]" />
                      )}
                    </React.Fragment>
                  ))
                )}
              </div>
            )}

            {/* Add Friend Section */}
            {activeTab === 'add' && (
              <div className="p-8">
                <h2 className="text-white text-xl font-bold mb-2">Arkadaş Ekle</h2>
                <p className="text-discord-light mb-4">LazCord kullanıcı adını kullanarak arkadaş ekleyebilirsin.</p>
                <div className="relative">
                  <input
                    type="text"
                    value={newFriendUsername}
                    onChange={(e) => setNewFriendUsername(e.target.value)}
                    placeholder="Kullanıcı adını gir"
                    className="w-full bg-discord-dark text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-discord-blurple"
                  />
                  <button
                    onClick={handleAddFriend}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 px-4 py-1 bg-discord-blurple text-white rounded-md hover:bg-discord-blurple-dark transition-colors"
                  >
                    Gönder
                  </button>
                </div>
              </div>
            )}

            {/* Pending Requests Section */}
            {activeTab === 'pending' && (
              <div className="p-8">
                {/* Gelen İstekler */}
                <div className="mb-8">
                  <h3 className="text-white text-lg font-semibold mb-4">Gelen İstekler</h3>
                  {incomingRequests.length === 0 ? (
                    <p className="text-discord-light">Gelen istek bulunmuyor</p>
                  ) : (
                    <div className="space-y-4">
                      {incomingRequests.map((request) => (
                        <PendingRequestCard
                          key={request.id}
                          request={request}
                          type="incoming"
                          onAccept={() => handleAcceptRequest(request.id)}
                          onReject={() => handleRejectRequest(request.id)}
                        />
                      ))}
                    </div>
                  )}
                </div>

                {/* Giden İstekler */}
                <div>
                  <h3 className="text-white text-lg font-semibold mb-4">Giden İstekler</h3>
                  {outgoingRequests.length === 0 ? (
                    <p className="text-discord-light">Giden istek bulunmuyor</p>
                  ) : (
                    <div className="space-y-4">
                      {outgoingRequests.map((request) => (
                        <PendingRequestCard
                          key={request.id}
                          request={request}
                          type="outgoing"
                          onCancel={() => handleCancelRequest(request.id)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Engellenen Kullanıcılar Bölümü */}
            {activeTab === 'blocked' && (
              <div className="p-4">
                <h2 className="text-discord-light font-semibold text-xs uppercase mb-4 px-2">
                  ENGELLENENLERİN — {blockedUsers.length}
                </h2>
                {blockedUsers.length === 0 ? (
                  <p className="text-discord-light text-center mt-4">Engellenen kullanıcı bulunmuyor</p>
                ) : (
                  blockedUsers.map((user, index) => (
                    <React.Fragment key={user.id}>
                      <BlockedUserCard
                        user={user}
                        onUnblock={() => handleUnblockUser(user.id.toString())}
                      />
                      {index < blockedUsers.length - 1 && (
                        <div className="mx-2 h-[1px] bg-[#4f545c99]" />
                      )}
                    </React.Fragment>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
        <div className="w-[300px] flex-shrink-0 bg-discord-dark border-l border-black/[0.24]">
          <FriendsSidebar />
        </div>
      </div>
      <ContextMenu />
    </div>
  );
};

export default FriendsPage; 