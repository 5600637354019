import React, { createContext, useContext, useState, useEffect } from 'react';

interface ActiveChatContextType {
  activeChannel: string | null;
  isChannelVisible: boolean;
  setActiveChat: (channelId: string | null, isVisible: boolean) => void;
}

const ActiveChatContext = createContext<ActiveChatContextType | undefined>(undefined);

export const ActiveChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeChannel, setActiveChannel] = useState<string | null>(null);
  const [isChannelVisible, setIsChannelVisible] = useState(false);

  const setActiveChat = (channelId: string | null, isVisible: boolean) => {
    console.log('Aktif sohbet güncellendi:', { channelId, isVisible, previous: activeChannel });
    setActiveChannel(channelId);
    setIsChannelVisible(isVisible);
  };

  return (
    <ActiveChatContext.Provider value={{ activeChannel, isChannelVisible, setActiveChat }}>
      {children}
    </ActiveChatContext.Provider>
  );
};

export const useActiveChat = () => {
  const context = useContext(ActiveChatContext);
  if (context === undefined) {
    throw new Error('useActiveChat must be used within an ActiveChatProvider');
  }
  return context;
}; 