import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { FriendRequestsProvider } from './contexts/FriendRequestsContext';
import { ProfileProvider } from './contexts/ProfileContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ActiveChatProvider } from './contexts/ActiveChatContext';
import { WebSocketProvider } from './contexts/WebSocketContext';
import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from './components/LoadingScreen';

// Loading yönetimi için özel komponent
const LoadingManager: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { currentUser } = useAuth();
  const hasShownChannelsLoading = useRef(false);

  useEffect(() => {
    // İlk login durumu
    if (location.pathname === '/' && !currentUser) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 3000); // İlk yükleme için 3 saniye loading
      return () => clearTimeout(timer);
    } 
    // Channels'a ilk geçiş durumu
    else if (location.pathname.includes('/channels') && currentUser && !hasShownChannelsLoading.current) {
      setIsLoading(true);
      hasShownChannelsLoading.current = true;
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Channels için 2 saniye loading
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [location.pathname, currentUser]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <AppRoutes />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </>
      )}
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <WebSocketProvider>
          <FriendRequestsProvider>
            <ProfileProvider>
              <NotificationProvider>
                <ActiveChatProvider>
                  <LoadingManager />
                </ActiveChatProvider>
              </NotificationProvider>
            </ProfileProvider>
          </FriendRequestsProvider>
        </WebSocketProvider>
      </AuthProvider>
    </Router>
  );
};

export default App; 