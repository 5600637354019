import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginBgLeft from '../assets/auth/login-bg-left.svg';
import loginBgRight from '../assets/auth/login-bg-right.svg';
import lazcordLogo from '../assets/auth/lazcord.png';
import axios from 'axios';

const RegisterPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    if (formData.password !== formData.confirmPassword) {
      setError('Şifreler eşleşmiyor');
      return false;
    }
    if (formData.password.length < 6) {
      setError('Şifre en az 6 karakter olmalıdır');
      return false;
    }
    if (formData.username.length < 3) {
      setError('Kullanıcı adı en az 3 karakter olmalıdır');
      return false;
    }
    if (!formData.email.includes('@')) {
      setError('Geçerli bir e-posta adresi giriniz');
      return false;
    }
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      console.log('Kayıt isteği gönderiliyor...');
      
      // Axios instance oluştur
      const axiosInstance = axios.create({
        baseURL: '/api',
        withCredentials: true,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      // Test isteği gönder
      try {
        console.log('Test isteği gönderiliyor...');
        const testResponse = await axiosInstance.get('/test');
        console.log('Test yanıtı:', {
          status: testResponse.status,
          headers: testResponse.headers,
          data: testResponse.data
        });
      } catch (testError: any) {
        console.error('Test isteği hatası:', {
          message: testError.message,
          code: testError.code,
          response: testError.response?.data
        });
      }

      // Kayıt isteği gönder
      console.log('Kayıt isteği gönderiliyor...');
      const response = await axiosInstance.post('/auth/register', {
        email: formData.email,
        username: formData.username,
        password: formData.password
      });

      console.log('Sunucu yanıtı:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: response.data
      });

      // Başarılı kayıt kontrolü
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false); // Yükleme durumunu kapat
        navigate('/login', { 
          state: { 
            message: 'Kayıt başarılı! Şimdi giriş yapabilirsiniz.',
            email: formData.email 
          } 
        });
      }
    } catch (err: any) {
      console.error('=== KAYIT HATASI ===');
      if (axios.isAxiosError(err)) {
        console.error('Axios Hatası:', {
          message: err.message,
          code: err.code,
          status: err.response?.status,
          statusText: err.response?.statusText,
          headers: err.response?.headers,
          data: err.response?.data
        });

        if (err.response) {
          if (err.response.status === 400) {
            const errorMessage = err.response.data?.error?.toLowerCase() || '';
            if (errorMessage.includes('email')) {
              setError('Bu e-posta adresi zaten kullanılıyor. Lütfen başka bir e-posta adresi deneyin.');
            } else if (errorMessage.includes('username')) {
              setError('Bu kullanıcı adı zaten kullanılıyor. Lütfen başka bir kullanıcı adı deneyin.');
            } else {
              setError(err.response.data?.error || 'Kayıt olurken bir hata oluştu. Lütfen tekrar deneyin.');
            }
          } else {
            setError(err.response.data?.error || 'Kayıt olurken bir hata oluştu. Lütfen tekrar deneyin.');
          }
        } else if (err.code === 'ERR_NETWORK') {
          setError('Sunucuya bağlanılamıyor. Lütfen internet bağlantınızı kontrol edin veya daha sonra tekrar deneyin.');
        } else if (err.code === 'ECONNABORTED') {
          setError('Sunucu yanıt vermedi. Lütfen daha sonra tekrar deneyin.');
        } else {
          setError('Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        }
      } else {
        console.error('Bilinmeyen Hata:', err);
        setError('Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
      setIsLoading(false); // Hata durumunda yükleme durumunu kapat
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden bg-discord-dark">
      {/* Background SVGs */}
      <img 
        src={loginBgLeft} 
        alt="" 
        className="fixed left-0 bottom-0 h-[40vh] opacity-50"
        style={{ filter: 'brightness(0.6)' }}
      />
      <img 
        src={loginBgRight} 
        alt="" 
        className="fixed right-0 bottom-0 h-[40vh] opacity-50"
        style={{ filter: 'brightness(0.6)' }}
      />

      {/* Register Form */}
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="bg-discord-gray rounded-lg p-8 w-full max-w-md shadow-2xl backdrop-blur-sm bg-opacity-90">
          <div className="text-center mb-8">
            <img 
              src={lazcordLogo} 
              alt="LazCord" 
              className="w-32 h-32 mx-auto mb-3"
            />
            <h1 className="text-2xl font-bold text-white mb-2">Bir Hesap Oluştur</h1>
          </div>

          {error && (
            <div className="bg-red-500 text-white p-3 rounded mb-4 text-sm">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-xs font-bold text-discord-light uppercase mb-2">
                E-posta
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2.5 bg-discord-dark text-white rounded focus:outline-none focus:ring-2 focus:ring-discord-blue"
                required
                disabled={isLoading}
                autoComplete="email"
              />
            </div>

            <div>
              <label className="block text-xs font-bold text-discord-light uppercase mb-2">
                Kullanıcı Adı
              </label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="w-full p-2.5 bg-discord-dark text-white rounded focus:outline-none focus:ring-2 focus:ring-discord-blue"
                required
                disabled={isLoading}
                autoComplete="username"
                minLength={3}
                maxLength={32}
              />
            </div>

            <div>
              <label className="block text-xs font-bold text-discord-light uppercase mb-2">
                Şifre
              </label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2.5 bg-discord-dark text-white rounded focus:outline-none focus:ring-2 focus:ring-discord-blue"
                required
                disabled={isLoading}
                autoComplete="new-password"
                minLength={6}
              />
            </div>

            <div>
              <label className="block text-xs font-bold text-discord-light uppercase mb-2">
                Şifre Tekrar
              </label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-full p-2.5 bg-discord-dark text-white rounded focus:outline-none focus:ring-2 focus:ring-discord-blue"
                required
                disabled={isLoading}
                autoComplete="new-password"
                minLength={6}
              />
            </div>

            <button
              type="submit"
              className={`w-full bg-discord-blue text-white p-3 rounded font-medium transition-colors ${
                isLoading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-opacity-80'
              }`}
              disabled={isLoading}
            >
              {isLoading ? 'Kayıt Yapılıyor...' : 'Kayıt Ol'}
            </button>

            <div className="text-sm text-discord-light">
              <Link to="/login" className="text-discord-blue hover:underline">
                Zaten bir hesabın var mı?
              </Link>
            </div>

            <div className="text-xs text-discord-light mt-4">
              Kayıt olarak{' '}
              <Link to="/terms" className="text-discord-blue hover:underline">
                Kullanım Şartları
              </Link>
              {' '}ve{' '}
              <Link to="/privacy" className="text-discord-blue hover:underline">
                Gizlilik Politikası
              </Link>
              'nı kabul etmiş olursun.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage; 